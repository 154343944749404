export function getGoogTaxDetails(bagaz){
    return new Promise(async function(resolve,reject){
        bagaz.l=1
        var request=new Request('https://upcscanningapp.azurewebsites.net/api/g_tax_query', {            
            method:'POST',
            headers: new Headers({'Accept': 'application/json',
            'Content-Type':'application/json'}),
            body:JSON.stringify(bagaz)
            })
        //we make a request
        fetch(request)
        //we get response, in form of json
            .then(response=>response.json())
            .then(response=>{
                //console.log('sku returns',response)
                resolve(response)
            })
        })
    }