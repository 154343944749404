//dependencies
import React from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import styled from 'styled-components';
//components
import ourroutes from './ourroutes'
import TopMenu from './top_menu';
import Bottom from './Bottom/Bottomv6_3';
//position: fixed- stays there, not moves even if we scroll the screen
//top strip
const Top=styled.div`
    width: 100%;
    height: 75px;
    background-color: #0c6a9c;
    display:flex;
    justify-content:space-between;
    `

// height auto preserves ratio of image, if you set a height which is not the size of original img
// you can realign objects by using different paddings/margins for all sides

const Title=styled.div`
    float: left;
    padding-top: 1.25%;
    padding-left:10%; 
    font-size: 39px;
    font-family: Times,Helvetica,sans-serif;
    width:50%;
    color:#ff890d;
    @media (max-width: 500px) {
       line-height:70px;
       font-size:25px;
       text-align:center;
      }
    `

export default class LayoutRouter extends React.Component {
    render() {
        return(
        <Router routes={ourroutes}>
            <div className="Main">
                <Top>
                    <Title>UPC Scan v 6.3.15</Title>
                    <TopMenu/> 
                </Top>
                <div className="BottomStyled">
                    <Bottom/>
                </div>
            </div>
        </Router>
        );
    }    
};