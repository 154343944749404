//dependencies
const Papa =require('papaparse');
const bluebird=require('bluebird')
const store=require('../store');


//function which deals with a single request to upc azure function 
function processSingleItemUPC_api(input){
    return new Promise(async function(resolve,reject){
        var bagaz={}
        if(input["11-Digit UPC"]){
            bagaz={
                upcnr:input["11-Digit UPC"],
                l:1
                }
            }
            else{
                bagaz={
                    upcnr:input,
                    l:1
                    }
                }
        //var bagaz={upcnr:values.upcnr, l:0}
        //we create a request
        var request=new Request('https://upcscanningapp.azurewebsites.net/api/UPCBarcodeDB', {            
            method:'POST',
            headers: new Headers({'Accept': 'application/json',
            'Content-Type':'application/json'}),
            body:JSON.stringify(bagaz)
            })
        //we make a request
        fetch(request)
            .then(response=>{
                //error handling
                if (!response.ok) {
                    response.answer='fetch error'
                    resolve(response)
                }
                return response;
            })
        //we get response, in form of json
            .then(response=>response.json())
            .then(response=>{
                console.log('answer from upc',response.answer)
                if(response.answer==='error'){
                    console.log('error',response.add)
                    }
                resolve(response)
                })
            .catch(e=>{
                    e.answer='fetch error'
                    resolve(e)
                })
        })
    }

function processfinalArrays(arrayA,processB,callback){
    let errors=null;
    var arrayAL=arrayA.length
    //console.log('length',arrayAL)
    //console.log('arrayA itself',arrayA)
    //console.log('arrayb itself',processB)
    var finalArray=[]
    for(let a=0;a<arrayAL;++a){
        let finalItem={upcnr:arrayA[a],upcdbanswer:processB[a].answer}
        //let finalItem={upcnr:arrayA[a],upcdbanswer:arrayA[a]}
        finalArray.push(finalItem)
        }
    //console.log('final array',finalArray)
    return callback(errors,finalArray)
    }

/**
* Description: transform csv file (with header) to object
* @param {File} input -csv file
* @returns {Object} js object (ie unc11numbers:bigint)
*/
export function CsvToObject(input){
        return new Promise(async function(resolve,reject){
            //get file and transform it into text
            input.text()
            .then(text=>{
                //create a config for papa parse
                const config={
                    delimiter: ",",
                    header: true,
                    }
                //parse it to json object using promise (to make sure everything is parsed)
                async function parsecsv(text){
                    let results = await Papa.parse(text, config)
                    //returns only data
                    return results.data
                    }
                parsecsv(text)
                    .then(results=>{
                        //send back results
                        resolve(results)
                    })
                })
            })
        }
export function ObjectToCsv(input){
            return new Promise(async function(resolve,reject){
                async function pureCsv(input){
                    let Csv=await Papa.unparse(input)
                    return Csv
                    } 
                pureCsv(input)
                .then(results=>{
                    resolve(results)
                    })
                })
        }
export function ObjectToCsvsemicolon(input){
    return new Promise(async function(resolve,reject){
        async function pureCsv(input){
            const config={
                delimiter: ";",
                header: true,
                }
            let Csv=await Papa.unparse(input,config)
            return Csv
            } 
        pureCsv(input)
        .then(results=>{
            resolve(results)
            })
        })
    }
    
/**
* Description: processes cvs file with callback
* @param {File} input -csv file
* @returns do not know yet what it will return
*/
export function processCsvInputv2(input){
    //we'll need to use throttle 100 requests at once
        return new Promise(async function(resolve,reject){
            //place where we process our await Promise from line 86

            const processA=async(inputA)=>{
                //we flatten our array
                let arrayA=[]
                for(let a=0;a<inputA.length;++a){
                    for(let b=0;b<10;++b){
                        //if we do first round
                        if(inputA[a]["11-Digit UPC"]){
                            let new_number=((inputA[a]["11-Digit UPC"]).toString()).concat(b);       
                            arrayA.push(new_number)                     
                            }
                            //if we do retry round
                            else{
                                //console.log('ignoring processing flattening A')
                                arrayA=inputA
                                break
                            }
                        }
                    }
                console.log('arraya',arrayA)
                //4rd step processing single item by external function(to de-bload it all)
                const processSingleUpcDb=async function(aitem){
                    return new Promise(async function(resolve,reject){
                        store.default.dispatch({type:'progress_counter',payload:1})
                        //asyc wrap for external function
                        async function getSingleItem(aitem){
                            //via external module
                            //console.log('still item?',aitem)
                            let UpdatedSingleItem=await processSingleItemUPC_api(aitem)
                            return UpdatedSingleItem.answer
                            }
                        getSingleItem(aitem)
                            .then(updatedSingleItem=>{
                                let AitemObject={}
                                AitemObject.upcnr=aitem
                                AitemObject.upcdbanswer=updatedSingleItem
                                //Aitem.upcdbanswer=updatedSingleItem
                                resolve(AitemObject)
                                })
                        })
                    }
                //3nd step, map array and pass one item at a time
                //const StartprocessWmt=await mapSeries(ArrayA,processSingleWmt)
                const StartprocessUpcDb=await bluebird.Promise.map(arrayA,processSingleUpcDb,{concurrency: 5})
                .then(results=>{return results})
                return StartprocessUpcDb
                }
            //we take all datapoints
            //processA(results[0])
            processA(input)
                .then(results=>{
                    resolve(results)
                    })
        })        
    }
