import {askTarget,askTargetDB} from '../apicalls/askTarget'
import {askupcdb,askwmtapinew,askUPCBCapi,askUPCBCdb} from '../apicalls/askupc'
import crp from'../components/crp'
const bluebird=require('bluebird')
function myFunc(resolve) {
    var rte='hi '+ Date.now()
    console.log(rte)
    resolve(rte)
    }

function timeouter(bagaz){
    return new Promise(async function(resolve,reject){
        setTimeout(myFunc, 2000, resolve);
        })
    }
//here we process single items
async function processSingleItem(singleitem){
    return new Promise(async function(resolve,reject){
        var bagaz={
            barcode:singleitem["barcode"],
            upcnr:singleitem["barcode"],
            l:1
            }
        async function asklocalUPCbarcodeQuery(bagaz){
            //returning promise
            return new Promise(async function(resolve,reject){
                let answerlocalUPCbarcodeQuery=await askUPCBCdb(bagaz)
                let answertargetdb=await askTargetDB(bagaz)
                let answerwmtdb=await askupcdb(bagaz)
                //we gather all promises
                Promise.all([answerlocalUPCbarcodeQuery,answertargetdb,answerwmtdb])
                .then((answerlocalDbs)=>{
                    //we return our values
                    resolve(answerlocalDbs)
                    })
                })                                               
            }
        asklocalUPCbarcodeQuery(bagaz)
        .then(answerlocalDbs=>{
            var result_payload_object={barcode:singleitem["barcode"]}
            if (answerlocalDbs[0].answer>0||answerlocalDbs[1].answer>0||answerlocalDbs[2].answer>0||answerlocalDbs[1].answer==='ok'){
                let result_payload=crp.barcode2(answerlocalDbs)
                result_payload_object.data=result_payload
                resolve(result_payload_object)
                return
                }
            else{
                resolve(result_payload_object)
                }
            })
        })
    }

//here we process single items
async function processSingleItemApis(singleitem){
    return new Promise(async function(resolve,reject){
        var bagaz={
            barcode:singleitem["barcode"],
            upcnr:singleitem["barcode"],
            l:1
            }
        if(singleitem.data){
            resolve(singleitem)
            return
            }
        else{
            //console.log('kuku',bagaz)
            async function asklocalUPCbarcodeQuery(bagaz){
                //returning promise
                return new Promise(async function(resolve,reject){
                    await timeouter(bagaz)
                    let answerUPCbarcodeQuery=await askUPCBCapi(bagaz)
                    let answerwmtquery=await askwmtapinew(bagaz)
                    let answertargetQuery=await askTarget(bagaz)
                    //we gather all promises
                    Promise.all([answerUPCbarcodeQuery,answertargetQuery,answerwmtquery])
                    .then((answerlocalDbs)=>{
                        //we return our values
                        resolve(answerlocalDbs)
                        })
                    })                                               
                }
            asklocalUPCbarcodeQuery(bagaz)
            .then(answerApis=>{
                var result_payload_object={barcode:singleitem["barcode"]}
                if (answerApis[0].answer>0||answerApis[1].answer>0||answerApis[2].answer>0||answerApis[1].answer==='ok'){
                    let result_payload=crp.barcode2(answerApis)
                    result_payload_object.data=result_payload
                    resolve(result_payload_object)
                    return
                    }
                else{
                    result_payload_object.data=['unknown','unknown']
                    resolve(result_payload_object)
                    return
                    }
                })
            }
        })
    }


//here we dispatch single items from batch
async function processSingleBatch(singlebatch){
    return new Promise(async function(resolve,reject){
        //console.log('single batch',singlebatch)
        //console.log('in single batch',singlebatch)
        //here we need to start do dispatch single items                       
        async function processSingleItems(singlebatch){
            //console.log('before single items',arrayA)
            //const processB=await Promise
            //        .all(arrayA.map(async Aitem=>await getArrayB(Aitem)))
            const updatedsingleitem=await Promise
                .all(singlebatch.map(async Aitem=>await processSingleItem(Aitem)))
            return updatedsingleitem
            }
        processSingleItems(singlebatch)
            .then(resultsSingleItems=>{
                resolve(resultsSingleItems)
                })
        })
    }

//here we dispatch single items from batch
async function processSingleBatchApis(singlebatch){
    return new Promise(async function(resolve,reject){
        //console.log('single batch',singlebatch)
        //console.log('in single batch',singlebatch)
        //here we need to start do dispatch single items                       
        async function processSingleItems(singlebatch){
            //console.log('before single items',arrayA)
            //const processB=await Promise
            //        .all(arrayA.map(async Aitem=>await getArrayB(Aitem)))
            //const updatedsingleitem=await Promise
            //    .all(singlebatch.map(async Aitem=>await processSingleItemApis(Aitem)))
            const updatedsingleitem=await bluebird.Promise.mapSeries(singlebatch,async Aitem=>await processSingleItemApis(Aitem))
            return updatedsingleitem
            }
        processSingleItems(singlebatch)
            .then(resultsSingleItems=>{
                resolve(resultsSingleItems)
                })
        })
    }


export function getBarcodeDetails(input){
        return new Promise (async function(resolve,reject){
            //console.log('our before',input)
            //here we will split the array to sub-arrays 50 items each
            var inputL=input.length
            //console.log('length of input',inputL)
            //checking how many times we need to iterate over
            var iterNr=Math.ceil(inputL/50)
            //console.log('r of iterations',iterNr)
            let arrayA=[]
            //preparing loads to check over (10 for each batch)
            for(let a=0;a<iterNr;++a){
                //iterate over each of 10th
                let bIter=50;
                if(a===iterNr-1){
                    bIter=inputL-1-(iterNr-1)*50
                    //console.log('last Biter',bIter)
                    }
                let temp_arr=[];
                for (let b=0;b<bIter;++b){
                    //checking the value
                    let c=a*50+b
                    temp_arr.push(input[c])
                    }
                 //we add found to array
                 arrayA.push(temp_arr)
                 }
            //arrayA=arrayA.slice(0,1)
            //arrayA=[[{"11-Digit UPC":4155405501},{"11-Digit UPC":"04155405601"},{"11-Digit UPC":4155405611}],
            //[{"11-Digit UPC":4155405491},{"11-Digit UPC":"04155405621"},{"11-Digit UPC":4155405604}]
            // ]
            //here we need to start do dispatch batches                        
            async function processBatchaTime(arrayA){
                //console.log('before single items',arrayA)
                //const updatedCorrel=await mapSeries(arrayA,processSingleBatch)
                const updatedCorrel=await bluebird.Promise.map(arrayA,processSingleBatch,{concurrency: 1})
                        .then(results=>{return results})
                return updatedCorrel
             }
            processBatchaTime(arrayA)
                .then(results=>{
                    //console.log('our changed',results)
                    resolve(results)
                    })
        })
    }

export function getBarcodeDetailsApis(input){
    return new Promise (async function(resolve,reject){
        //console.log('our before apis',input)
        let arrayA=input
        //i thinkit is already in batches
        /*
        //here we will split the array to sub-arrays 50 items each
        var inputL=input.length
        //console.log('length of input',inputL)
        //checking how many times we need to iterate over
        var iterNr=Math.ceil(inputL/50)
        //console.log('r of iterations',iterNr)
        let arrayA=[]
        //preparing loads to check over (10 for each batch)
        for(let a=0;a<iterNr;++a){
            //iterate over each of 10th
            let bIter=50;
            if(a===iterNr-1){
                bIter=inputL-1-(iterNr-1)*50
                //console.log('last Biter',bIter)
                }
            let temp_arr=[];
            for (let b=0;b<bIter;++b){
                //checking the value
                let c=a*50+b
                temp_arr.push(input[c])
                }
                //we add found to array
                arrayA.push(temp_arr)
                }
        */
        //arrayA=arrayA.slice(0,1)
        //arrayA=[[{"11-Digit UPC":4155405501},{"11-Digit UPC":"04155405601"},{"11-Digit UPC":4155405611}],
        //[{"11-Digit UPC":4155405491},{"11-Digit UPC":"04155405621"},{"11-Digit UPC":4155405604}]
        // ]
        //here we need to start do dispatch batches                        
        async function processBatchaTime(arrayA){
            //console.log('before single items',arrayA)
            //const updatedCorrel=await mapSeries(arrayA,processSingleBatch)
            const updatedCorrel=await bluebird.Promise.map(arrayA,processSingleBatchApis,{concurrency: 1})
                    .then(results=>{return results})
            return updatedCorrel
            }
        processBatchaTime(arrayA)
            .then(results=>{
                //console.log('after getbarcodedetailsapis',results)
                resolve(results)
                })
        })
    }