
export function saveManifestItem(bagaz){
    return new Promise(async function(resolve,reject){
        //most likely we will check shops api to see if such item has not been uploaded already
        //if not, then we send the load to shopify
        //as we amend the local db table with what has been send
        bagaz.l=1
        var request=new Request('https://upcscanningapp.azurewebsites.net/api/save_manifest_item', {            
            method:'POST',
            headers: new Headers({'Accept': 'application/json',
            'Content-Type':'application/json'}),
            body:JSON.stringify(bagaz)
            })
        //we make a request
        fetch(request)
        //we get response, in form of json
            .then(response=>response.json())
            .then(response=>{
                console.log('manifests answer',response)
                resolve(response)
            })
        })
    }

export function GetSerialNumberListDetails(bagaz){
    return new Promise(async function(resolve,reject){
        //most likely we will check shops api to see if such item has not been uploaded already
        //if not, then we send the load to shopify
        //as we amend the local db table with what has been send
        bagaz.l=1
        var request=new Request('https://upcscanningapp.azurewebsites.net/api/GetSerialNumberListDetails', {            
            method:'POST',
            headers: new Headers({'Accept': 'application/json',
            'Content-Type':'application/json'}),
            body:JSON.stringify(bagaz)
            })
        //we make a request
        fetch(request)
        //we get response, in form of json
            .then(response=>response.json())
            .then(response=>{
                console.log('manifests answer',response)
                resolve(response)
            })
        })
    }