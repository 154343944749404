import React,{Component} from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';
import {Formik} from 'formik';
import * as Yup from 'yup';

//components
//import {writeScanLogs} from '../apicalls/askScanLogs'
import {askTarget,askTargetDB} from '../apicalls/askTarget'

import ResultsBarcode from '../components/ResultsBarcodev5_1'
//import ResultsBarcode from '../components/ResultsBarcodev5_1test'

import crp from'../components/crp'
//import Converter from '../components/Converter'
//import Decoder from '../components/Decoder'
import {askupcdb,askwmtapinew,askUPCBCapi,askUPCBCdb} from '../apicalls/askupc'

const ButtonStyled=styled.button`
    
    top:400px;
    text-align: center;
    color:black;
    background:  #c6a9c; 
    padding-left:10px;
    padding-right:10px;
    margin-left:2px;
    border: 2px solid #58402e;
    border-radius: 2px;
    font-size:13px;
`

const SearchBarStyled=styled.div`
    grid-template-rows: auto;
    display: flex;
`
const SearchTitle=styled.h4`

    height:100%;
    width:35%;
    <!--background-color:lightgrey;-->
    color:#45240A;
    font-family: Arial,Times,Helvetica,sans-serif;
    font-size:16px;
    display:flex;
    align-self: left;
    `
const Communication=styled.h4`

    <--!background-color:lightgreen;-->
    height:100%;
    width:50%;
    font-size:16px;
    font-family: Arial,Times,Helvetica,sans-serif;
    opacity:80%;
    `
const ResultsStyled=styled.div`
    height:470px;
    `
const IDField=styled.div`
    width:100%;
    font-size:15px;
    `
    class Scanner extends Component {
        constructor(){
            super()
            this.state={
                modal:'',
                communicate:'',
                };
            this.textInput = React.createRef();
        }
        componentDidUpdate(prevProps, prevState){
            if(prevState.modal!==this.state.modal){
                console.log('modal changed to',this.state.modal)
                }
            if(prevProps.communicate!==this.props.communicate){
                this.setState({communicate:this.props.communicate})
                }
            if(prevProps.autoFocus!==this.props.autoFocus){
                if(this.props.autoFocus===true){
                    //force re-render
                    //console.log('forcing render')
                    this.textInput.current.focus();
                    }
                }
            }
        render(){
                return(
                    <div>
                        <div id="MainContent">
                            <IDField>
                                {this.props.poH!=='' && 'PO#: '+this.props.poH + ' Station ID: '+ this.props.stationId}
                            </IDField>
                            <SearchBarStyled>
                                <SearchTitle>BARCODE: 
                                    <Formik display="flex" width="auto"
                                    initialValues={{ upcnr: "",isForgotButton:false }}
                                    validationSchema={Yup.object().shape({
                                        upcnr:Yup.string()
                                        .min(11,'11 digits minimum')
                                        .max(13,'13 digits maximum')
                                        .required('required value')
                                        }) 
                                    }
                                    displayData={(response)=>{
            
                                    }}
                                    onSubmit={async (values, { setSubmitting,resetForm }) => {
                                        //we reset our errors before submission
                                        this.setState({errors:{},communicateAzure:''})
                                        //we set the autoFocus to False
                                        this.props.dispatch({type:"autoFocus",payload:false})
                                        //we reset our results screen
                                        var result_payload=[]
                                        let scanned_barcode=values.upcnr.replace(/\s/g,'')
                                        this.props.dispatch({type:"targetOwned",payload:false})
                                        this.props.dispatch({type:"results_to_show",payload:result_payload});
                                        this.props.dispatch({type:"scanned_barcode",payload:scanned_barcode});
                                        this.props.dispatch({type:'scan_value',payload:values.upcnr})
                                        //we create timestamp:
                                        var timestamps=Date.now()
                                        //we create payload for request
                                        var bagaz={
                                            upcnr:values.upcnr,
                                            barcode:values.upcnr,
                                            l:1
                                            }
                                        //var bagaz={upcnr:values.upcnr, l:0}
                                        console.log('cest parti!')
                                        //we say that we start
                                        this.setState({communicate:"Fetching..."});
                                        //we connect to UPC DB and API -always, no matter if it is 12 or 13 (later we might add number to 13 stricng stuff)
                                        //we create payload for scan_logs
                                        var scan_log_bagaz={
                                            user_id:this.props.acc_ID,
                                            station_id:this.props.stationId,
                                            po_h:this.props.poH,
                                            barcode:values.upcnr,
                                            override:null,
                                            timestamps:timestamps,
                                            l:1
                                            }
                                        //we connect local upc_api_db wmt and target local dbs
                                        async function asklocalUPCbarcodeQuery(bagaz){
                                            //returning promise
                                            return new Promise(async function(resolve,reject){
                                                let answerlocalUPCbarcodeQuery=await askUPCBCdb(bagaz)
                                                let answertargetdb=await askTargetDB(scan_log_bagaz)
                                                let answerwmtdb=await askupcdb(bagaz)
                                                //let answerwmtdb={answer:0}
                                                //we gather all promises
                                                Promise.all([answerlocalUPCbarcodeQuery,answertargetdb,answerwmtdb])
                                                .then((answerlocalDbs)=>{
                                                    //we return our values
                                                    resolve(answerlocalDbs)
                                                    })
                                                })                                               
                                            }
                                        asklocalUPCbarcodeQuery(bagaz,scan_log_bagaz)
                                            .then(answerlocalDbs=>{
                                                console.log('local dbs,',answerlocalDbs)
                                                //if we have an answer 
                                                if (answerlocalDbs[0].answer>0||answerlocalDbs[1].answer>0||answerlocalDbs[2].answer>0){
                                                    //if we have a code
                                                    this.setState({communicate:"Barcode fetched from local database. Displaying"});
                                                    console.log('we took barcode from local db')
                                                    //building payload
                                                    //0-upc,
                                                    var result_payload=crp.barcode2(answerlocalDbs)
                                                    //sending it to the reductor
                                                    this.props.dispatch({type:"targetOwned",payload:result_payload[10]})
                                                    this.props.dispatch({type:"results_to_show",payload:result_payload});
                                                    resetForm({})
                                                    //and we open the condition modal
                                                    //this.setState({modal:"condition"})
                                                    this.props.dispatch({type:"SET_SHOW_LOGIN_STATUS",payload:"condition"})
                                                    return
                                                    }
                                                //if we do not have the answer
                                                else{
                                                    this.setState({communicate:"No such item in local DBS, starting fetching from APIS"});
                                                    async function askapis(scan_log_bagaz){
                                                        //returning promise
                                                        return new Promise(async function(resolve,reject){
                                                            //we look in upc api
                                                            let answerUPCbarcodeQuery=await askUPCBCapi(bagaz)
                                                            //let answerUPCbarcodeQuery={answer:0}
                                                            let answertargetQuery=await askTarget(scan_log_bagaz)
                                                            let answerwmtquery=await askwmtapinew(bagaz)
                                                            //let answerwmtquery={answer:0}
                                                            Promise.all([answerUPCbarcodeQuery,answertargetQuery,answerwmtquery])
                                                            .then((answerApis)=>{
                                                                //we return our values
                                                                console.log('answer from apis',answerApis)
                                                                resolve(answerApis)
                                                                })
                                                            })
                                                        }
                                                    askapis(scan_log_bagaz)                                                                                                                
                                                    .then(answerApis=>{
                                                        console.log('afer answer apis',answerApis)
                                                        //if we have a response with data
                                                        if (answerApis[0].answer>0||answerApis[1].answer>0||answerApis[2].answer>0){
                                                            //if we have a code
                                                            this.setState({communicate:"EAN fetched and saved to local database. Displaying"});
                                                            //building payload
                                                            var result_payload=crp.barcode2(answerApis)
                                                            console.log('result_payload',result_payload)
                                                            //sending it to the reductor
                                                            this.props.dispatch({type:"targetOwned",payload:result_payload[10]})
                                                            this.props.dispatch({type:"results_to_show",payload:result_payload});
                                                            resetForm({})
                                                            //and we open the condition modal
                                                            //this.setState({modal:"condition"})
                                                            this.props.dispatch({type:"SET_SHOW_LOGIN_STATUS",payload:"condition"})
                                                            return
                                                            }
                                                        //if no positive answer
                                                        else{
                                                            console.log('nothing to show')
                                                            this.setState({communicate:'Barcode Not Found'});
                                                            resetForm({})
                                                            //and we open the condition modal
                                                            //this.setState({modal:"condition"})
                                                            this.props.dispatch({type:"SET_SHOW_LOGIN_STATUS",payload:"condition"})
                                                            return
                                                            }
                                                        })
                                                    }
                                                })
                                        }}
            
                                        >
                                        {props => {
                                        const {
                                        values,
                                        touched,
                                        errors,
                                        isSubmitting,
                                        handleChange,
                                        handleBlur,
                                        handleSubmit,
                                        } = props;
                                        return (
                                            <form display="flex" onSubmit={handleSubmit}>
                                                <input
                                                    ref={this.textInput}
                                                    autoFocus={this.props.autoFocus}
                                                    display="flex"
                                                    name="upcnr"
                                                    type="text"
                                                    placeholder="           numbers only"
                                                    value={values.upcnr}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={errors.upcnr && touched.upcnr && "error"}
                                                    />
                                                <ButtonStyled type="submit" disabled={isSubmitting}>submit</ButtonStyled>
                                                <div className="FormikErrors">{errors.upcnr}</div>
                                            </form>
                                            )
                                        }}
                                    </Formik>
                                </SearchTitle>
                                <Communication>
                                    {this.state.communicate}
                                </Communication>
                            </SearchBarStyled>
                            <ResultsStyled >
                                <ResultsBarcode/>
                            </ResultsStyled>
                        </div>
                    </div>
                    )
                }
    }

const mapStateToProps=(state)=>({
    autoFocus:state.autoFocus,
    communicate:state.communicate,
    LOGGED:state.LOGGED,
    results_to_show:state.results_to_show,
    scan_value:state.scan_value,
    showlogin:state.showlogin,
    MenuOption:state.MenuOption,
    acc_ID:state.acc_ID,
    stationId:state.stationId,
    poH:state.poH
})
export default connect(mapStateToProps)(Scanner);


/*
        let bagaz={token:token,
        //barcode:"00302995000042",
        //barcode:"045496596439",
        l:1}
*/