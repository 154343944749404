export async function askTarget(bagaz){
    return new Promise(async function(resolve,reject){
        console.log('target bagaz',bagaz.barcode)
        let barcode_l=bagaz.barcode.length
        let barcode_original=bagaz.barcode
        let barcode_original_l=barcode_l
        if(barcode_l<12){
            let diff=12-barcode_l;
            for (let add0=1;add0<diff;++add0){
                bagaz.barcode='0'+(bagaz.barcode).toString()
                }
            }
        console.log('target bagaz',bagaz.barcode,'length',barcode_l)
        var request=new Request('https://upcscanningapp.azurewebsites.net/api/askTargetGraph', {            
            method:'POST',
            headers: new Headers({'Accept': 'application/json',
            'Content-Type':'application/json'}),
            body:JSON.stringify(bagaz)
            })
        //we make a request
        fetch(request)
        //we get response, in form of json
            .then(response=>response.json())
            .then(response=>{
                console.log('targetgraph returns',response)
                //if we have tcin
                if(response.answer.items){
                    //console.log('targetgraph we have item',response.answer)
                    if(response.answer.items[0]){
                        //console.log('targetgraph we have item[0]')
                        if(response.answer.items[0].tcin){
                            //we call target other api to get online price
                            //console.log('tcin',response.answer.items[0].tcin)
                            var tcin=response.answer.items[0].tcin
                            var bagaz2={tcin:tcin}
                            var request=new Request('https://upcscanningapp.azurewebsites.net/api/askTargetApi', {            
                                method:'POST',
                                headers: new Headers({'Accept': 'application/json',
                                'Content-Type':'application/json'}),
                                body:JSON.stringify(bagaz2)
                                })
                            //we make a request
                            fetch(request)
                            //we get response, in form of json
                                .then(response2=>response2.json())
                                .then(response2=>{
                                    response.tagetPrice=response2
                                    //console.log('response2',response)
                                    var ean_loop=response.answer.items
                                    let EAN=null;
                                    let ML=null;
                                    let TITLE=null;
                                    let BRAND=null
                                    let ISTARGETOWNED=null;
                                    let IMAGE=null;
                                    let COLOR=null;
                                    let SIZE=null;
                                    let WEIGHT=null;
                                    let WEIGHT_UNIT=null;
                                    let DIMENSIONS=null;
                                    let MSRP=null;
                                    let SALEPRICE=null;

                                    //loop through items to find upc
                                    for (let iteritems=0;iteritems<ean_loop.length;++iteritems){
                                        //loop to get title
                                        let checktitle=ean_loop[iteritems].description.title
                                        if (TITLE===null){
                                            TITLE=checktitle
                                            }
                                        //loop through brands
                                        for(let iterbrands=0;iterbrands<ean_loop[iteritems].brands.length;++iterbrands){
                                            let checkbrand=ean_loop[iteritems].brands[iterbrands].name
                                            if(BRAND===null){
                                                BRAND=checkbrand
                                                }
                                            let checktargetowned=ean_loop[iteritems].brands[iterbrands].isTargetOwned
                                            if (ISTARGETOWNED===null){
                                                ISTARGETOWNED=checktargetowned
                                                }
                                            }
                                        //get image
                                        if(ean_loop[iteritems].digitalAssets.images){
                                            let checkimage=`https:`+ean_loop[iteritems].digitalAssets.images.baseUrl+ean_loop[iteritems].digitalAssets.images.primaryImage
                                            if(IMAGE===null&&checkimage.length>6){
                                                IMAGE=checkimage
                                                }
                                            }
                                        //get weight and dimension
                                        WEIGHT=ean_loop[iteritems].operational.packageDimensions.measurements.weight.value
                                        WEIGHT_UNIT=ean_loop[iteritems].operational.packageDimensions.measurements.weight.code
                                        let dims=ean_loop[iteritems].operational.packageDimensions.measurements.dimensions
                                        DIMENSIONS=dims.depth + ' x ' + dims.height + ' x '+ dims.width + ' ' +dims.units
                                        //loop through themes in variations
                                        for(let iterthemes=0;iterthemes<ean_loop[iteritems].variations.themes.length;++iterthemes){
                                            let checktheme=ean_loop[iteritems].variations.themes[iterthemes].name
                                            if(checktheme==='color'){
                                                COLOR=ean_loop[iteritems].variations.themes[iterthemes].value
                                                }
                                            if(checktheme==='size'){
                                                SIZE=ean_loop[iteritems].variations.themes[iterthemes].value
                                                }
                                            }
                                        //loop through vendors
                                        for(let itervendors=0;itervendors<ean_loop[iteritems].vendors.length;++itervendors){
                                            //loop through barcodes
                                            for (let iterbarcodes=0;iterbarcodes<ean_loop[iteritems].vendors[itervendors].barcodes.length;++iterbarcodes){
                                                //checking type of a number
                                                //console.log('inside iterbarcodes',ean_loop[iteritems].vendors[itervendors].barcodes[iterbarcodes])
                                                let checknumber=ean_loop[iteritems].vendors[itervendors].barcodes[iterbarcodes].type
                                                //if checknumber is EAN13 or ML12
                                                /*
                                                barcode_original
                                                barcode_original_l
                                                */

                                                if (checknumber==='EAN13'){
                                                    //if the barcode is equal to barcode.original
                                                    if(ean_loop[iteritems].vendors[itervendors].barcodes[iterbarcodes].number===barcode_original){
                                                        EAN=barcode_original
                                                        }
                                                    else{
                                                        if(EAN===null){
                                                            EAN=ean_loop[iteritems].vendors[itervendors].barcodes[iterbarcodes].number
                                                            } 
                                                        }
                                                    }
                                                if(checknumber==='UPC'||checknumber==='SY12'||checknumber==='ML12'){
                                                    if(ean_loop[iteritems].vendors[itervendors].barcodes[iterbarcodes].number===barcode_original){
                                                        ML=barcode_original
                                                        }
                                                    else{
                                                        if(ML===null){
                                                            ML=ean_loop[iteritems].vendors[itervendors].barcodes[iterbarcodes].number
                                                            }
                                                        }
                                                    }
                                                //checking if we have both
                                                if(EAN!==null&&ML!==null){
                                                    //we break the for barcodes loop
                                                    break
                                                    }
                                                }
                                            //checking if we have both
                                            if(EAN!==null&&ML!==null){
                                                //we break the for vendors loop
                                                break
                                                }
                                            }
                                        //checking if we have both
                                        if(EAN!==null&&ML!==null){
                                            //we break the for items loop
                                            break
                                            }
                                        }
                                    //loop to get prices
                                    if(response.tagetPrice.add==='we have error'){
                                        MSRP=null
                                        SALEPRICE=null
                                        }
                                    else{
                                        //console.log('resp',response.tagetPrice.answer.price,'of',response)
                                        MSRP=response.tagetPrice.answer.price.msrp||response.tagetPrice.answer.price.reg_retail
                                        SALEPRICE=response.tagetPrice.answer.price.current_retail
                                        }
                                    //i see nothing which we could use as category, model (these 2 can be taken from upc)
                                    var bagaz3={UPC:ML,
                                        EAN:EAN,
                                        Title:TITLE,
                                        Brand:BRAND,
                                        Category:null,
                                        Model:null,
                                        Image:IMAGE,
                                        Color:COLOR,
                                        Size:SIZE,
                                        Weight:WEIGHT + ' ' + WEIGHT_UNIT,
                                        Dimensions:DIMENSIONS,
                                        TargetOwned:ISTARGETOWNED,
                                        MSRP:MSRP,
                                        SalePrice:SALEPRICE
                                        }
                                    console.log('saving to target db',bagaz3)
                                    //we pass payload to main file
                                    //and we save the results independently
                                    var request3=new Request('https://upcscanningapp.azurewebsites.net/api/saveTargetRes', {            
                                        method:'POST',
                                        headers: new Headers({'Accept': 'application/json',
                                        'Content-Type':'application/json'}),
                                        body:JSON.stringify(bagaz3)
                                        })
                                        fetch(request3)
                                        .then(response3=>response3.json())
                                        .then(response3=>{
                                            var bagazans=0
                                            //console.log('response from target',response3.answer)
                                            if(response3.answer==='ok'){
                                                bagazans=1;
                                                }
                                            var bagaz4={answer:bagazans,
                                                    fullans:{ 
                                                        upc:ML,
                                                        ean:EAN,
                                                        title:TITLE,
                                                        brand:BRAND,
                                                        category:null,
                                                        model:null,
                                                        image:IMAGE,
                                                        color:COLOR,
                                                        size:SIZE,
                                                        weight:WEIGHT + ' ' + WEIGHT_UNIT,
                                                        dimensions:DIMENSIONS,
                                                        targetowned:ISTARGETOWNED,
                                                        msrp:MSRP,
                                                        saleprice:SALEPRICE
                                                        }
                                                    }
                                            //console.log('answer from target db',response3)
                                            //console.log('target returns',bagaz4)
                                            console.log('target returns')
                                            resolve(bagaz4)
                                            })
                                    })
                            }
                        }
                        //if we have empty array
                        else{
                            //then we have no item
                            var bagazansno={answer:0}
                            //console.log('targer api no items')
                            resolve(bagazansno)
                            }
                    }
                else{
                    bagazansno={answer:0}
                    //console.log('targer api no items')
                    resolve(bagazansno)
                }                
            })
        })
    }
//target accepts strings only
export async function askTargetDB(bagaz){
    return new Promise(async function(resolve,reject){
        var bagazyk={barcode:bagaz.barcode}

        if(bagazyk.barcode.length<12){
            bagazyk.barcode='0'+bagazyk.barcode
            }
        var request=new Request('https://upcscanningapp.azurewebsites.net/api/TargetBarcodeDB', {            
            method:'POST',
            headers: new Headers({'Accept': 'application/json',
            'Content-Type':'application/json'}),
            body:JSON.stringify(bagazyk)
            })
        //we make a request
        fetch(request)
        //we get response, in form of json
            .then(response=>response.json())
            .then(response=>{
                //console.log('inside asktargetdb',response)
                resolve(response)
                })
        })
    }