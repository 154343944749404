import React from 'react';
import {Switch,
    Route,
    Redirect} from 'react-router-dom';
import LoginC from './LoginC';
import Modal from './Modal';


export default  (
    <Switch>
        <Route exact path="/Login">
            <LoginC />
        </Route>
          <Route path="/Modal">
              <Redirect to ={{pathname:'/Login'}}/>
              <Route path='/Login' component={LoginC}/>
            <Modal /> 
        </Route> 
    </Switch>
)
