import React, {Component} from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components'
const LoginDiv=styled.div`
display:inline;
align:right;
float:right;
padding-right:20px;
font-family: Segoe UI, Times, Helvetica, sans-serif;
color: ghostwhite;
font-size: 20px;
cursor:pointer;
`
//class dealing with login
class Logout extends Component {
    constructor(){
        super()
       this.showLogin = this.showLogin.bind(this);
    };
    showLogin=()=> {
        this.props.dispatch({type:"SET_SHOW_LOGIN_STATUS", payload:0})
        this.props.dispatch({type:"LOGGED", payload:0})
        this.props.dispatch({type:"MenuOption",payload:1})
        this.props.dispatch({type:"results_to_show",payload:[]})
        this.props.dispatch({type:"acc_ID",payload:null})
        this.props.dispatch({type:"poH",payload:''})
        this.props.dispatch({type:"stationId",payload:''})
        }
    render() {       
        return(           
            <LoginDiv onClick={this.showLogin}>Log out </LoginDiv>
        )
    }
}
const mapStateToProps=(state)=>({
    showlogin:state.showlogin,
})
export default connect(mapStateToProps)(Logout);