//initial state:
//showlogin-0 a login button
//showlogin-1 a logout button
//LOGGED:0
//testing
/*
const intialstatelr={
  autoFocus:true,
  acc_type:'testing',
  acc_ID:0,
  showlogin:0,
  LOGGED:1,
  results_to_show:[],
  MenuOption:1,
  poH:'',
  progress_counter:0,
  serialnumbers_range:[],
  stationId:''
  }



//admin initial
    acc_type:'admin',
    acc_ID:2,
    autoFocus:true,
    showlogin:2,
    LOGGED:1,
    results_to_show:[],
    MenuOption:1,
    poH:'',
    progress_counter:0,
    sku_to_print:null,
    stationId:''
//associate
  const intialstatelr={
    autoFocus:true,
    acc_type:'associate',
    acc_ID:4,
    showlogin:0,
    LOGGED:1,
    results_to_show:[],
    MenuOption:'associate_main_view',
    poH:'',
    progress_counter:0,
    stationId:''
  }

const intialstatelr={
    autoFocus:true,
    acc_type:0,
    acc_ID:'',
    showlogin:0,
    LOGGED:0,
    results_to_show:[],
    MenuOption:1,
    poH:'',
    progress_counter:0,
    serialnumbers_range:[],
    stationId:''
  }
  */
  
  const intialstatelr={
    autoFocus:true,
    acc_type:0,
    acc_ID:0,
    showlogin:0,
    LOGGED:0,
    results_to_show:[],
    MenuOption:1,
    poH:'',
    progress_counter:0,
    serialnumbers_range:[],
    stationId:''
    }

  const ourReducer=function(state=intialstatelr, action){
    switch (action.type) {
      case "acc_ID":{
        state= {...state, acc_ID:action.payload}
        break;
        }
      case "acc_type":{
        state= {...state, acc_type:action.payload}
        break;
        }
      case "autoFocus":{
        state= {...state, autoFocus:action.payload}
        break;
        }
      case "communicate":{
        state= {...state, communicate:action.payload}
        break;
        }
      case "item_condition":{
        state={...state,item_condition:action.payload}
        break;
        }
      //0-not logged, 1-logged
      case "LOGGED":{
        state={...state,LOGGED:action.payload}
        break;
        }
      case "SET_SHOW_LOGIN_STATUS":{
        state= {...state, showlogin:action.payload}
        break;
        }
      case "m_sku_found":{
        state={...state,m_sku_found:action.payload}
        break;
        }
      case "MenuOption":{
        state={...state,MenuOption:action.payload}
        break;
        }
      case "override":{
        state={...state,override:action.payload}
        break;
        }
      case "override_product_name":{
        state={...state,override_product_name:action.payload}
        break;
        }
      case "poH":{
        state={...state,poH:action.payload}
        break;
          }
      case "previous_product_name":{
        state={...state,previous_product_name:action.payload}
        break;
        }
      case "product_name":{
        state={...state,product_name:action.payload}
        break;
        }
      case "progress_counter":{
        state={...state,progress_counter:state.progress_counter+action.payload}
        break;
        }
      case "progress_counter_reset":{
          state={...state,progress_counter:action.payload}
          break;
          }
      case "reprint":{
        state={...state,reprint:action.payload}
        break;
        }
      case "results_to_show":{
          state={...state,results_to_show:action.payload}
          break;
          }
      case "scan_value":{
          state={...state,scan_value:action.payload}
          break;
          }
      case "scanned_barcode":{
          state={...state,scanned_barcode:action.payload}
          break;
          }
      case "serialnumber":{
        state={...state,serialnumber:action.payload}
        break
        }
      case "serialnumbers_range":{
        state={...state,serialnumbers_range:action.payload}
        break
        }
      case "sku_to_print":{
          state={...state,sku_to_print:action.payload}
          break
        }
      case "stationId":{
          state={...state,stationId:action.payload}
          break;
          }
      case "targetOwned":{
          state={...state,targetOwned:action.payload}
          break
          }
  //        return {
  //            ...state,
  //            showlogin:false,
  //            };
  //        break;
  //        }
  //        case "yyyy": {
  //            state= {...state, showlogin:state.showlogin+action.payload}
  //            break;
  //            }
        default:
          return state
      }
    return state;
  }
  export default ourReducer;