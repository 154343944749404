import React,{Component} from 'react';
import {connect} from 'react-redux';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';
//internal dependencies
import {GetSerialNumbersRange} from '../../apicalls/GetWhlsSerialNumber'

class ManifestDL extends Component {
    constructor(){
        super()
        this.state={
            date_selected:null,
            start_date:null,
            end_date:null,
            communicate:''
            }
        this.handleKeyUp=this.handleKeyUp.bind(this)
        this.handleSelect=this.handleSelect.bind(this)
        this.handleRangeSelect=this.handleRangeSelect.bind(this)
    }
    handleKeyUp(e) {
        //get the function inside the function(?)
        //asking for a key
        console.log('our props',e)
        const keys = {
        //if 27 (esc)
          27: () => {
              console.log('clciked esc')
            //prevents submit
            e.preventDefault();
            },
          13: ()=>{
            //prevents submit
            e.preventDefault();
            console.log('clicked enter',document.activeElement.name)
            if(document.activeElement.name==="upcnr"){
                this.inputText2.current.focus();
                }
            }
        }
        //if the key has ben pressed, run function to determine if it was esc
        if (keys[e.keyCode]) {
            keys[e.keyCode]();
          }
    }
    handleSelect(dater){
        console.log('our date',dater)
        this.setState({date_selected:dater})
        }
    handleRangeSelect(daters){
        let range_start_date=daters.selection.startDate
        this.setState({start_date:range_start_date})
        let range_end_date=daters.selection.endDate
        this.setState({end_date:range_end_date})
        console.log('our dates',range_start_date,'send',range_end_date)
        }
    async rundates (dates){
        console.log('start date',this.state.start_date,'end date',this.state.end_date)
        //transforming it to timestamp
        if(this.state.start_date){
            let start_timestamp=this.state.start_date.getTime()
            let end_timestamp=this.state.end_date.getTime()
            if(start_timestamp===end_timestamp){
                end_timestamp=start_timestamp+86400000
                }
            let bagaz={start:start_timestamp,
                end:end_timestamp}
            console.log('timestamps?',bagaz)
            let serialresponse=await GetSerialNumbersRange(bagaz)
            console.log('response from serial numbers',serialresponse)
            if(serialresponse.answer==='ok'){
                console.log('no errors')
                if(serialresponse.data.length>0){
                    this.setState({communicate:'data fetched'})
                    this.props.dispatch({type:"serialnumbers_range",payload:serialresponse.data})
                    this.props.dispatch({type:"MenuOption",payload:"ManifestDLDownload"})
                    }
                else{
                    this.setState({communicate:'no records within selected date range'})
                    }
                }
            else{
                console.log('errors')
                this.setState({communicate:'errors. please try again in a few moments'})
                return
                }   
            }
        else{
            //nothing
            this.setState({communicate:"please select date before running for results"})
            }
        }
    //while creatiung component
    componentDidMount() {
        //console.log("has mounted");
        //adding keyboard listener (keyup-press a key, handlekeyup-function which is triggered, false-?)
        window.addEventListener('keyup', this.handleKeyUp, false);
        //this.inputText.current.focus();
        }
    //while unmounting component
    componentWillUnmount(){
        //we remove event listener (for keyboard)
        window.removeEventListener("keyup", this.handleKeyUp, false);
        }
    //when re-enabling component
    componentDidUpdate(prevState){
        if(prevState.communicate!==this.state.communicate){
            console.log('communicate changed')
            }
        //if(prevState.start_date!==this.state.start_date){
        //    if(this.state.communicate==='please select date before running for results'){
        //        this.delete_comm()
        //        }
        //    }
        }
    render(){
        const selectionRange=(start_date,end_date)=> {
            return {
            startDate: start_date||new Date(),
            endDate: end_date||new Date(),
            key: 'selection'
                }
          }
        return(
            <div className="ecom_flex">
                <div className="ecom_search_title">
                    Manifest DL
                </div>
                <div>
                <DateRangePicker
                    ranges={[selectionRange(this.state.start_date,this.state.end_date)]}
                    onChange={this.handleRangeSelect}
                    />
                </div>
                <button className="ecom_search_button_confirm" onClick={()=>this.rundates(this.state.date_selected)}>Run </button>
                <div style={{color:"red"}}>
                    {this.state.communicate||'\u00a0'}
                </div>
            </div>
            )
    }
}
const mapStateToProps=(state)=>({
    LOGGED:state.LOGGED,
    MenuOption:state.MenuOption,
    acc_type:state.acc_type,
    serialnumbers_range:state.serialnumbers_range
    })
export default connect(mapStateToProps)(ManifestDL);