//in ourReduced, in inistialstatelr LOGGED is changed to 1 from 0
//dependencies
import React,{Component} from 'react';
import {connect} from 'react-redux';
//components

import Modal from '../../components/Modal';

//import associate_menu
import AssociateMenu from './AssociateMenu_v6_3'
//import Converter from '../components/Converter'
import Download from '../../components/Download'
import Ecom from './Ecom.js'
//import Ecom from './Bottom/Ecom_test.js'
import Manifest from './Manifest'
import ManifestDL from './ManifestDL'
import ManifestDLDownload from './ManifestDLDownload'
import Manifestp2 from './Manifestp2'
import Sort from './Sort.js'
import Testing from './Testing'
//import Sort from './Bottom/Sort_test.js'
import WhlsAdd from './Whls_add.js'
import WhlsRemove from './Whls_remove'
//import scanner
import Scanner from '../Scanner'
//import Decoder from '../components/Decoder'
import UploadMissingDigits from '../UploadMissingDigits'

class Bottom extends Component {
    render(){
        //if we are not logged
        if (this.props.LOGGED===0){
            return <Modal/> 
        }
        //if we are logged
        else{
            //console.log('not logger=0')
            //if it is 
            switch(this.props.MenuOption){
                case "associate_main":{
                    return(
                        <div className='associate_main'>
                            <AssociateMenu/>
                        </div>
                        )
                    }
                case "ecom":{
                    return(
                        <div>
                            <Ecom/>
                        </div>
                        )
                    }
                case "manifest":{
                    return(
                        <div>
                            <Manifest/>
                        </div>
                        )
                    }
                case "manifestdl":{
                    return(
                        <div>
                            <ManifestDL/>
                        </div>
                        )
                    }
                case "ManifestDLDownload":{
                    return(
                        <div>
                            <ManifestDLDownload/>
                        </div>
                        )
                }
                case "manifestp2":{
                    return(
                        <div>
                            <Manifestp2/>
                        </div>
                        )
                    }
                case "sort":{
                    return(
                        <div>
                            <Sort/>
                        </div>
                        )
                    }
                case "testing":{
                    return(
                        <div>
                        <Testing/>
                    </div>
                        )
                    }
                case "whls_add":{
                    //console.log('menu option whls_add',this.props.showlogin)
                    if(this.props.showlogin==='sku_print_modal'){
                        //console.log('this print showlogin=sku_print_modal')
                        return(
                            <div>
                                <WhlsAdd/>
                                <Modal/>
                            </div>
                            )
                        }
                    else{
                        return(
                            <div>
                                <WhlsAdd/>
                            </div>
                            )
                        }
                    }
                case "whlsremove":{
                    return(
                        <div>
                            <WhlsRemove/>
                        </div>
                        )
                    }
                case 1:{
                    if (this.props.showlogin==='condition'||this.props.showlogin==='override'){
                        return(
                            <div>
                            <Scanner/>
                            <Modal/>
                            </div>
                            )
                        }
                    else{
                        return(  
                            <Scanner/>
                            )
                        }
                    }
                case 2:{
                    return(
                        <div>
                            <Download/>
                        </div>
                        )
                    }
                case 3:{
                    return(
                        <div>
                            <UploadMissingDigits/>    
                        </div>
                        )
                    }
                default:{
                    if (this.props.showlogin==='condition'||this.props.showlogin==='override'){
                        return(
                            <div>
                            <Scanner/>
                            <Modal/>
                            </div>
                            )
                        }
                    else{
                        return(  
                            <Scanner/>
                            )
                        }
                    }
                }          
        }
    }
}

const mapStateToProps=(state)=>({
    LOGGED:state.LOGGED,
    showlogin:state.showlogin,
    MenuOption:state.MenuOption,
    Modal:state.Modal
})
export default connect(mapStateToProps)(Bottom);