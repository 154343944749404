//works up to line 85, we can sent request to function, now we need to connect function to db
//dependencies
import React,{Component} from 'react';
import {connect} from 'react-redux';
import {Formik} from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components'

const ButtonStyled=styled.button`
  text-align: center;
  color:black;
  background:  #c6a9c; 
  padding-left:10px;
  padding-right:10px;
  padding-top:5px;
  padding-bottom:5px;
  border: 2px solid #58402e;
  border-radius: 5px;
  font-size:13px;
`
const ButtonStyledSmallUnder=styled.button`
  text-align: center;
  font-size:13px;
  color:rgb(212, 58, 58);
`
const InputFeedbackStyled=styled.div`
  font-size:12px;
  color:#343339;
`
const CenterFStyled=styled.div`
  text-align: center;
`
class LoginC extends Component {
  constructor(props) {
    super(props)
    this.state={
      showlogin:1,
      rezultat:'',
      communicate:'',
      userid:this.props.useridConfirm,
      acc_type:'',
      acc_ID:null
      };
    };
  //updating state
  componentDidUpdate(prevState) {
    if (this.state.communicate !== prevState.communicate) {
      //if we have had welcome back communicate
      if (this.state.communicate==="Welcome!") {
        //we reload login page (to display communicate)
        this.props.dispatch({type:"SET_SHOW_LOGIN_STATUS",payload:1});
        //and then after 2 seconds
        setTimeout(() => {
          //we close modal
          this.props.dispatch({type:"SET_SHOW_LOGIN_STATUS",payload:0});
        }, 2000);  
      }
      }
    }
  render() {
    return(
      <Formik
        initialValues={{ userid: this.state.userid||"", password: "",isForgotButton:false }}
        onSubmit={(values, { setSubmitting,resetForm }) => {
          //we reset our errors before submission
          this.setState({errors:{},communicate:'',show_forgot_password:''})
          //console.log(values.userid)
          //console.log(values.password)
          //we create payload for request
          var bagaz={
            userid:values.userid,
            password:values.password
            }
          //console.log(bagaz)
          //we do request, 
          console.log('cest parti!')
          var request=new Request('https://upcscanningapp.azurewebsites.net/api/loginv3', { 
            method:'POST',
            headers: new Headers({'Accept': 'application/json',
              'Content-Type':'application/json'}),
            body:JSON.stringify(bagaz)
            })
          //we make a request
          fetch(request)
            //we get response, in form of json
            .then(response=>response.json())
            .then(response=>{
                if(response){
                  if (response.answer){
                    if (response.answer===1){      
                      console.log('login correct')
                      //console.log(response)
                      //if we have admin
                      if(response.acc_type==='admin'){
                        console.log('we have admin')
                        //we set acc_type
                        this.props.dispatch({type:"acc_type",payload:'admin'});
                        //we set the user id
                        this.props.dispatch({type:"acc_ID",payload:values.userid});
                        this.setState({communicate:"Thank you !"});
                        //we are logged in
                        this.props.dispatch({type:"LOGGED",payload:1});
                        //we change screen
                        this.props.dispatch({type:"SET_SHOW_LOGIN_STATUS",payload:0});
                        }
                      //if we have regular employee
                      else{
                        //we set acc_type
                        this.props.dispatch({type:"acc_type",payload:'employee'});
                        //we set the user id
                        this.props.dispatch({type:"acc_ID",payload:values.userid});
                        //we display message
                        this.setState({communicate:"Thank you !"});
                        //we redirect us to second screen with station id etc ('ie we are not fully logged yet)
                        this.props.dispatch({type:"LOGGED",payload:0});
                        //we are logged in
                        this.props.dispatch({type:"SET_SHOW_LOGIN_STATUS",payload:2});
                        }
                      }
                    }
                    else{
                      //we are not logged in
                      console.log('bad_login_or_pass')
                      //we display message
                      this.setState({communicate:"Wrong ID/password"})
                      //we update userid in state (for reset password purposes)
                      //this.props.dispatch({type:"UPLOAD_USERID_FOR_CONFIRMATION",payload:values.userid}) 
                      //and we reset password field
                      resetForm({});
                      }  
                  }
                  //something bad with connection, we are not logged in
                  else{
                    console.log('something_with_connection')
                  }
              })
            //we get data, and pass rezultat to the state of the component!
          }}
        // ********Using Yup for validation********/
        validationSchema={Yup.object().shape({
          userid: Yup.number()
            .positive("number must be positive")
            .required("ID nr required"),
          password: Yup.string()
            .required("No password provided.")
            .max(25, "Password must be max. 25  chars")
          })}

      >
      {props => {
        const {
          values,
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          } = props;
      return (
        <form onSubmit={handleSubmit}>
          <label htmlFor="userid">ID:</label>
          <br></br>
          <input
            name="userid"
            type="text"
            placeholder="Enter your ID nr"
            value={values.userid}
            onChange={handleChange}
            onBlur={handleBlur}
            className={errors.userid && touched.userid && "error"}
            />
            <br></br>
            <div>
          {errors.userid && touched.userid && (
            <InputFeedbackStyled>{errors.userid}</InputFeedbackStyled>
            )}
            </div>
          <br></br>
          <label htmlFor="password">Password:</label>
          <br></br>
          <input
            name="password"
            type="password"
            placeholder="Enter your password"
            value={values.password}
            onChange={handleChange}
            onBlur={handleBlur}
            className={errors.password && touched.password && "error"}
            />
          <div>
            {errors.password && touched.password && (
              <InputFeedbackStyled>{errors.password}</InputFeedbackStyled>
              )}
          </div>
          {/*here we set communicate iv to display feedback messages */}
          <div>{this.state.communicate}</div>
          {/*here we activate new div ig show_forgot_password gets a value */}
          {this.state.show_forgot_password && (
            <CenterFStyled>
              <ButtonStyledSmallUnder type="button" onClick={() => {
                /* in onClick we set new function (cannot pass it to the formik, don't know why) */
                this.props.dispatch({type:"SET_SHOW_LOGIN_STATUS",payload:4}); 
                
                 }}>{this.state.show_forgot_password}</ButtonStyledSmallUnder>            
            </CenterFStyled>
          )}
          <br></br>
          <CenterFStyled>
            <ButtonStyled type="submit" disabled={isSubmitting}>Login</ButtonStyled>
          </CenterFStyled>
        </form>
        );
      }}
      </Formik>
    )
    }
  }

const mapStateToProps = state => ({
  showlogin: state.showlogin,
  useridConfirm:state.useridConfirm
  //memberlvl:state.memberlvl
  });
export default connect(mapStateToProps)(LoginC);