import React, {Component} from 'react';
import {connect} from 'react-redux';
//we go with bootstrap
import '../modal.css'

    
class Override extends Component {
    constructor(props){
        super(props)
        this.state={isOpen:true,
        condition:''};
        //we pass props to the function handlekeyup (by using this.handlekeyup) and from (by using .bind(this) )
        this.handleKeyUp = this.handleKeyUp.bind(this);
        this.setCondition=this.setCondition.bind(this);

    
    };
    setCondition(e){

        //here we will need to process the m_sku calls
        //console.log('override',e.target.value)
        // set each selected variable with its analogous variable from the theme
        document.documentElement.style.setProperty('--selected-condition-color', `var(--${e.target.value}${'-color'})`);
        let product_name=e.target.value
        let previous_product_name=this.props.product_name
        this.props.dispatch({type:"previous_product_name",payload:previous_product_name})
        //if we have moe tahn on row to process
        this.props.dispatch({type:"product_name",payload:product_name})
        this.props.dispatch({type:"override_product_name",payload:product_name})
        this.props.dispatch({type:"override",payload:true})
        //console.log('the end')
        this.props.dispatch({type:"SET_SHOW_LOGIN_STATUS", payload:0})                                  
        }
    //fucntion which deals with closing the modal
    showLogin=()=> {
        this.props.dispatch({type:"SET_SHOW_LOGIN_STATUS", payload:0})
        }
    //while creatiung component
    componentDidMount() {
        //console.log("has mounted");
        //adding keyboard listener (keyup-press a key, handlekeyup-function which is triggered, false-?)
        window.addEventListener('keyup', this.handleKeyUp, false);
        }
    //while unmounting component
    componentWillUnmount(){
        //we remove event listener (for keyboard)
        window.removeEventListener("keyup", this.handleKeyUp, false);
        }
    //when re-enabling component
    componentDidUpdate(){
        if(!this.state.isOpen){
            //we add listener one more time
            window.addEventListener('keyup', this.handleKeyUp, false);
            }
        }

    handleKeyUp(e) {
        //get the function inside the function(?)
        //asking for a key
        const Logins=this.props
        const keys = {
        //if 27 (esc)
          27: () => {
            //prevents submit
            e.preventDefault();
            //triggers function which change showlogin(ie closes modal)
            Logins.dispatch({type:"SET_SHOW_LOGIN_STATUS", payload:0})
            //removes listener
            window.removeEventListener("keyup", this.handleKeyUp, false);
            },
          13: ()=>{
            //prevents submit
            e.preventDefault();
            //triggers function which change showlogin(ie closes modal)
//            Logins.dispatch({type:"SET_SHOW_LOGIN_STATUS", payload:0})
            //removes listener
            window.removeEventListener("keyup", this.handleKeyUp, false);
            }
        }
        //if the key has ben pressed, run function to determine if it was esc
        if (keys[e.keyCode]) {
            keys[e.keyCode]();
          }
    }
    render(){
        return(
            <div className='whls_add_main_container'>
                <br></br>
                <br></br>
                <div className='modal_override_title'>OVERRIDE PRODUCT</div>
                <div className='whls_add_options_box'>
                <button className='modal_override_options_button' style={{"backgroundColor":" lightblue"}} onClick={this.setCondition} value='ECOM'>ECOM</button>
                    <button className='modal_override_options_button' style={{"backgroundColor":" #ffe70d"}} onClick={this.setCondition} value='REFURB'>REFURB</button>
                    <button className='modal_override_options_button' style={{"backgroundColor":" rgb(219, 26, 26)"}} onClick={this.setCondition} value='SLVG-PLT'>SLVG-PLT</button>
                    <button className='modal_override_options_button' style={{"backgroundColor":" rgb(106, 212, 0)"}} onClick={this.setCondition} value='TGT-AP'>TGT-AP</button>
                    <button className='modal_override_options_button' style={{"backgroundColor":" rgb(106, 212, 0)"}} onClick={this.setCondition} value='TGT-SH'>TGT-SH</button>
                    <button className='modal_override_options_button' style={{"backgroundColor":" rgb(106, 212, 0)"}} onClick={this.setCondition} value='TGT-UG'>TGT-UG</button>
                    <button className='modal_override_options_button' style={{"backgroundColor":" #ff890d"}} onClick={this.setCondition} value='TGT-HC'>TGT-HC</button>
                    <button className='modal_override_options_button' style={{"backgroundColor":" #ff890d"}} onClick={this.setCondition} value='TGT-GM'>TGT-GM</button>
                    <div className='modal_override_options_button'  > </div>
                    <button className='modal_override_options_button' style={{"backgroundColor":" rgb(226, 181, 181)"}} onClick={this.setCondition} value='TGT-SW'>TGT-SW</button>
                    <button className='modal_override_options_button' style={{"backgroundColor":" rgb(226, 181, 181)"}} onClick={this.setCondition} value='TGT-X'>TGT-X</button>
                    <button className='modal_override_options_button' style={{"backgroundColor":"white"}} onClick={this.showLogin}>BACK</button>
                </div>
            </div>
            )                            
        };
    };
    const mapStateToProps=(state)=>({
        product_name:state.product_name,
        previous_product_name:state.previous_product_name,
    })
    export default connect(mapStateToProps)(Override);