import {askTarget} from './askTarget'
import {GetWmtToken} from './askWmt'

const bluebird=require('bluebird')
export function askcorrelatingquery(bagaz){
    return new Promise(async function(resolve,reject){
        var request=new Request('https://upcscanningapp.azurewebsites.net/api/correlatingquery', {            
            method:'POST',
            headers: new Headers({'Accept': 'application/json',
            'Content-Type':'application/json'}),
            body:JSON.stringify(bagaz)
            })
        //we make a request
        fetch(request)
        //we get response, in form of json
            .then(response=>response.json())
            .then(response=>{
                resolve(response)
            })
        })
    }

export function askupcdb(bagaz){
    return new Promise(async function(resolve,reject){
        var request=new Request('https://upcscanningapp.azurewebsites.net/api/upcquery', {            
            method:'POST',
            headers: new Headers({'Accept': 'application/json',
            'Content-Type':'application/json'}),
            body:JSON.stringify(bagaz)
            })
        //we make a request
        fetch(request)
        //we get response, in form of json
            .then(response=>response.json())
            .then(response=>{
                //console.log('wmt returns')
                resolve(response)
            })
        })
    }
export function askwmtapi(bagaz){
    return new Promise(async function(resolve,reject){
        //console.log('inside 2')
        //here azure function which fetches data from WMT API
        //we create a request
        var request=new Request('https://upcscanningapp.azurewebsites.net/api/wmtquery', {
            method:'POST',
            headers: new Headers({'Accept': 'application/json',
            'Content-Type':'application/json'}),
            body:JSON.stringify(bagaz)
            })
        //we make a request
        fetch(request)
        //we get response, in form of json
            .then((response)=>{
                if (response.status===404){
                    const ress={answer:"No such UPC in Wallmart API"}
                    resolve(ress)
                    return
                    }
                else{
                    //console.log('response',response)
                    let response2=response.json()
                    return response2
                    }
                })
            .then(response2=>{
                resolve(response2)
                return
                })
        })
    }

export function askwmtapinew(bagaz){
    return new Promise(async function(resolve,reject){
        let token=await GetWmtToken()
        bagaz.token=token.data.access_token
        console.log('bagaz token',bagaz.token)
        //here azure function which fetches data from WMT API
        //we create a request
        var request=new Request('https://upcscanningapp.azurewebsites.net/api/wmtquerynew', {
            method:'POST',
            headers: new Headers({'Accept': 'application/json',
            'Content-Type':'application/json'}),
            body:JSON.stringify(bagaz)
            })
        //we make a request
        fetch(request)
        //we get response, in form of json
            .then((response)=>{
                if (response.status===404){
                    const ress={answer:"No such UPC in Wallmart API"}
                    resolve(ress)
                    return
                    }
                else{
                    //console.log('response',response)
                    let response2=response.json()
                    return response2
                    }
                })
            .then(response2=>{
                resolve(response2)
                return
                })
        })
    }

function myFunc(resolve) {
    var rte='hi '+ Date.now()
    console.log(rte)
    resolve(rte)
    }

function timeouter(bagaz){
    return new Promise(async function(resolve,reject){
        setTimeout(myFunc, 2000, resolve);
        })
    }

export function askUPCBCapi(bagaz){
    return new Promise(async function(resolve,reject){
        console.log('inside4',bagaz)
        var request=new Request('https://upcscanningapp.azurewebsites.net/api/UPCBarcodeApiv3 ',{
            method:'POST',
            headers: new Headers({'Accept': 'application/json',
            'Content-Type':'application/json'}),
            body:JSON.stringify(bagaz)
            })
        //we make a request
        fetch(request)
        //we get response, in form of json
            .then(response=>response.json())
            .then(async(response)=>{
                console.log('response',response)
                var bagazans=0
                var bagaz4={answer:bagazans}
                if(response.answer==='ok'){
                    bagazans=1;
                    bagaz4={answer:bagazans,
                        fullans:response.data}
                    }
                resolve(bagaz4)
            })
    })
}

export function askUPCBCdb(bagaz){
    return new Promise(async function(resolve,reject){
        //console.log('inside ask upcapidb',bagaz)
        var request=new Request('https://upcscanningapp.azurewebsites.net/api/UPCBarcodeDB',{
            method:'POST',
            headers: new Headers({'Accept': 'application/json',
            'Content-Type':'application/json'}),
            body:JSON.stringify(bagaz)
            })
        //we make a request
        fetch(request)
        //we get response, in form of json
            .then(response=>
                {
                const contentType = response.headers.get("Content-Type");
                if (contentType && contentType.indexOf("application/json") !== -1) {
                    return response.json()
                    }
                else{
                    console.log('this is not json',response, 'for item',bagaz)
                    return response.text()
                    }
                })
            .then(responser=>{
                console.log('upcbcdb returns')
                resolve(responser)
                })
        })
    }

export function uploadCSVFile(bagaz){
    return new Promise(async function(resolve,reject){
//https://azure.github.io/azure-storage-node/BlobService.html
        //console.log('inside ask upcapidb',bagaz)
        //we create a formdata (for thansferring files)
        var formData=new FormData();
        formData.append("uploadCsv",bagaz.file);
        var request=new Request('https://upcscanningapp.azurewebsites.net/api/csvuploader',{
            method:'POST',
            headers: new Headers({"Content-type":"text/csv"}),
            body:formData
            })
         //   headers: new Headers({"Content-type":"multipart/form-data"}),
        //we make a request
        fetch(request)
        //we get response, in form of json
            .then(response=>response.json())
            .then(responser=>{
                resolve(responser)
                })
        })
    }
    
async function processSingleItemUpcs(singleitem){
    return new Promise(async function(resolve,reject){
        //console.log('our singleitem before processing',singleitem)
        //if we do not have data
        if(!singleitem.data){
            console.log('no data for',singleitem)
            //we get it from upcApi (looping thorugh 0-9 stuff)
            //singleitem.barcode='04155444058'
            let upc_nr_array=[]
            //we create array of 12v13 digit upc nrs.
            for(let a=0;a<10;++a){
                //var new_nr=upc_nr.toString().split(`\\`)[0].concat(a)
                let new_nr=singleitem.barcode*10+a
                //var new_nr=new_str.concat(a)
                upc_nr_array.push(new_nr)
                }
            //console.log('loop from single item',upc_nr_array)
            async function processArray(upc_nr_array){
                //console.log('are we in?')
                for(let a=0;a<10;++a){
                    //console.log('a',a)
                    let bagaz={upcnr:upc_nr_array[a],
                               barcode:upc_nr_array[a],
                                l:1}
                    //make a call to upcapi to check the number
                    console.log('bagaz from single item',bagaz)
                    await timeouter(bagaz)
                    let item=await askUPCBCapi(bagaz)
                    await timeouter(bagaz)
                    console.log('item',item)
                    //if we get right answer
                    if(item.answer!==0){
                        //we get details from target and wmt as well
                        let targetAnswer=await askTarget(bagaz)
                        let wmtAnswer=await askwmtapinew(bagaz)
                        let load={upcnr:singleitem.barcode,
                            data:[item,targetAnswer,wmtAnswer]
                            }
                            if(bagaz.barcode.length>11){
                                load.ean=bagaz.barcode
                                }
                            else{
                                load.upc=bagaz.barcode
                                load.ean='0'+bagaz.barcode
                                }
                        //console.log('our answer from the upcapi',load)
                        return load
                        }
                    if(a===9){
                        let load={upcnr:singleitem.barcode}
                        //console.log('our answer from the upcapi',load)
                        return load
                        }
                    }
                }
            processArray(upc_nr_array)
            .then(upc_answers=>{
                console.log('resolving single items',upc_answers)
                resolve(upc_answers)
                })
            }
        else{
            //console.log('we have data for',singleitem)
            //if we have it, we go with fetching target and wmt data
            async function getrest(singleitem){
                let bagaz={l:1}
                if (singleitem.data[0]!=null){
                    if(singleitem.data[0].length<12){
                        singleitem.data[0]='0'+singleitem.data[0]
                        }
                    bagaz.barcode=singleitem.data[0]
                    bagaz.upcnr=singleitem.data[0]
                    }
                else{
                    if(singleitem.data[1]!=null){
                        if(singleitem.data[1].length<13){
                            singleitem.data[1]='0'+singleitem.data[1]
                            }
                        bagaz.barcode=singleitem.data[1]
                        bagaz.upcnr=singleitem.data[1]
                        }
                    else{
                        bagaz.barcode=singleitem.barcode
                        bagaz.upcnr=singleitem.barcode
                        //console.log('stange stuff',singleitem)
                        }
                    }
                //console.log('bagaz',bagaz)
                let item=await askUPCBCdb(bagaz)
                let targetAnswer=await askTarget(bagaz)
                let wmtAnswer=await askupcdb(bagaz)
                let load={upcnr:singleitem.barcode,
                    data:[item,targetAnswer,wmtAnswer],
                    ean:singleitem.data[1],
                    upc:singleitem.data[0]}
                //console.log('our answer from the upcapi',load)
                return load
                }
            getrest(singleitem)
            .then(upc_answers=>{
                resolve(upc_answers)
                })
            }
        })
    }
    
async function processSingleBatchUpc(singlebatch){
    return new Promise(async function(resolve,reject){
        //console.log('inside single batch',singlebatch)
        async function processSingleItems(singlebatch){
            //console.log('before single items',arrayA)
            //const processB=await Promise
            //        .all(arrayA.map(async Aitem=>await getArrayB(Aitem)))
            const updatedsingleitem=await bluebird.Promise.mapSeries(singlebatch,async Aitem=>await processSingleItemUpcs(Aitem))
            return updatedsingleitem
            }
        processSingleItems(singlebatch)
            .then(resultsSingleItems=>{
                resolve(resultsSingleItems)
                })
        })
    }

export function getMissingUpcsDetails(input){
    return new Promise(async function(resolve,reject){
        let arrayA=input
        async function processBatchaTime(arrayA){
            console.log('before single items',arrayA)
            //const updatedCorrel=await mapSeries(arrayA,processSingleBatch)
            const updatedCorrel=await bluebird.Promise.map(arrayA,processSingleBatchUpc,{concurrency: 1})
                    .then(results=>{return results})
            return updatedCorrel
            }
        processBatchaTime(arrayA)
        .then(results=>{
            //console.log('our changed',results)
            resolve(results)
            })
        })
    }

