
//we'll make writeScanLogsv4
export function writeScanLogs(bagaz){
    return new Promise(async function(resolve,reject){
        //console.log('writescan log bagaz',bagaz)
        resolve(bagaz)
        var request=new Request('https://upcscanningapp.azurewebsites.net/api/writeScanLogsv4', {            
            method:'POST',
            headers: new Headers({'Accept': 'application/json',
            'Content-Type':'application/json'}),
            body:JSON.stringify(bagaz)
            })
        //we make a request
        fetch(request)
        //we get response, in form of json
            .then(response=>{
                if(response.status===209){
                    console.log(response.body.message)
                    }
                resolve(response.status)
            })
        })
    }
/*
export function askTarget(bagaz){
    return new Promise(async function(resolve,reject){
        //  'https://api.target.com/items/v4/graphql'
        bagaz='723902495490';
        const Get_Target_item = gql`
        query item($barcode:String!){
            items(type:BARCODE, value:$barcode){
              tcin
              description {
                title
                short
              }
              vendors{
                barcodes{
                  number
                  type
                }
              }
              brands {
                name
                isTargetOwned
              }
              digitalAssets{
                images {
                  baseUrl
                  primaryImage
                }
              }
              variations{
                themes {
                  name
                  value
                  swatchImage
                }
              }
              operational{
                weightUnitOfMeasure
                packageDimensions{
                  measurements{
                    weight{
                         code
                      value
                    }
                    dimensions {
                      depth
                      height
                      units
                      width
                    }
                  }
                }
              }
            }
          }          
        `
        try{
            let data =await useQuery(Get_Target_item, {
                variables: { bagaz },
            });
            console.log('data',data)
            resolve(data)
            }
        catch(e){console.log('errors graphql',e)}
        })
    }
    */