/////****WARNING!!! THE CONVERTER IN FILE SYSTEM IS DOWNLOADER, THE DECODER IN FILE SYSTEM IS CONVERTER*****/////


//dependencies
import React, {Component} from 'react';
import styled from 'styled-components';
import download from 'downloadjs'

//our modules
import {CsvToObject,ObjectToCsvDownload} from './processCsv'
//import {getCorrel} from './getCorrel'
import {getBarcodeDetails,getBarcodeDetailsApis} from './getBarcodeDetails'


const Titler=styled.div`
    padding-left:1.5%;
    font-size:20px;
    color:brown;
    `
const DecoderMain=styled.div`
    top:100px;
    display:grid;
    rows:2;
    width:60%;
    height:100px;
    `
const FirstColumn=styled.div`
    align-self:center;
    `
const FirstRow=styled.div`
    grid-row:1;
    height:50px;
    width:100%;
    display:flex;
    justify-content:flex-start;
    `
const SecondRow=styled.div`
    grid-row:2;
    height:50px;
    width:100%;
    display:flex;
    justify-content:flex-start;
    `
const SecondColumn=styled.div`
    margin-left:10px;
    align-self:center;
    `



class Converter extends Component {
    constructor(){
        super()
        this.state={
            upload_file:null,
            file_name:null,
            file_to_upload:null,
            progress:null,
            file_to_download:null,
            download_file:null,
            download_file_name:null
        }
        this.uploadFile=this.uploadFile.bind(this)
        this.submit=this.submit.bind(this)        
    }
    //function which accepts a file
    uploadFile=(event)=>{
        //console.log('uploading',event.target.files[0])
        //and sets date with:
        // file name
        if (event.target.files[0]){
            this.setState({file_name:event.target.files[0].name})
            //that we have a file 
            this.setState({upload_file:'full'})
            //and with file itself
            this.setState({file_to_upload:event.target.files[0]})
            }
        }
    //function which send a file to azure bucket
    submit=()=>{
        //if we have a file to upload
        //console.log(this.state.upload_file)
        if(this.state.upload_file==='full'){
            //updating progress
            this.setState({progress :'start processing file'})
            //transforming csv file into JS object
            async function getCsvObject(fileFromState){
                //via external module
                let csvObject=await CsvToObject(fileFromState)
                return csvObject
                }
            //we make a queries to correlated
            getCsvObject(this.state.file_to_upload)
                .then(csvObject=>{
                //console.log('our object',csvObject)
                //updating progress
                this.setState({progress :'checking local db tables'})
                console.log('get local dbs')
                //checking our correlate table for matches
                async function getDBs(csvObject){
                    //via external module
                    //let CorrelObject=await getCorrel(csvObject)
                    let CorrelObject=await getBarcodeDetails(csvObject)
                    return CorrelObject
                    }
                //call for correlating table
                getDBs(csvObject)
                    .then(correlObject=>{
                        //console.log('correl object',correlObject)
                        //updating progress
                        this.setState({progress :'start fetching missing barcodes from APIS'})
                        console.log('get apis')
                        async function getApis(correlObject){
                            //via external module
                            let AfterApisObject=await getBarcodeDetailsApis(correlObject)
                            return AfterApisObject
                            }
                        //call for correlating table
                        getApis(correlObject)
                            .then(AfterApisObject=>{
                                //updating progress
                                this.setState({progress :'flattening object'})
                                //now we need to flatten the object
                                //checking original size
                                var csvlength=csvObject.length
                                var finalFlatObject=[]
                                //if it's nested(ie over 50 positions)
                                if(csvlength>50){
                                    var upc12L=AfterApisObject.length
                                    for(let a=0;a<upc12L;++a){
                                        let upc12BL=(AfterApisObject[a]).length
                                        for (let b=0;b<upc12BL;++b){
                                            finalFlatObject.push(AfterApisObject[a][b])
                                            }
                                        }
                                    }
                                    //if its flat
                                    else{
                                        let upc12BL=(AfterApisObject[0]).length
                                        for (let b=0;b<upc12BL;++b){
                                            finalFlatObject.push(AfterApisObject[0][b])
                                            }
                                        }
                                    //updating progress
                                    this.setState({progress :'preparing csv file'})
                                    async function getObjectCsv(finalFlatObject){
                                        //via external module
                                        //let csvFilet=await ObjectToCsv(finalFlatObject)
                                        let csvFilet=await ObjectToCsvDownload(finalFlatObject)
                                        return csvFilet
                                        }
                                    //we make a queries to correlated
                                    getObjectCsv(finalFlatObject)
                                        .then(CvsFilet=>{
                                        //updating progress
                                        this.setState({progress :'file ready to upload'})
                                        //changing state to notify that file is ready
                                        this.setState({download_file :'full'})
                                        //creating name
                                        var csvfilename='bulk_upload_'+ Date.now() + '.csv'
                                        //updating progress
                                        this.setState({download_file_name :csvfilename})
                                        //updating progress
                                        this.setState({file_to_download :CvsFilet})
                                        download(CvsFilet, csvfilename, "text/plain");
                                        
                                        })
                                })
                        })

                })
            }
            else{
                //updating progress
                this.setState({progress :'no file selected'})
                }
            }
    render(){
        return(
            <div>
                <Titler>
                    Bulk Upload
                </Titler>
                <DecoderMain>
                    <FirstRow>
                        <FirstColumn>
                            <input className="fileInput" type="file" id="csv_input" name="csv_input" accept=".csv" onChange={this.uploadFile}/>
                            <label className="fileInputLabel" htmlFor="csv_input">Choose a file</label>
                        </FirstColumn>
                        <SecondColumn>
                            {this.state.file_name}
                        </SecondColumn>
                    </FirstRow>
                    <SecondRow>
                        <FirstColumn>
                            <button className="fileInput" id="csv_input_button" onClick={this.submit}>Upload</button>    
                            <label className="fileInputLabel" htmlFor="csv_input_button">Upload a file</label>
                        </FirstColumn>
                        <SecondColumn>
                            {this.state.progress}
                        </SecondColumn>
                    </SecondRow>
                </DecoderMain>
            </div>
        )
    }
}
export default Converter;