export function determineTypeOfBarcode(Barcode){
    return new Promise(async function(resolve,reject){
        let Barcode_l=Barcode.length
        //console.log('Barcode',Barcode,'length',Barcode_l)
        let finalBarcode=null
        let type='UPC'
        const gtin='GTIN'
        switch (Barcode_l){
            case 11:{
                finalBarcode='0'+Barcode
                break
                }
            case 12:{
                finalBarcode=Barcode
                break
                }
            case 13:{
                finalBarcode='0'+Barcode
                type=gtin
                break
                }
            case 14:{
                finalBarcode=Barcode
                type=gtin
                break
                }
            default:{
                finalBarcode=Barcode
                break
                }
            }
        resolve({barcode:finalBarcode,type:type})
        return
        })
    }

export function FillEmptyWithUnknownSerialNumbers(input){
    return new Promise(async function(resolve,reject){
        for(let a=0;a<input.length;++a){
            if(!input[a].barcode){
                input.barcode='unknown'
                }
            if(!input[a].category){
                input[a].category='unknown'
                }
            if(!input[a].msrp){
                input[a].msrp='unknown'
                }
            if(!input[a].product_name){
                input[a].product_name='unknown'
                }
            if(!input[a].serial_numbers){
                input[a].serial_numbers='unknown'
                }
            if(!input[a].title){
                input[a].title='unknown'
                }
            }
        resolve(input)
        }) 
    }