//we'll make writeScanLogsv4
export function getSaveErr(bagaz){
    return new Promise(async function(resolve,reject){
        console.log('saving errors inside')
        resolve(bagaz)
        var request=new Request('https://upcscanningapp.azurewebsites.net/api/saveGoogErr', {            
            method:'POST',
            headers: new Headers({'Accept': 'application/json',
            'Content-Type':'application/json'}),
            body:JSON.stringify(bagaz)
            })
        //we make a request
        fetch(request)
        //we get response, in form of json
            .then(response=>{
                if(response.status===209){
                    console.log(response.body.message)
                    }
                resolve(response.status)
            })
        })
    }