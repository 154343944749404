import React, {Component} from 'react';
import {connect} from 'react-redux';
import ReactToPrint from "react-to-print";
//components
import {GetWhlsSerialNumber,SaveWhlsSerialNumber,SaveWhlsLogs} from '../../apicalls/GetWhlsSerialNumber'
import { AddQuantityToSkuVault } from '../../apicalls/skuVault';
import {createbarcode} from '../utils/createbarcode'
import PrintComponent from '../Bottom/PrintComponent'

//we go with bootstrap
import '../modal.css'

    
class PrintSku extends Component {
    constructor(props){
        super(props)
        this.state={isOpen:true,
            sku:'',
            sku_full:'TGT-APPAREL',
            printing:false
            };
        //we pass props to the function handlekeyup (by using this.handlekeyup) and from (by using .bind(this) )
        this.handleKeyUp = this.handleKeyUp.bind(this);
        this.print=this.print.bind(this)
        this.ReactprintRef = React.createRef();
        this.PrintcomponentRef = React.createRef();
        this.PrintbuttonRef=React.createRef()
    };
    TriggerPrinting=() => {
        this.ReactprintRef.current.handleClick();
        this.PrintbuttonRef.current.removeAttribute("disabled")
        //this.setState({sku:'',sku_full:'',communicate:'done'})
        };
    print=async()=>{
        this.setState({sku:this.props.sku_to_print})
        console.log('clicked print. value',this.state.communicate)
        this.PrintbuttonRef.current.setAttribute("disabled", "disabled")
        if(this.props.reprint==='no'){
            let answerserialnumber=await GetWhlsSerialNumber()  
            if(answerserialnumber.answer==='ok'){
                console.log('response from serial numbers',answerserialnumber.data[0].serialnumber)
                let currentserialnumber=parseInt(answerserialnumber.data[0].serialnumber)
                let newserialnumber=++currentserialnumber
                this.props.dispatch({type:"serialnumber",payload:newserialnumber})
                console.log('new serial number',newserialnumber)
                if(newserialnumber&&this.props.sku_to_print){
                    //building bagaz for sku_vault_add
                    let bagaz_vault={sku:this.props.sku_to_print,
                        SerialNumbers:newserialnumber,
                        l:1
                        }
                    //console.log('bagaz for sku vault',bagaz_vault)
                        //we save logs 
                        let saveserial=await SaveWhlsSerialNumber(newserialnumber)
                        //add quantity
                        let skuvault_answer=await AddQuantityToSkuVault(bagaz_vault)
                        //let skuvault_answer={answer:'ok'}
                        //console.log('answer from skuvault',skuvault_answer)
                        //if success
                        if(skuvault_answer.answer==='ok'){
                            //console.log('saving serial',saveserial)
                            let currenttime=Date.now()
                            let whls_logs_bagaz={serialnumber:newserialnumber,
                                product_name:this.props.sku_to_print,
                                warehouse_id:4,
                                location_code:'WHOLESALE',
                                user_id:this.props.acc_ID,
                                station_id:this.props.stationId||null,
                                po_hash:this.props.poH||null,
                                time_added:currenttime
                                }
                            console.log('whls logs bagaz',whls_logs_bagaz)
                            let savelogs=await SaveWhlsLogs(whls_logs_bagaz)
                            }
                        else{
                            this.setState({communicate:'problems with adding quantity to sku vault. please try again'})
                            return
                            }
                        //we print labels
                        let answerprint=await createbarcode(newserialnumber)
                        this.TriggerPrinting()
                        //and we go back to whls
                        this.showLogin()
                        return
                    }
                else{
                    this.setState({communicate:'please select product name'})
                    return
                    }
                }
            else{
                console.log('serialnumbers errors')
                this.setState({communicate:'errors while fetching serial number. please try again'})
                return
                }
            }
        else{
            let answerprint=await createbarcode(this.props.serialnumber)
            this.TriggerPrinting()
            //and we go back to whls
            this.showLogin()
            return
            }
        }
    //fucntion which deals with closing the modal
    showLogin=()=> {
        this.props.dispatch({type:"SET_SHOW_LOGIN_STATUS", payload:0})
        }
    //while creatiung component
    componentDidMount() {
        //console.log("has mounted");
        //adding keyboard listener (keyup-press a key, handlekeyup-function which is triggered, false-?)
        window.addEventListener('keyup', this.handleKeyUp, false);
        }
    //while unmounting component
    componentWillUnmount(){
        //we remove event listener (for keyboard)
        window.removeEventListener("keyup", this.handleKeyUp, false);
        }
    //when re-enabling component
    componentDidUpdate(){
        if(!this.state.isOpen){
            //we add listener one more time
            window.addEventListener('keyup', this.handleKeyUp, false);
            }
        }

    handleKeyUp(e) {
        //get the function inside the function(?)
        //asking for a key
        const Logins=this.props
        const keys = {
        //if 27 (esc)
          27: () => {
            //prevents submit
            e.preventDefault();
            //triggers function which change showlogin(ie closes modal)
            Logins.dispatch({type:"SET_SHOW_LOGIN_STATUS", payload:0})
            //removes listener
            window.removeEventListener("keyup", this.handleKeyUp, false);
            },
          13: ()=>{
            //prevents submit
            e.preventDefault();
            //triggers function which change showlogin(ie closes modal)
//            Logins.dispatch({type:"SET_SHOW_LOGIN_STATUS", payload:0})
            //removes listener
            window.removeEventListener("keyup", this.handleKeyUp, false);
            }
        }
        //if the key has ben pressed, run function to determine if it was esc
        if (keys[e.keyCode]) {
            keys[e.keyCode]();
          }
    }
    render(){
        return(
            <div className='whls_add_main_container'>
                <br></br>
                <br></br>
                <div className='modal_override_title'>Print Barcode</div>
                <button className='modal_override_options_button' style={{"backgroundColor":" #ffe70d"}} >{this.props.sku_to_print}</button>
                <button className='modal_override_options_big_button' style={{"backgroundColor":" rgb(106, 212, 0)"}} onClick={this.print} ref={this.PrintbuttonRef}>PRINT</button>
                <button className='modal_override_options_button' style={{"backgroundColor":"white"}} onClick={this.showLogin}>BACK</button>
                <ReactToPrint 
                    content={() => this.PrintcomponentRef.current}
                    trigger={() => <React.Fragment/>}
                    ref={this.ReactprintRef}
                    >
                </ReactToPrint >
                <div style={{ display: "none" }}>  
                    <PrintComponent ref={this.PrintcomponentRef} {...this.state}/>
                </div>
            </div>
            
            )                            
        };
    };
    const mapStateToProps=(state)=>({
        acc_ID:state.acc_ID,
        sku_to_print:state.sku_to_print,
        stationId:state.stationId,
        poH:state.poH,
        serialnumber:state.serialnumber,
        reprint:state.reprint
    })
    export default connect(mapStateToProps)(PrintSku);