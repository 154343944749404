export function getSave_m_sku(bagaz){
    return new Promise(async function(resolve,reject){
        bagaz.l=1;
        var request=new Request('https://upcscanningapp.azurewebsites.net/api/save_m_sku', {            
            method:'POST',
            headers: new Headers({'Accept': 'application/json',
            'Content-Type':'application/json'}),
            body:JSON.stringify(bagaz)
            })
        //we make a request
        fetch(request)
        //we get response, in form of json
            .then(response=>response.json())
            .then(response=>{
                //console.log('sku returns',response)
                resolve(response)
            })
        })
    }
export function getSkuDetails(input){
    return new Promise(async function(resolve,reject){
        let bagaz={m_sku:input,
                    l:1}
        var request=new Request('https://upcscanningapp.azurewebsites.net/api/m_sku_query', {            
            method:'POST',
            headers: new Headers({'Accept': 'application/json',
            'Content-Type':'application/json'}),
            body:JSON.stringify(bagaz)
            })
        //we make a request
        fetch(request)
        //we get response, in form of json
            .then(response=>response.json())
            .then(response=>{
                //console.log('sku returns',response)
                resolve(response)
            })
        })
    }
export function updateM_sku(input){
    return new Promise(async function(resolve,reject){
        let bagaz=input
        var request=new Request('https://upcscanningapp.azurewebsites.net/api/update_m_sku', {            
            method:'POST',
            headers: new Headers({'Accept': 'application/json',
            'Content-Type':'application/json'}),
            body:JSON.stringify(bagaz)
            })
        //we make a request
        fetch(request)
        //we get response, in form of json
            .then(response=>response.json())
            .then(response=>{
                //console.log('sku returns',response)
                resolve(response)
            })
        })
    }