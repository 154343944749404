//dependencies
import React,{Component} from 'react';
import {connect} from 'react-redux';
import {Formik} from 'formik';
import * as Yup from 'yup';
//internal modules
import {getSkuDetails} from '../../apicalls/skuCalls'
import {askupcdb,askwmtapinew,askUPCBCapi,askUPCBCdb} from '../../apicalls/askupc'
import {askTarget,askTargetDB} from '../../apicalls/askTarget'
import {getGoogTaxDetails} from '../../apicalls/googTaxCalls'
import {getSendItemsToShops,get_check_shops} from '../../apicalls/getSendItemsToShops'
import {get_save_shopify_pr_id} from '../../apicalls/shops_api_uploads'
import crp from'../crp'


class Sort extends Component {
    constructor(){
        super()
        this.state={
            product_name:'',
            communicate:''
            };
        this.sortInput=React.createRef()
        this.chooseOption=this.chooseOption.bind(this)
        this.clicked_back=this.clicked_back.bind(this)
        this.continueSort=this.continueSort.bind(this)
    }
    continueSort(data,local){
        return new Promise(async function(resolve,reject){
            //if we do not go to shopify
            if(data.product_name!=='ECOM'){
                resolve(null)
                return
                }
            //if it is ecom, we go to shopify
            else{
                function upload_shopify(bagazShops){
                    return new Promise(async function(resolve,reject){
                        //async function
                        console.log('we proceed to shopify',bagazShops)
                        async function send_items_to_shops(bagazShops){
                            let send_resp=await getSendItemsToShops(bagazShops)
                            return send_resp
                            }
                        send_items_to_shops(bagazShops)
                        .then(send_resp=>{
                            console.log('response from shopify',send_resp)
                            //we save answer with product_id and shopify=true
                            console.log(send_resp.data.product.id)
                            let product_id=send_resp.data.product.id
                            let variant_id=send_resp.data.product.variants[0].id
                            let inventory_item_id=send_resp.data.product.variants[0].inventory_item_id
                            let bagazsave={m_sku:m_sku,
                                            product_id:product_id,
                                            variant_id:variant_id,
                                            inventory_item_id:inventory_item_id}
                            async function save_shopify_product_id(bagazsave){
                                let answer_save_shopify_pr_id=await get_save_shopify_pr_id(bagazsave)
                                return answer_save_shopify_pr_id
                                }
                            save_shopify_product_id(bagazsave)
                            .then(answer_save_shopify_pr_id=>{
                                console.log('saved product_id',answer_save_shopify_pr_id)
                                resolve(null)
                                return
                                })
                            })
                        })
                    }
                console.log('inside checkshops',local,'product name',data)
                let m_sku=data.m_sku
                //we check shops
                async function check_shops(m_sku){
                    console.log('going to check shops')
                    let answer_check_shops=await get_check_shops(m_sku)
                    return answer_check_shops
                    }
                check_shops(m_sku)
                .then(async(answer_check_shops)=>{
                    console.log('data',answer_check_shops)
                    if(answer_check_shops.answer==='ok'){
                        //variables fro shopify 
                        let handle=data.results_to_show[2]
                        let price_reformatted=data.results_to_show[12]
                        let msrp_price_reformatted=data.results_to_show[11]
                        let title=data.results_to_show[2]
                        let descriptionHtml=data.results_to_show[14]
                        let bodyHtmlL=data.results_to_show[14]
                        let Vendor=data.results_to_show[3]
                        let Barcode=data.scanned_barcode
                        let Image_Src=data.results_to_show[13]
                        //create a load for scan logs
                        //we create bagaz fro shops
                        handle=handle.replace(/ /g, "-")
                        console.log('handle:',handle)
                        if(price_reformatted){
                            price_reformatted=price_reformatted.replace('$','')
                            price_reformatted=price_reformatted.replace(' ','')
                            }
                        if(msrp_price_reformatted){
                            msrp_price_reformatted=msrp_price_reformatted.replace('$','')
                            msrp_price_reformatted=msrp_price_reformatted.replace(' ','')
                            }
                        let price_to_pass=0
                        if(Number(msrp_price_reformatted)>Number(price_reformatted)){
                            price_to_pass=msrp_price_reformatted
                            }
                        else{
                            price_to_pass=price_reformatted
                            }
                        let bagazShops={
                            handle:handle,
                            title:title,
                            descriptionHtml:descriptionHtml,
                            bodyHtmlL:bodyHtmlL,
                            Vendor:Vendor,
                            Published:true,
                            SKU:m_sku,
                            Inventory_Tracker:'shopify',
                            Inventory_Policy:'deny',
                            Fulfillment_Service:'manual',
                            Price: price_to_pass,
                            Requires_Shipping:true,
                            Taxable:true,
                            Barcode:Barcode,
                            Image_Src:Image_Src,
                            Gift_Card:false,
                            Tax_Code:2038710
                            }
                        //console.log('our bagaz to shops',bagazShops)
                        //if we have some rows
                        if(answer_check_shops.data.length>0){
                            //we check the size
                            if(answer_check_shops.data[0].spotify_upload===true){
                                resolve(null)
                                return
                                }
                            else{
                                //here contintue with uploading stuff
                                await upload_shopify(bagazShops)
                                resolve(null)
                                return
                                }
                            }
                        //if we have no data about the m_sku
                        else{
                            // we upload stuff to shopify
                            await upload_shopify(bagazShops)
                            resolve(null)
                            return
                            }
                        }
                    else{
                        console.log('something wrong with getting check shops')
                        resolve(null)
                        return
                        }            
                    })
                }
            })    
        }
    chooseOption(e){
        //here we will need to process the m_sku calls
        console.log('option clicked',e.target.value)
        }
    clicked_back(){
        this.props.dispatch({type:'MenuOption',payload:'associate_main'})
        console.log('clicked back')
        }
    show_back(clicked_back){
        if(this.props.acc_type==='associate'){
            return(
                <button className="sort_search_button_back" onClick={clicked_back}>BACK</button>
                )
            }
        else{
            return null
            }
        }
    show_title(){
        if(this.props.acc_type==='associate'){
            return(
                <div className="ecom_description">
                    SORT
                </div>
                )
            }
        else{
            return null
            }
        }
    outcome(){
        //console.log('inside outcome')
        //if we have a results
        if(this.props.product_name){
            //if ecom
            if(this.props.product_name==='ECOM'){
                return(
                    <div className="sort_results_ecom">
                        {this.props.scanned_barcode +':'+ this.props.product_name}
                    </div>
                    )
                }
            //anithing else
            else{
                let shower='ML'
                if(this.props.product_name==='NOT FOUND'){
                    shower='NOT FOUND'
                    }
                return(
                    <div className="sort_results_ml">
                        {this.props.scanned_barcode +':'+shower}
                    </div>
                    )
                }
            }
        //if we do not have results
        else{
            return (
                <div className="sort_results_none">
                    RESULTS
                </div>
                )
            }
        }
    //while creatiung component
    componentDidMount() {
        //console.log("has mounted");
        this.sortInput.current.focus();
        }
    componentDidUpdate(prevProps, prevState){
        if(prevProps.communicate!==this.props.communicate){
            this.setState({communicate:this.props.communicate})
            }
        }
    render(){

        return(
            <div className="ecom_flex">
                {this.show_title()||null}
                <Formik
                    initialValues={{ upcnr: ""}} 
                    validationSchema={Yup.object().shape({
                        upcnr:Yup.string()
                        .min(11,'11 digits minimum')
                        .max(13,'13 digits maximum')
                        .required('required value')
                        }) 
                    }
                    displayData={(response)=>{
                    }}

                    onSubmit={(values, { setSubmitting,resetForm }) => {
                        console.log('clicked',values)
                        let scanned_barcode=values.upcnr.replace(/\s/g,'')
                        //we reset our results screen
                        var result_payload=[]
                        this.props.dispatch({type:"results_to_show",payload:result_payload});
                        this.props.dispatch({type:"product_name",payload:''});
                        this.props.dispatch({type:"scanned_barcode",payload:scanned_barcode});
                        //creating m_sku
                        let m_sku=scanned_barcode+'-N'
                        //we create payload for request
                        this.setState({communicate:"Fetching..."});
                        async function getSku(m_sku){
                            //via external module
                            let sku_db_res=await getSkuDetails(m_sku)
                            return sku_db_res
                            }
                        getSku(m_sku)
                        .then(sku_db_res=>{
                            console.log('m_sku response',sku_db_res)
                            //if we've found the item
                            if(sku_db_res.answer>0){
                                console.log('yes luck')
                                this.props.dispatch({type:"product_name",payload:sku_db_res.fullans.product_name})
                                resetForm({})
                                return
                                }
                            //if we have no correct response  
                            else{
                                //create timestamps
                                var timestamps=Date.now()
                                var bagaz={
                                    upcnr:values.upcnr,
                                    l:1
                                    }
                                var scan_log_bagaz={
                                    user_id:this.props.acc_ID,
                                    station_id:this.props.stationId,
                                    po_h:this.props.poH,
                                    barcode:values.upcnr,
                                    override:null,
                                    timestamps:timestamps,
                                    l:1
                                    }
                                //we connect local upc_api_db wmt and target local dbs
                                async function asklocalUPCbarcodeQuery(bagaz){
                                    //returning promise
                                    return new Promise(async function(resolve,reject){
                                        let answerlocalUPCbarcodeQuery=await askUPCBCdb(bagaz)
                                        let answertargetdb=await askTargetDB(scan_log_bagaz)
                                        let answerwmtdb=await askupcdb(bagaz)
                                        //let answerwmtdb={answer:0}
                                        //we gather all promises
                                        Promise.all([answerlocalUPCbarcodeQuery,answertargetdb,answerwmtdb])
                                        .then((answerlocalDbs)=>{
                                            //we return our values
                                            resolve(answerlocalDbs)
                                            })
                                        })                                               
                                    }
                                asklocalUPCbarcodeQuery(bagaz,scan_log_bagaz)
                                .then(answerlocalDbs=>{
                                    console.log('local dbs,',answerlocalDbs)
                                    if (answerlocalDbs[0].answer>0||answerlocalDbs[1].answer>0||answerlocalDbs[2].answer>0){
                                        //building payload
                                        var result_payload=crp.barcode2(answerlocalDbs)
                                        this.props.dispatch({type:"results_to_show",payload:result_payload});
                                        //and we go to taxonomy
                                        //we create tax_bagaz
                                        let tax_bagaz={condition_name:'NEW',
                                            target_owned:result_payload[10]||null,
                                            msrp:result_payload[11]||null,
                                            category:result_payload[4]||null}
                                        //we connect to google_taxonomy to fetch product_name
                                        async function getTaxonomy(tax_bagaz){
                                            //console.log('tax bagaz',tax_bagaz)
                                            //via external module
                                            let gtax_db_res=await getGoogTaxDetails(tax_bagaz)
                                            return gtax_db_res
                                            }
                                        getTaxonomy(tax_bagaz)
                                        .then(async(gtax_db_res)=>{
                                            let product_name='none'
                                            console.log('gtax resp',gtax_db_res)
                                            if(gtax_db_res.answer===0){
                                                this.props.dispatch({type:"product_name",payload:'NOT FOUND'})
                                                console.log('no luck')
                                                resetForm({})
                                                return
                                                }
                                            if(gtax_db_res.answer===1){
                                                product_name=gtax_db_res.fullans[0].product_name
                                                console.log('product name',product_name)
                                                this.props.dispatch({type:"product_name",payload:product_name})
                                                //if yes luck then we continue with checking shops
                                                let bagazContinueSort={
                                                    product_name:product_name,
                                                    m_sku:m_sku,
                                                    results_to_show:this.props.results_to_show,
                                                    scanned_barcode:this.props.scanned_barcode
                                                    }
                                                await this.continueSort(bagazContinueSort,'y')
                                                console.log('yes luck')
                                                resetForm({})
                                                return                                                
                                                }
                                            if(gtax_db_res.answer>1){
                                                let msrp_orig=result_payload[11]||null
                                                if(msrp_orig){
                                                    msrp_orig=Number(msrp_orig.slice(2,msrp_orig.length))
                                                    }
                                                console.log('msrp orig',msrp_orig)
                                                for(let gtaxiter=0;gtaxiter<gtax_db_res.answer;++gtaxiter){
                                                    console.log('iter nr',gtaxiter)
                                                    //if we have target_owned=null
                                                    let check_target_owned=gtax_db_res.fullans[gtaxiter].target_owned
                                                    //if there is no target_owned in taxonomy db
                                                    if(check_target_owned===null||!check_target_owned){
                                                        //if we have had no target_item in api answer
                                                        if(!tax_bagaz.target_owned||tax_bagaz.target_owned===null){
                                                            //checking if check is not false
                                                            if(!check_target_owned===false){
                                                                product_name=gtax_db_res.fullans[gtaxiter].product_name
                                                                break
                                                                }
                                                            }
                                                        }
                                                    //checking other options
                                                    let msrp_resp=Number(gtax_db_res.fullans[gtaxiter].msrp)
                                                    console.log('msrp_resp',msrp_resp)
                                                    let pricing_function=gtax_db_res.fullans[gtaxiter].pricing_function
                                                    console.log('pricing_function',pricing_function)
                                                    if(pricing_function==='>='){
                                                        if(msrp_orig>=msrp_resp){
                                                            product_name=gtax_db_res.fullans[gtaxiter].product_name
                                                            break
                                                            }
                                                        }
                                                    else{
                                                        if(msrp_orig<msrp_resp){
                                                            console.log('inside? orig',msrp_orig,'and',msrp_resp)
                                                            product_name=gtax_db_res.fullans[gtaxiter].product_name
                                                            break
                                                            }
                                                        }
                                                    }
                                                this.props.dispatch({type:"product_name",payload:product_name})
                                                let bagazContinueSort={product_name:product_name,
                                                    m_sku:m_sku,
                                                    results_to_show:this.props.results_to_show,
                                                    scanned_barcode:this.props.scanned_barcode}
                                                await this.continueSort(bagazContinueSort,'y')
                                                console.log('yes luck')
                                                resetForm({})
                                                return
                                                }    
                                            })
                                        }
                                    else{
                                        //we try using external apis
                                        async function askapis(scan_log_bagaz){
                                            //returning promise
                                            return new Promise(async function(resolve,reject){
                                                //we look in upc api
                                                let answerUPCbarcodeQuery=await askUPCBCapi(bagaz)
                                                //let answerUPCbarcodeQuery={answer:0}
                                                let answertargetQuery=await askTarget(scan_log_bagaz)
                                                let answerwmtquery=await askwmtapinew(bagaz)
                                                //let answerwmtquery={answer:0}
                                                Promise.all([answerUPCbarcodeQuery,answertargetQuery,answerwmtquery])
                                                .then((answerApis)=>{
                                                    //we return our values
                                                    console.log('answer from apis',answerApis)
                                                    resolve(answerApis)
                                                    })
                                                })
                                            }
                                        askapis(scan_log_bagaz)
                                        .then(answerApis=>{
                                            console.log('afer answer apis',answerApis)
                                            //if we have a response with data
                                            if (answerApis[0].answer>0||answerApis[1].answer>0||answerApis[2].answer>0){
                                                //if we have a code
                                                this.setState({communicate:"EAN fetched and saved to local database. Displaying"});
                                                //building payload
                                                var result_payload=crp.barcode2(answerApis)
                                                console.log('result_payload',result_payload)
                                                //sending it to the reductor
                                                this.props.dispatch({type:"results_to_show",payload:result_payload});
                                                //going to google taxonomy
                                                //we create tax_bagaz
                                                let tax_bagaz={condition_name:'NEW',
                                                    target_owned:result_payload[10]||null,
                                                    msrp:result_payload[11]||null,
                                                    category:result_payload[4]||null}
                                                //we connect to google_taxonomy to fetch product_name
                                                async function getTaxonomy(tax_bagaz){
                                                    //console.log('tax bagaz',tax_bagaz)
                                                    //via external module
                                                    let gtax_db_res=await getGoogTaxDetails(tax_bagaz)
                                                    return gtax_db_res
                                                    }
                                                getTaxonomy(tax_bagaz)
                                                .then(async(gtax_db_res)=>{
                                                    let product_name='none'
                                                    console.log('gtax resp',gtax_db_res)
                                                    if(gtax_db_res.answer===0){
                                                        this.props.dispatch({type:"product_name",payload:'NOT FOUND'})
                                                        console.log('no luck')
                                                        resetForm({})
                                                        return
                                                        }
                                                    if(gtax_db_res.answer===1){
                                                        product_name=gtax_db_res.fullans[0].product_name
                                                        console.log('product name',product_name)
                                                        this.props.dispatch({type:"product_name",payload:product_name})
                                                        console.log('yes luck')
                                                        let bagazContinueSort={product_name:product_name,
                                                            m_sku:m_sku,
                                                            results_to_show:this.props.results_to_show,
                                                            scanned_barcode:this.props.scanned_barcode}
                                                        await this.continueSort(bagazContinueSort,'n')
                                                        resetForm({})
                                                        return
                                                        }
                                                    if(gtax_db_res.answer>1){
                                                        let msrp_orig=result_payload[11]||null
                                                        if(msrp_orig){
                                                            msrp_orig=Number(msrp_orig.slice(2,msrp_orig.length))
                                                            }
                                                        console.log('msrp orig',msrp_orig)
                                                        for(let gtaxiter=0;gtaxiter<gtax_db_res.answer;++gtaxiter){
                                                            console.log('iter nr',gtaxiter)
                                                            //if we have target_owned=null
                                                            let check_target_owned=gtax_db_res.fullans[gtaxiter].target_owned
                                                            //if there is no target_owned in taxonomy db
                                                            if(check_target_owned===null||!check_target_owned){
                                                                //if we have had no target_item in api answer
                                                                if(!tax_bagaz.target_owned||tax_bagaz.target_owned===null){
                                                                    //checking if check is not false
                                                                    if(!check_target_owned===false){
                                                                        product_name=gtax_db_res.fullans[gtaxiter].product_name
                                                                        break
                                                                        }
                                                                    }
                                                                }
                                                            //checking other options
                                                            let msrp_resp=Number(gtax_db_res.fullans[gtaxiter].msrp)
                                                            console.log('msrp_resp',msrp_resp)
                                                            let pricing_function=gtax_db_res.fullans[gtaxiter].pricing_function
                                                            console.log('pricing_function',pricing_function)
                                                            if(pricing_function==='>='){
                                                                if(msrp_orig>=msrp_resp){
                                                                    product_name=gtax_db_res.fullans[gtaxiter].product_name
                                                                    break
                                                                    }
                                                                }
                                                            else{
                                                                if(msrp_orig<msrp_resp){
                                                                    console.log('inside? orig',msrp_orig,'and',msrp_resp)
                                                                    product_name=gtax_db_res.fullans[gtaxiter].product_name
                                                                    break
                                                                    }
                                                                }
                                                            }
                                                        this.props.dispatch({type:"product_name",payload:product_name})
                                                        console.log('yes luck')
                                                        let bagazContinueSort={product_name:product_name,
                                                            m_sku:m_sku,
                                                            results_to_show:this.props.results_to_show,
                                                            scanned_barcode:this.props.scanned_barcode}
                                                        await this.continueSort(bagazContinueSort,'n')
                                                        resetForm({})
                                                        return
                                                        }    
                                                    })
                                                }
                                            //if no positive answer
                                            else{
                                                console.log('no luck luck')
                                                this.props.dispatch({type:"product_name",payload:'NOT FOUND'})
                                                resetForm({})
                                                return
                                                }
                                            })
                                        }
                                    })
                                }
                            })
                        }}
                    >
                    {props => {
                        const {
                        values,
                        touched,
                        errors,
                        isSubmitting,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        } = props;
                    return(
                        <form onSubmit={handleSubmit}>
                            <div className="ecom_search_bar">
                                <div className="ecom_search_title">
                                    BARCODE
                                </div>
                                <input className="ecom_search_input"
                                    size="1"                              
                                    ref={this.sortInput}
                                    autoFocus={this.props.autoFocus}
                                    name="upcnr"
                                    type="text"
                                    placeholder="numbers only"
                                    value={values.upcnr}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                <div className="ecom_FormikErrors">{errors.upcnr||'\u00a0'}</div>
                            </div>
                            <div className="ecom_search_empty_space"></div>
                        {/*   <button className="ecom_search_button_confirm" type="submit"  disabled={isSubmitting}>CONFIRM</button> */}
                        </form>
                        )
                    }}
               </Formik>
               {this.outcome()||'RESULTS'}
               {this.show_back(this.clicked_back)||null}
            </div>
            )
    }
}


const mapStateToProps=(state)=>({
    LOGGED:state.LOGGED,
    MenuOption:state.MenuOption,
    acc_type:state.acc_type,
    product_name:state.product_name,
    results_to_show:state.results_to_show,
    scanned_barcode:state.scanned_barcode
    })
export default connect(mapStateToProps)(Sort);
