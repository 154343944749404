export function askWmtCategory(bagaz){
    return new Promise(async function(resolve,reject){
        //console.log('inside4',bagaz)
        var request=new Request('https://upcscanningapp.azurewebsites.net/api/goggle_tax_wmt_conversion',{
            method:'POST',
            headers: new Headers({'Accept': 'application/json',
            'Content-Type':'application/json'}),
            body:JSON.stringify(bagaz)
            })
        //we make a request
        fetch(request)
        //we get response, in form of json
            .then(response=>response.json())
            .then(async(response)=>{
                //console.log('response',response)
                if(response.answer==='ok'){
                    if(!response.data[0]){
                        console.log('modify')
                        response.data[0]={
                            google_taxonomy_category:bagaz.gcat,
                            wmt_mp_product_category:'Other',
                            wmt_mp_product_subcategory:'Other'
                            }
                        }
                    resolve(response.data[0])
                    }
                return
            })
        })
    }
export function GetFeedId(){
    return new Promise(async function(resolve,reject){
        let bagaz={logged:1,
            type:"CONTENT_PRODUCT"}
        var request=new Request('https://upcscanningapp.azurewebsites.net/api/getFeedId',{
            method:'POST',
            headers: new Headers({'Accept': 'application/json',
            'Content-Type':'application/json'}),
            body:JSON.stringify(bagaz)
            })
        //we make a request
        fetch(request)
        //we get response, in form of json
            .then(response=>response.json())
            .then(async(response)=>{
                //console.log('response',response)
                resolve(response)
                return
            })
        })
    }
export function GetWmtToken(){
    return new Promise(async function(resolve,reject){
        let bagaz={logged:1}
        var request=new Request('https://upcscanningapp.azurewebsites.net/api/getWmtToken',{
            method:'POST',
            headers: new Headers({'Accept': 'application/json',
            'Content-Type':'application/json'}),
            body:JSON.stringify(bagaz)
            })
        //we make a request
        fetch(request)
        //we get response, in form of json
            .then(response=>response.json())
            .then(async(response)=>{
                console.log('response',response)
                resolve(response)
                return
            })
        })
    }
export function SendWmtItem(bagazShops,accessToken){
    return new Promise(async function(resolve,reject){
        let bagaz=bagazShops
        bagaz.wmt_token=accessToken
        var request=new Request('https://upcscanningapp.azurewebsites.net/api/SendItemToWmtMarketplace1',{
            method:'POST',
            headers: new Headers({'Accept': 'application/json',
            'Content-Type':'application/json'}),
            body:JSON.stringify(bagaz)
            })
        //we make a request
        fetch(request)
        //we get response, in form of json
            .then(response=>response.json())
            .then(async(response)=>{
                console.log('response',response)
                resolve(response)
                return
            })
        })
    }
export function SendWmtItemp2(data,headers){
    return new Promise(async function(resolve,reject){
        var url1=`https://marketplace.walmartapis.com/v4/feeds?feedType=item&setupType=byMatch`
        let json_bagaz={
            'MPItemFeed xmlns="http://walmart.com/':{
            'MPItemFeedHeader': {
                "version": "3.2",
                "mart":"WALMART_US",
                requestId:'ITEM'
                },
            "MPItem": [{
                "processMode":"REPLACE_ALL",
                "sku": data.SKU,
                "productIdentifiers": {
                    productIdType:data.wmt_determined_barcode_type,
                    productId:data.wmt_determined_barcode,
                    },
                "MPProduct":{
                    subCategory:data.wmt_subcategory,
                    SkuUpdate:'Yes',
                    msrp:data.Price,
                    productName:data.title,
                    category:{
                        [data.wmt_category]:{
                            [data.wmt_subcategory]:{
                                shortDescription:data.handle,
                                brand:data.Vendor,
                                manufacturer:data.Vendor,
                                manufacturerPartNumber:data.Model,
                                modelNumber:data.Model,
                                mainImageUrl:data.Image_Src,
                                color:data.Color,
                                }
                            }
                        }
                    },
                "MPOffer":{
                    price:data.Price,
                    StartDate:'2020-12-19',                        
                    ShippingWeight:{
                        measure:data.WmtWeight,
                        unit:"lb"
                        },
                    ProductTaxCode:2038710,
                    }
                }],
            }}
        //console.log(headers)
        let formdata=new FormData()
        let responseData=JSON.stringify(json_bagaz)
        var byteArray = new Uint8Array(responseData.match(/.{2}/g)
                              .map(e => parseInt(e, 16)));
        let blb=new Blob([byteArray], { type: 'application/json' })
        await formdata.append('file',blb,"file.json")
        var request=new Request(url1,{
            method:'POST',
            headers: headers.headers,
            body:formdata
            })
        fetch(request)
        //we get response, in form of json
            .then(response=>response.json())
            .then(async(response)=>{
                console.log('response',response)
                resolve(response)
                return
            })
        })
    }