import Modals from 'react-bootstrap/Modal'  
import React, {Component} from 'react';
import {connect} from 'react-redux';
import LoginForm from '../components/LoginForm';
import {getSkuDetails} from '../apicalls/skuCalls'
import {getGoogTaxDetails} from '../apicalls/googTaxCalls'
import {getSaveErr} from '../apicalls/getSaveErr'
//we go with bootstrap
import './modal.css'
import styled from 'styled-components';
import Override from '../components/modal/Override_v6_1'
import PrintSku from '../components/modal/PrintSku'

const condition_translator={"NEW":"N","LIKE_NEW":"LN","USED":"U","REFURB":"R","EXPIRED":"E","NOT_TESTED":"T"}

/*   align-items: center; 
    justify-self:center;
        align-items: stretch;
    justify-content: center;
    */
const ScreenSize = {
    mobile: '320px',
    desktop: '730px'
  }
const Device={mobile: `(min-width: ${ScreenSize.mobile})`,
            desktop: `(min-width: ${ScreenSize.desktop})`
            }

const ModalStyle=styled.div`
    position:absolute;
    display:flex;
    align-items: center; 
    justify-self:center;
    justify-content: center;
    padding-bottom: 15%;
    background:  #d0a78a;
    border: 2px solid rgb(59, 59, 58);
    flex-direction: column; 
    font-family: Segoe UI, Times, Helvetica, sans-serif;
    @media ${Device.mobile} { 
        top:14%;
        left:5%;
        width:90%;
        height: 400px;
      };
    @media ${Device.desktop} {
        top:14%;
        left:25%;
        width:50%;
        height: 300px;
      };
    `
    
const ButtonStyled=styled.button`
    text-align: center;
    color:black;
    background:  #c6a9c; 
    padding-left:10px;
    padding-right:10px;
    padding-top:5px;
    padding-bottom:5px;
    border: 2px solid #58402e;
    border-radius: 5px;
    font-size:13px;
    `

class Modal extends Component {
    constructor(props){
        super(props)
        this.state={isOpen:true,
        condition:''};
        //we pass props to the function handlekeyup (by using this.handlekeyup) and from (by using .bind(this) )
        this.handleKeyUp = this.handleKeyUp.bind(this);
        this.setCondition=this.setCondition.bind(this);

    
    };
    setCondition(e){
        //here we will need to process the m_sku calls
        //console.log('condition',e.target.value)
        console.log('results',this.props.results_to_show)
        const cond=e.target.value
        const cond_sku=condition_translator[cond]
        const m_sku=this.props.scanned_barcode+'-'+cond_sku
        console.log('looking for m-sku',m_sku)
        //we kane a call to fetch sku_details from table
        async function getSku(m_sku){
            //via external module
            let sku_db_res=await getSkuDetails(m_sku)
            return sku_db_res
            }
        getSku(m_sku)
        .then(sku_db_res=>{
            console.log('m_sku response',sku_db_res)
            //if we have no correct response
            if(sku_db_res.answer===0){
                //we store information that there was no sku found
                this.props.dispatch({type:"m_sku_found",payload:'n'})
                //we create tax_bagaz
                let tax_bagaz={condition_name:cond,
                    target_owned:this.props.results_to_show[10]||null,
                    msrp:this.props.results_to_show[11]||null,
                    category:this.props.results_to_show[4]||null}
                //we connect to google_taxonomy to fetch product_name
                async function getTaxonomy(tax_bagaz){
                    //console.log('tax bagaz',tax_bagaz)
                    //via external module
                    let gtax_db_res=await getGoogTaxDetails(tax_bagaz)
                    return gtax_db_res
                    }
                getTaxonomy(tax_bagaz)
                .then(gtax_db_res=>{
                    let product_name='none'
                    console.log('gtax resp',gtax_db_res)
                    if(gtax_db_res.answer===0){
                        console.log('erroneous fetch',gtax_db_res)
                        //if error, we send the details to google_category_not_found table
                        let timestamp=Date.now()
                        let bagazerr={timestamp:timestamp,
                            category:this.props.results_to_show[4],
                            m_sku:m_sku}
                        async function saveError(bagazerr){
                            let answerSendErr=await getSaveErr(bagazerr)
                            return answerSendErr
                            }
                        saveError(bagazerr)
                        .then(answerSendErr=>{
                            console.log('errors saved',answerSendErr)
                            this.setState({condition:cond})
                            this.props.dispatch({type:"item_condition",payload:cond})
                            this.props.dispatch({type:"product_name",payload:product_name})
                            console.log('the ends')
                            this.props.dispatch({type:"SET_SHOW_LOGIN_STATUS", payload:'override'})
                            return
                            })
                        }   
                    if(gtax_db_res.answer===1){
                        product_name=gtax_db_res.fullans[0].product_name
                        //console.log('product name',product_name)
                        this.setState({condition:cond})
                        this.props.dispatch({type:"item_condition",payload:cond})
                        this.props.dispatch({type:"product_name",payload:product_name})
                        console.log('the end')
                        this.props.dispatch({type:"SET_SHOW_LOGIN_STATUS", payload:0})
                        return
                        }
                    //if we have more tahn on row to process
                    if(gtax_db_res.answer>1){
                        let msrp_orig=this.props.results_to_show[11]||null
                        if(msrp_orig){
                            msrp_orig=Number(msrp_orig.slice(2,msrp_orig.length))
                            }
                        console.log('msrp orig',msrp_orig)
                        for(let gtaxiter=0;gtaxiter<gtax_db_res.answer;++gtaxiter){
                            console.log('iter nr',gtaxiter)
                            //if we have target_owned=null
                            let check_target_owned=gtax_db_res.fullans[gtaxiter].target_owned
                            //if there is no target_owned in taxonomy db
                            if(check_target_owned===null||!check_target_owned){
                                //if we have had no target_item in api answer
                                if(!tax_bagaz.target_owned||tax_bagaz.target_owned===null){
                                    //checking if check is not false
                                    if(!check_target_owned===false){
                                        product_name=gtax_db_res.fullans[gtaxiter].product_name
                                        break
                                        }
                                    }
                                }
                            //checking other options
                            let msrp_resp=Number(gtax_db_res.fullans[gtaxiter].msrp)
                            console.log('msrp_resp',msrp_resp)
                            let pricing_function=gtax_db_res.fullans[gtaxiter].pricing_function
                            console.log('pricing_function',pricing_function)
                            if(pricing_function==='>='){
                                if(msrp_orig>=msrp_resp){
                                    product_name=gtax_db_res.fullans[gtaxiter].product_name
                                    break
                                    }
                                }
                            else{
                                if(msrp_orig<msrp_resp){
                                    console.log('inside? orig',msrp_orig,'and',msrp_resp)
                                    product_name=gtax_db_res.fullans[gtaxiter].product_name
                                    break
                                    }
                                }
                            }
                        this.setState({condition:cond})
                        this.props.dispatch({type:"item_condition",payload:cond})
                        this.props.dispatch({type:"product_name",payload:product_name})
                        console.log('the end')
                        this.props.dispatch({type:"SET_SHOW_LOGIN_STATUS", payload:0})
                        }
                    })
                }
            else{
                //if m_sku_found
                //console.log('sku resp',sku_db_res.fullans.product_name)
                this.props.dispatch({type:"m_sku_found",payload:'y'})
                this.props.dispatch({type:"item_condition",payload:cond})
                this.props.dispatch({type:"product_name",payload:sku_db_res.fullans.product_name})
                this.props.dispatch({type:"SET_SHOW_LOGIN_STATUS", payload:0})
                //console.log('found it')
                }
            })
        }
    //fucntion which deals with closing the modal
    showLogin=()=> {
        this.props.dispatch({type:"SET_SHOW_LOGIN_STATUS", payload:0})
        }
    //while creatiung component
    componentDidMount() {
        //console.log("has mounted");
        //adding keyboard listener (keyup-press a key, handlekeyup-function which is triggered, false-?)
        window.addEventListener('keyup', this.handleKeyUp, false);
        }
    //while unmounting component
    componentWillUnmount(){
        //we remove event listener (for keyboard)
        window.removeEventListener("keyup", this.handleKeyUp, false);
        }
    //when re-enabling component
    componentDidUpdate(){
        //console.log('updating modal')
        if(!this.state.isOpen){
            //we add listener one more time
            window.addEventListener('keyup', this.handleKeyUp, false);
            }
        }

    handleKeyUp(e) {
        //get the function inside the function(?)
        //asking for a key
        const Logins=this.props
        const keys = {
        //if 27 (esc)
          27: () => {
            //prevents submit
            e.preventDefault();
            //triggers function which change showlogin(ie closes modal)
            Logins.dispatch({type:"SET_SHOW_LOGIN_STATUS", payload:0})
            //removes listener
            window.removeEventListener("keyup", this.handleKeyUp, false);
            },
          13: ()=>{
            //prevents submit
            e.preventDefault();
            //triggers function which change showlogin(ie closes modal)
//            Logins.dispatch({type:"SET_SHOW_LOGIN_STATUS", payload:0})
            //removes listener
            window.removeEventListener("keyup", this.handleKeyUp, false);
            }
        }
        //if the key has ben pressed, run function to determine if it was esc
        if (keys[e.keyCode]) {
            keys[e.keyCode]();
          }
    }
    render(){
        //console.log('modal is on',this.props.showlogin)
        if (this.props.showlogin===0) {
            
            return null
            }
        else{
            if (this.props.showlogin===1) {
                return(
                    <Modals.Dialog centered>
                        <ModalStyle>
                            <br></br>
                            <div>Please Enter Your Credentials</div>
                            <br></br>
                            <LoginForm/>
                            <br></br>
                            <ButtonStyled onClick={this.showLogin}>Close</ButtonStyled>  
                        </ModalStyle>
                    </Modals.Dialog>
                    )
                }
            if(this.props.showlogin===2){
                return(
                    <Modals.Dialog centered>
                         <ModalStyle>
                            <br></br>
                            <div>Please Enter Your Credentials p2.</div>
                            <br></br>
                            <LoginForm/>
                            <br></br>
                            <ButtonStyled onClick={this.showLogin}>Close</ButtonStyled>  
                        </ModalStyle>
                    </Modals.Dialog>
                    )
                }
            if(this.props.showlogin==='condition'){
                return(
                    <Modals.Dialog centered>
                        <div className='modal_condition'>
                            <br></br>
                            <br></br>
                            <div className='modal_condition_title'>SELECT CONDITION</div>
                            <div className='modal_condition_options_box'>
                                <button className='modal_condition_options_button' style={{"backgroundColor":"green"}} onClick={this.setCondition} value='NEW'>NEW</button>
                                <button className='modal_condition_options_button' style={{"backgroundColor":"yellow"}} onClick={this.setCondition} value='LIKE_NEW'>LIKE NEW</button>
                                <button className='modal_condition_options_button' style={{"backgroundColor":"orange"}} onClick={this.setCondition} value='USED'>USED</button>
                                <button className='modal_condition_options_button' style={{"backgroundColor":"lightblue"}} onClick={this.setCondition} value='REFURB'>REFURBISHED</button>
                                <button className='modal_condition_options_button' style={{"backgroundColor":"red"}} onClick={this.setCondition} value='EXPIRED'>EXPIRED</button>
                                <button className='modal_condition_options_button' style={{"backgroundColor":"white"}} onClick={this.showLogin}>BACK</button>
                            </div>
                        </div>
                    </Modals.Dialog>
                    )
                }
            if (this.props.showlogin==='override') {
                //console.log('in override?')
                return(
                    <Modals.Dialog centered>
                        <Override/>
                    </Modals.Dialog>
                    )
                }
            if (this.props.showlogin==='sku_print_modal') {
                console.log('in sku print modal?')
                return(
                    <Modals.Dialog centered>
                        <PrintSku/>
                    </Modals.Dialog>
                    )

                }
            }
        };
    };
    const mapStateToProps=(state)=>({
        showlogin:state.showlogin,
        acc_type:state.acc_type,
        acc_ID:state.acc_ID,
        m_sku_found:state.m_sku_found,
        results_to_show:state.results_to_show,
        scanned_barcode:state.scanned_barcode
    })
    export default connect(mapStateToProps)(Modal);