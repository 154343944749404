export function SaveEcomLogs(bagaz){
    return new Promise(async function(resolve,reject){
        var request=new Request('https://upcscanningapp.azurewebsites.net/api/save_ecom_logs', {            
            method:'POST',
            headers: new Headers({'Accept': 'application/json',
            'Content-Type':'application/json'}),
            body:JSON.stringify(bagaz)
            })
        //we make a request
        fetch(request)
        //we get response, in form of json
            .then(response=>response.json())
            .then(response=>{
                //console.log('wmt returns')
                resolve(response)
            })
        })
    }