export function GetSerialNumbersRange(bagaz){
    return new Promise(async function(resolve,reject){
        var request=new Request('https://upcscanningapp.azurewebsites.net/api/get_serial_numbers_range', {            
            method:'POST',
            headers: new Headers({'Accept': 'application/json',
            'Content-Type':'application/json'}),
            body:JSON.stringify(bagaz)
            })
        //we make a request
        fetch(request)
        //we get response, in form of json
            .then(response=>response.json())
            .then(response=>{
                console.log('serial number returns',response)
                resolve(response)
            })
        })
    }
export function GetWhlsLogsProductName(bagaz){
    return new Promise(async function(resolve,reject){
        var request=new Request('https://upcscanningapp.azurewebsites.net/api/get_whls_logs_product_name', {            
            method:'POST',
            headers: new Headers({'Accept': 'application/json',
            'Content-Type':'application/json'}),
            body:JSON.stringify(bagaz)
            })
        //we make a request
        fetch(request)
        //we get response, in form of json
            .then(response=>response.json())
            .then(response=>{
                console.log('serial number returns',response)
                resolve(response)
            })
        })
    }
export function GetWhlsSerialNumber(){
    return new Promise(async function(resolve,reject){
        //most likely we will check shops api to see if such item has not been uploaded already
        //if not, then we send the load to shopify
        //as we amend the local db table with what has been send
        let bagaz={l:1}
        var request=new Request('https://upcscanningapp.azurewebsites.net/api/get_whls_serial_number', {            
            method:'POST',
            headers: new Headers({'Accept': 'application/json',
            'Content-Type':'application/json'}),
            body:JSON.stringify(bagaz)
            })
        //we make a request
        fetch(request)
        //we get response, in form of json
            .then(response=>response.json())
            .then(response=>{
                console.log('serial number returns',response)
                resolve(response)
            })
        })
    }

export function SaveWhlsSerialNumber(serialnumber){
    return new Promise(async function(resolve,reject){
        //most likely we will check shops api to see if such item has not been uploaded already
        //if not, then we send the load to shopify
        //as we amend the local db table with what has been send
        let bagaz={serialnumber:serialnumber,
            l:1}
        var request=new Request('https://upcscanningapp.azurewebsites.net/api/save_whls_serial_number', {            
            method:'POST',
            headers: new Headers({'Accept': 'application/json',
            'Content-Type':'application/json'}),
            body:JSON.stringify(bagaz)
            })
        //we make a request
        fetch(request)
        //we get response, in form of json
            .then(response=>response.json())
            .then(response=>{
                console.log('serial number returns',response)
                resolve(response)
            })
        })
    }

export function SaveWhlsLogs(bagaz){
    return new Promise(async function(resolve,reject){
        //most likely we will check shops api to see if such item has not been uploaded already
        //if not, then we send the load to shopify
        //as we amend the local db table with what has been send
        bagaz.l=1
        var request=new Request('https://upcscanningapp.azurewebsites.net/api/SaveWhlsLogs', {            
            method:'POST',
            headers: new Headers({'Accept': 'application/json',
            'Content-Type':'application/json'}),
            body:JSON.stringify(bagaz)
            })
        //we make a request
        fetch(request)
        //we get response, in form of json
            .then(response=>response.json())
            .then(response=>{
                console.log('whls logs answer',response)
                resolve(response)
            })
        })
    }