import React,{Component} from 'react';
import {connect} from 'react-redux';


class AssociateMenu extends Component {
    constructor(){
        super()
        this.state={
            };
        this.chooseOption=this.chooseOption.bind(this)
    }
    chooseOption(e){
        //here we will need to process the m_sku calls
        //console.log('option clicked',e.target.value)
        switch(e.target.value){
            case "ecom":{
                this.props.dispatch({type:'MenuOption',payload:'ecom'})
                break
                }
            case "manifest":{
                this.props.dispatch({type:'MenuOption',payload:'manifest'})
                break
                }
            case "sort":{
                this.props.dispatch({type:'MenuOption',payload:'sort'})
                break
                }
            case "remove":{
                this.props.dispatch({type:'MenuOption',payload:'whlsremove'})
                break
                }
            default:{
                console.log('clicked',e.target.value)
                break
                }
            }
        }
    render(){
        return(
            <div className="associate_menu_flex">
                <br></br>
                <br></br>
                <button className="associate_menu_button" onClick={this.chooseOption} value='ecom'>ECOM ADD</button>
                <button className="associate_menu_button" onClick={this.chooseOption} value='sort'>SORT</button>
                <button className="associate_menu_button" style={{backgroundColor:"#ff890d", color:"black"}} onClick={this.chooseOption} value='manifest'>MANIFEST</button>
                <button className="associate_menu_button" style={{backgroundColor:"red"} }onClick={this.chooseOption} value='remove'>WHLS REMOVE</button>
            </div>
            )
    }
}
const mapStateToProps=(state)=>({
    LOGGED:state.LOGGED,
    MenuOption:state.MenuOption
    })
export default connect(mapStateToProps)(AssociateMenu);