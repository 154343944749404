//dependencies
import React from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';
//components
import Login from '../components/Login';
import Logout from '../components/Logout';
import Options from '../components/options/Options_v6_3'
//assign el_id to a variable

const TopMenuDiv = styled.div`
    width:30%;
    float:right;
    padding-top:27px;
    font-family: Segoe UI, Times, Helvetica, sans-serif;
    color: ghostwhite;
    font-size: 20px;
    display:flex;
    justify-content:space-evenly;
    @media (max-width: 500px) {
        line-height:20px;
        font-size:20px;
        text-align:center;
       }
    `   

//class which shows top menu (sign up and log in)
class TopMenu extends React.Component {
    //creating class and adding props (attributes)
    constructor(props){
        super(props)
        //initial state -not logged
        this.state={LOGGED:0};
        } 
    //here we start writing what we will see
    render() {
        //if we are not logged or props is not initiated (ie first load of the page)
        if (this.props.LOGGED===0||!this.props.LOGGED){
            //we show this
            return(
                <TopMenuDiv>
                    <Login/>
                </TopMenuDiv>   
                )
            }
            //if we are logged
            else{
                //we show this
                return(
                    <TopMenuDiv>
                        <Options/>
                        <Logout/>
                    </TopMenuDiv>  
                )
            }
        }

    }

const mapStateToProps=(state)=>({
    LOGGED:state.LOGGED,
})
export default connect(mapStateToProps)(TopMenu);