import React, {Component} from 'react';
import DropdownMenu from 'react-dd-menu';
import { connect } from 'react-redux';
//import styled from 'styled-components'

//class dealing with options
class Options extends Component {
    constructor(){
        super()
        this.state={
            isDropdownMenuOpen: false,
            }
        this.toggler = this.toggler.bind(this);
        this.closer=this.closer.bind(this)
        this.chosenOption=this.chosenOption.bind(this)
    }
    toggler(){
        //opens/closes menu
        this.setState({ isDropdownMenuOpen: !this.state.isDropdownMenuOpen });
        }
    //function which takes arg from dropdown buttons and open appropriate option
    chosenOption(option){
        //display scanner
        switch(option){
            case 1:{
                this.props.dispatch({type:"MenuOption",payload:1})
                break
                }
            //display converter
            case 2:{
                this.props.dispatch({type:"MenuOption",payload:2})
                break
                }  
            //display decoder
            case 3:{
                this.props.dispatch({type:"MenuOption",payload:3})
                break
                }
            case 4:{
                this.props.dispatch({type:"MenuOption",payload:"ecom"})
                break
                }
            case 5:{
                this.props.dispatch({type:"MenuOption",payload:"sort"})
                break
                }
            case 6:{
                this.props.dispatch({type:"MenuOption",payload:"whls_add"})
                break
                }
            case 7:{
                this.props.dispatch({type:"MenuOption",payload:"manifest"})
                break
                }
            case 8:{
                this.props.dispatch({type:"MenuOption",payload:"manifestdl"})
                break
                }
            case 9:{
                this.props.dispatch({type:"MenuOption",payload:"whlsremove"})
                break
                }
            case 99:{
                this.props.dispatch({type:"MenuOption",payload:"testing"})
                break
                }
            default:{
                this.props.dispatch({type:"MenuOption",payload:1})
                break
                }
            
            }

        }
    //closing dropdown menu
    closer(){
        this.setState({ isDropdownMenuOpen: false });
        }
    render(){
        //arguments for dropdown menu
        const  menuOptions= {
            isOpen: (this.props.isDropdownMenuOpen||this.state.isDropdownMenuOpen),
            close: this.closer,
            align: 'left',
            animate:false,
            toggle:( <button className="DropdownMainButton" onClick={this.toggler}>
                        Menu
                    </button>)
          };
        //if we are logged
        if(this.props.LOGGED===1){
            //if acc type = admin
            switch (this.props.acc_type){
                case "admin":{
                    return(
                        <DropdownMenu {...menuOptions}>
                            <div className="DropDownBlock">
                                <button className="SubItem" key="option1" onClick={()=>this.chosenOption(1)}>
                                    Barcode Scanner
                                </button>
                                <button className="SubItem" key="option2" onClick={()=>this.chosenOption(2)}>
                                    Bulk Upload
                                </button>
                                <button className="SubItem" key="option3" onClick={()=>this.chosenOption(3)}>
                                    Converter
                                </button>
                                <button className="SubItem" key="option4" onClick={()=>this.chosenOption(4)}>
                                    ECOM add
                                </button>
                                <button className="SubItem" key="option5" onClick={()=>this.chosenOption(5)}>
                                    SORT
                                </button>
                                <button className="SubItem" key="option6" onClick={()=>this.chosenOption(6)}>
                                    WHLS add
                                </button>
                                <button className="SubItem" key="option9" onClick={()=>this.chosenOption(9)}>
                                    WHLS remove
                                </button>
                                <button className="SubItem" key="option7" onClick={()=>this.chosenOption(7)}>
                                    Manifest
                                </button>
                                <button className="SubItem" key="option8" onClick={()=>this.chosenOption(8)}>
                                    D/L Manifest
                                </button>
                            </div>
                        </DropdownMenu>
                        )
                    }
                case "supervisor":{
                    return(
                        <DropdownMenu {...menuOptions}>
                            <div className="DropDownBlock">
                                <button className="SubItem" key="option1" onClick={()=>this.chosenOption(1)}>
                                    Barcode Scanners
                                </button>
                                <button className="SubItem" key="option4" onClick={()=>this.chosenOption(4)}>
                                    ECOM add
                                </button>
                                <button className="SubItem" key="option5" onClick={()=>this.chosenOption(5)}>
                                    SORT
                                </button>
                                <button className="SubItem" key="option6" onClick={()=>this.chosenOption(6)}>
                                    WHLS add
                                </button>
                                <button className="SubItem" key="option9" onClick={()=>this.chosenOption(9)}>
                                    WHLS remove
                                </button>
                                <button className="SubItem" key="option7" onClick={()=>this.chosenOption(7)}>
                                    Manifest
                                </button>
                                <button className="SubItem" key="option8" onClick={()=>this.chosenOption(8)}>
                                    D/L Manifest
                                </button>
                            </div>
                        </DropdownMenu>
                        )
                    }
                case "scanner":{
                    return(
                        <DropdownMenu {...menuOptions}>
                            <div className="DropDownBlock">
                                <button className="SubItem" key="option1" onClick={()=>this.chosenOption(1)}>
                                    Barcode Scanner
                                </button>
                                <button className="SubItem" key="option6" onClick={()=>this.chosenOption(6)}>
                                    WHLS add
                                </button>
                                <button className="SubItem" key="option9" onClick={()=>this.chosenOption(9)}>
                                    WHLS remove
                                </button>
                                <button className="SubItem" key="option7" onClick={()=>this.chosenOption(7)}>
                                    Manifest
                                </button>
                            </div>
                        </DropdownMenu>
                        )
                    }
                default:{
                    return (<DropdownMenu {...menuOptions}>
                        <div className="DropDownBlock">
                            <button className="SubItem" key="option1" onClick={()=>this.chosenOption(99)}>
                                testing
                            </button>
                        </div>
                    </DropdownMenu>)
                    }

                }
            }
            //if not, do not render menu
            else{
                return null;
                }
        }
}
const mapStateToProps=(state)=>({
    LOGGED:state.LOGGED,
    acc_type:state.acc_type
})

export default connect(mapStateToProps)(Options);



