import React,{Component} from 'react'
const product_full_names={"ECOM":"ECOM","REFURB":"REFURB","SLVG-PLT":"SALVAGE PALLET","TGT-AP":"TGT APPAREL","TGT-SH":"TGT SHOES","TGT-UG":"TGT UNDERGARMENTS","TGT-GM":"TGT GM","TGT-HC":"TGT HIGH COUNT GM","TGT-SW":"TGT SWIMWEAR","TGT-X":"TGT SEASONAL"};
export default class ComponentToPrint extends Component {
    constructor(props){
        super()
        }
    render() {
      return (
        <div>
            <div>
                <div style={{"textAlign":"center","fontSize":"50px","fontWeight":"700","backgroundColor":"white"}}>
                    {this.props.sku|| ' '}
                </div>
                <div style={{"textAlign":"center"}}>
                    <svg id="barcode"></svg>
                </div>
                <div style={{"textAlign":"center","fontSize":"30px","fontWeight":"600","backgroundColor":"white","paddingTop":"5px"}}>
                    {product_full_names[this.props.sku]|| ' '}
                </div>
            </div>
            <p style={{"pageBreakBefore": "always"}}> </p>
            <div>
                <div style={{"textAlign":"center","fontSize":"50px","fontWeight":"700","backgroundColor":"white"}}>
                    {this.props.sku|| ' '}
                </div>
                <div style={{"textAlign":"center"}}>
                    <svg id="barcode"></svg>
                </div>
                <div style={{"textAlign":"center","fontSize":"30px","fontWeight":"600","backgroundColor":"white","paddingTop":"5px"}}>
                    {product_full_names[this.props.sku]|| ' '}
                </div>
            </div>
            <p style={{"pageBreakBefore": "always"}}> </p>
            <div>
                <div style={{"textAlign":"center","fontSize":"50px","fontWeight":"700","backgroundColor":"white"}}>
                    {this.props.sku|| ' '}
                </div>
                <div style={{"textAlign":"center"}}>
                    <svg id="barcode"></svg>
                </div>
                <div style={{"textAlign":"center","fontSize":"30px","fontWeight":"600","backgroundColor":"white","paddingTop":"5px"}}>
                    {product_full_names[this.props.sku]|| ' '}
                </div>
            </div>
            <p style={{"pageBreakBefore": "always"}}> </p>
            <div>
                <div style={{"textAlign":"center","fontSize":"50px","fontWeight":"700","backgroundColor":"white"}}>
                    {this.props.sku|| ' '}
                </div>
                <div style={{"textAlign":"center"}}>
                    <svg id="barcode"></svg>
                </div>
                <div style={{"textAlign":"center","fontSize":"30px","fontWeight":"600","backgroundColor":"white","paddingTop":"5px"}}>
                    {product_full_names[this.props.sku]|| ' '}
                </div>
            </div>
        </div>
        
        )
      }
    }