import {askcorrelatingquery} from '../apicalls/askupc'
import crp from'../components/crp'
const bluebird=require('bluebird')

//here we process single items
async function processSingleItemCorrelation(singleitem){
    return new Promise(async function(resolve,reject){
        var bagaz={
            barcode:singleitem["unknown_barcode"],
            nr11:singleitem["unknown_barcode"],
            l:1
            }
            //console.log('kuku',bagaz)
            async function askcor(bagaz){
                //returning promise
                let answercorrelatingquery=await askcorrelatingquery(bagaz)
                return answercorrelatingquery
                }
            askcor(bagaz)
            .then(answercorrelatingquery=>{
                var result_payload_object={barcode:singleitem["unknown_barcode"]}
                //console.log('response from correlation',answercorrelatingquery)
                if (answercorrelatingquery.answer.answer==='bingo'){
                    let result_payload=crp.correlating(answercorrelatingquery)
                    result_payload_object.data=result_payload
                    //console.log('passing final object',result_payload_object)
                    resolve(result_payload_object)
                    return
                    }
                else{
                    resolve(result_payload_object)
                    return
                    }
                })
        })
    }


//here we dispatch single items from batch
async function processSingleBatchCorrelation(singlebatch){
    return new Promise(async function(resolve,reject){
        //console.log('single batch',singlebatch)
        //console.log('in single batch',singlebatch)
        //here we need to start do dispatch single items                       
        async function processSingleItems(singlebatch){
            //console.log('before single items',arrayA)
            //const processB=await Promise
            //        .all(arrayA.map(async Aitem=>await getArrayB(Aitem)))
            const updatedsingleitem=await Promise
                .all(singlebatch.map(async Aitem=>await processSingleItemCorrelation(Aitem)))
            return updatedsingleitem
            }
        processSingleItems(singlebatch)
            .then(resultsSingleItems=>{
                resolve(resultsSingleItems)
                })
        })
    }


export function getcorelationdetails(input){
    return new Promise (async function(resolve,reject){
        //console.log('our before apis',input)
        //let arrayA=input
        //i thinkit is already in batches
        //here we will split the array to sub-arrays 50 items each
        var inputL=input.length
        //console.log('length of input',inputL)
        //checking how many times we need to iterate over
        var iterNr=Math.ceil(inputL/50)
        //console.log('r of iterations',iterNr)
        let arrayA=[]
        //preparing loads to check over (10 for each batch)
        for(let a=0;a<iterNr;++a){
            //iterate over each of 10th
            let bIter=50;
            if(a===iterNr-1){
                bIter=inputL-1-(iterNr-1)*50
                //console.log('last Biter',bIter)
                }
            let temp_arr=[];
            for (let b=0;b<bIter;++b){
                //checking the value
                let c=a*50+b
                temp_arr.push(input[c])
                }
                //we add found to array
                arrayA.push(temp_arr)
                }
        //arrayA=arrayA.slice(0,1)
        //arrayA=[[{"11-Digit UPC":4155405501},{"11-Digit UPC":"04155405601"},{"11-Digit UPC":4155405611}],
        //[{"11-Digit UPC":4155405491},{"11-Digit UPC":"04155405621"},{"11-Digit UPC":4155405604}]
        // ]
        //here we need to start do dispatch batches                        
        async function processBatchaTime(arrayA){
            //console.log('before single items',arrayA)
            //const updatedCorrel=await mapSeries(arrayA,processSingleBatch)
            const updatedCorrel=await bluebird.Promise.map(arrayA,processSingleBatchCorrelation,{concurrency: 1})
                    .then(results=>{return results})
            return updatedCorrel
            }
        processBatchaTime(arrayA)
            .then(results=>{
                //console.log('after getbarcodedetailsapis',results)
                resolve(results)
                })
        })
    }