//depencdencies
import React, {Component} from 'react';
import styled from 'styled-components';
import {connect} from 'react-redux';
import download from 'downloadjs'



//internal modules
import {getMissingUpcsDetails} from '../apicalls/askupc'
import {CsvToObject,ObjectToCsvUploadMissingDigits} from './processCsv'
import {getcorelationdetails} from './getcorelationdetails'
import crp from './crp'

const Titler=styled.div`
    padding-left:1.5%;
    font-size:20px;
    color:brown;
    `

const DecoderMain=styled.div`
    top:100px;
    display:grid;
    rows:2;
    width:60%;
    height:100px;
    `
const FirstColumn=styled.div`
    align-self:center;
    `
const FirstRow=styled.div`
    grid-row:1;
    height:50px;
    width:100%;
    display:flex;
    justify-content:flex-start;
    `
const SecondRow=styled.div`
    grid-row:2;
    height:50px;
    width:100%;
    display:flex;
    justify-content:flex-start;
    `
const SecondColumn=styled.div`
    margin-left:10px;
    align-self:center;
    `
class UploadMissingDigits extends Component {
    constructor(){
        super()
        this.state={
            upload_file:null,
            file_name:null,
            file_to_upload:null,
            progress:null,
            progress_counter:0,
        }
        this.uploadFile=this.uploadFile.bind(this)
        this.submit=this.submit.bind(this)
    }
    //function which accepts a file
    uploadFile=(event)=>{
            //console.log('uploading',event.target.files[0])
            //and sets date with:
            if (event.target.files[0]){
                this.setState({file_name:event.target.files[0].name})
                //that we have a file 
                this.setState({upload_file:'full'})
                //and with file itself
                this.setState({file_to_upload:event.target.files[0]})
                }
        }
    //function which send a file to azure bucket
    submit=()=>{
        //if we have a file to upload
        //console.log(this.state.upload_file)
        if(this.state.upload_file==='full'){
            //updating progress
            this.setState({progress :'start processing file'})
            //transforming csv file into JS object
            async function getCsvObject(fileFromState){
                //via external module
                let csvObject=await CsvToObject(fileFromState)
                return csvObject
                }
            //we make a queries to correlated
            getCsvObject(this.state.file_to_upload)
            .then(csvObject=>{
            //transforming csv file into JS object
                //console.log('our object',csvObject);
                this.setState({progress :'checking correlation tables'})
                console.log('get local dbs')
                //checking correlation table
                async function getcorrelations(csvObject){
                    //via external module
                    //let CorrelObject=await getCorrel(csvObject)
                    let CorrelObject=await getcorelationdetails(csvObject)
                    return CorrelObject
                    }
                //call for correlating table
                getcorrelations(csvObject)
                .then(CorrelObject=>{
                    console.log('correl object',CorrelObject)
                    this.setState({progress :'fetching missing barcodes and/or details'})
                    //we do the loop via upcitemdb to look for missing numbers(using api) (0-9)
                    async function getMissingUpcs(CorrelObject){
                        let UpcMissingObject=await getMissingUpcsDetails(CorrelObject)
                        return UpcMissingObject
                        }
                    getMissingUpcs(CorrelObject)
                    .then(UpcMissingObject=>{
                        this.setState({progress :'preparing file'})
                        console.log('after fetches',UpcMissingObject)
                        //flattening object
                        //checking original size
                        var csvlength=csvObject.length
                        var finalFlatObject=[]
                        //if it's nested(ie over 50 positions)
                        if(csvlength>50){
                            var upc12L=UpcMissingObject.length
                            for(let a=0;a<upc12L;++a){
                                let upc12BL=(UpcMissingObject[a]).length
                                for (let b=0;b<upc12BL;++b){
                                    finalFlatObject.push(UpcMissingObject[a][b])
                                    }
                                }
                            }
                        else{
                            let upc12BL=(UpcMissingObject[0]).length
                            for (let b=0;b<upc12BL;++b){
                                finalFlatObject.push(UpcMissingObject[0][b])
                                }
                            }
                        //we need to uniform response
                        async function getsort(finalFlatObject){
                            let sortedresponses= crp.unknowns(finalFlatObject)
                            return sortedresponses
                            }
                        getsort(finalFlatObject)
                        .then(sortedresponses=>{
                            //we prepare file
                            //console.log('payoaded?',sortedresponses)
                            async function getObjectCsv(sortedresponses){
                                //via external module
                                //let csvFilet=await ObjectToCsv(finalFlatObject)
                                let csvFilet=await ObjectToCsvUploadMissingDigits(sortedresponses)
                                return csvFilet
                                }
                            //we make a queries to correlated
                            getObjectCsv(sortedresponses)
                            .then(CvsFilet=>{
                                //updating progress
                                this.setState({progress :'file ready to upload'})
                                //changing state to notify that file is ready
                                this.setState({download_file :'full'})
                                //creating name
                                var csvfilename='Unknown_barcodes_file_'+ Date.now() + '.csv'
                                //updating progress
                                this.setState({download_file_name :csvfilename})
                                //updating progress
                                this.setState({file_to_download :CvsFilet})
                                download(CvsFilet, csvfilename, "text/plain");
                                })
                            })                        
                        })
                    })
                return
                })
        }
        else{
            //updating progress
            this.setState({progress :'no file selected'})
            }
    }

    //if we have a change in props
    componentDidUpdate(prevProps){
        //if counter in component is different than in store
        if (this.props.progress_counter !== prevProps.progress_counter) {
            //and if it is not =0 (ie has not been reset at the end of the work)
            if(this.props.progress_counter!==0){
                var communicater='processing WMT item nr:'+this.props.progress_counter
                this.setState({progress:communicater});
                }
            }
    }
    render(){
        return(
            <div>
                <Titler>
                    Upload Unknown Barcodes
                </Titler>
                <DecoderMain>
                        <FirstRow>
                            <FirstColumn>
                                <input className="fileInput" type="file" id="csv_input" name="csv_input" accept=".csv" onChange={this.uploadFile}/>
                                <label className="fileInputLabel" htmlFor="csv_input">Choose a file</label>
                            </FirstColumn>
                            <SecondColumn>
                                {this.state.file_name}
                            </SecondColumn>
                        </FirstRow>
                        <SecondRow>
                            <FirstColumn>
                                <button className="fileInput" id="csv_input_button" onClick={this.submit}>Upload</button>    
                                <label className="fileInputLabel" htmlFor="csv_input_button">Upload a file</label>
                            </FirstColumn>
                            <SecondColumn>
                                {this.state.progress}
                            </SecondColumn>
                        </SecondRow>
                        
                </DecoderMain>
            </div>
        )
    }
}
const mapStateToProps = state => ({
    progress_counter:state.progress_counter
    //memberlvl:state.memberlvl
    });
  export default connect(mapStateToProps)(UploadMissingDigits);

//export default Decoder;

