//dependencies
import React, { Component } from 'react';
import { connect } from 'react-redux';
//components
//import LoginC from '../components/LoginC';
import LoginC from '../components/LoginCv6_1';
import LoginEmployee from '../components/logins/loginEmployee';


class LoginForm extends Component {
  constructor(props){
    super(props)
    this.state={
      showlogin:0,
      }
    }
showLogin=()=> {
  this.setState({
    showlogin:1,
  })
  }
render() {
  //showing login form
  if (this.props.showlogin===1) {
    return (
      <div><LoginC/></div>
    )
  }
  if (this.props.showlogin===2){
     return (
      <div>        
        <LoginEmployee/>
      </div>
      )
    }
}

}

//old way
const mapStateToProps=(state)=>({
  showlogin:state.showlogin,
});
export default connect (mapStateToProps)(LoginForm);

