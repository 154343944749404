import React,{Component} from 'react';
import {connect} from 'react-redux';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import download from 'downloadjs'
import {GetSerialNumberListDetails} from '../../apicalls/manifests'
import {ObjectToCsvsemicolon} from '../processCsvv2'
import {FillEmptyWithUnknownSerialNumbers} from '../utils/utils'
//internal dependencies

class ManifestDLDownload extends Component {
    constructor(){
        super()
        this.state={
            serial_numbers_selected_range:[],
            serialnumbers_range:[],
            communicate:''
            }
        
    }
    handleKeyUp(e) {
        //get the function inside the function(?)
        //asking for a key
        console.log('our props',e)
        const keys = {
        //if 27 (esc)
          27: () => {
              console.log('clciked esc')
            //prevents submit
            e.preventDefault();
            },
          13: ()=>{
            //prevents submit
            e.preventDefault();
            console.log('clicked enter',document.activeElement.name)
            if(document.activeElement.name==="upcnr"){
                this.inputText2.current.focus();
                }
            }
        }
        //if the key has ben pressed, run function to determine if it was esc
        if (keys[e.keyCode]) {
            keys[e.keyCode]();
          }
    }

    handleMultipleChoiceChange=(e)=>{
        console.log('changer',e)
        console.log('options array',this.state.serialnumbers_range)
        let checked_items=[]
        //we map through array and change checked to opposite
        let changedArray=this.state.serialnumbers_range.map(item=>{
            //console.log('cahnging item',item)
            let column_name=item.serialnumber
            //if we toggled this item
            if(column_name===e.serialnumber){
                //we change checked
                if(item.checked){
                    item.checked=false
                    }
                    else{
                    item.checked=true
                        }
                }
            if(item.checked){
                checked_items.push(item)
                }
            //and return item
            return item
            })
        this.setState({serialnumbers_range:changedArray})
        this.setState({serial_numbers_selected_range:checked_items})
        }

    async download (e){
        e.preventDefault()
        //console.log('full range',this.props.serialnumbers_range)
        //extract values which has been selected
        //console.log('selected range',this.state.serial_numbers_selected_range)            
            if(this.state.serial_numbers_selected_range){
                //checking if this is subcat we use already
                this.setState({"communicate":"downloading manifests details"})
                let bagaz={serialnumbers:this.state.serial_numbers_selected_range}
                console.log('bagaz',bagaz)
                let manifest_details=await GetSerialNumberListDetails(bagaz)
                console.log('results',manifest_details)
                if(manifest_details.answer==='ok'){
                    if(manifest_details.data.length>0){
                        //filling empty gaps with 'unknown
                        let fill_gaps=await FillEmptyWithUnknownSerialNumbers(manifest_details.data)
                        //we go to download stuff
                        this.setState({communicate:"start building csv file"})
                        let build_file=await ObjectToCsvsemicolon(fill_gaps)
                        let csvfilename='serialnumbers_details'+ Date.now() + '.csv'
                        console.log('our file',build_file)
                        download(build_file, csvfilename, "text/plain");
                        this.props.dispatch({type:"MenuOption",payload:"manifestdl"})
                        return
                        }
                    else{
                        this.setState({communicate:"you've selected serialnumbers without assigned barcodes"})
                        return
                        }
                    }
                }
            else{
                this.setState({"communicate":"No serialnumber selected"})
                return
            }
        return
        }
    //while creatiung component
    componentDidMount() {
        //console.log("has mounted");
        //adding keyboard listener (keyup-press a key, handlekeyup-function which is triggered, false-?)
        window.addEventListener('keyup', this.handleKeyUp, false);
        let changedArray=this.props.serialnumbers_range.map(item=>{
            item.checked=false
            return item
            })
        this.setState({serialnumbers_range:changedArray})
        }
    //while unmounting component
    componentWillUnmount(){
        //we remove event listener (for keyboard)
        window.removeEventListener("keyup", this.handleKeyUp, false);
        }
    //when re-enabling component
    componentDidUpdate(prevState){
        if(prevState.communicate!==this.state.communicate){
            console.log('communicate changed')
            }
        //if(prevState.start_date!==this.state.start_date){
        //    if(this.state.communicate==='please select date before running for results'){
        //        this.delete_comm()
        //        }
        //    }
        }
    render(){
        const renderMultipleChoiceOptionMenu=(handleMultipleChoiceChange,propvalue)=>(this.state.serialnumbers_range.map(function(menuOption){
            //console.log('menu option',menuOption,'full nemu option',menuOption.serialnumber)
            return(
                <div className="options_list_fontsEx" key={menuOption.serialnumber+"checkbox option div"}>
                    <input type="checkbox" id={menuOption.serialnumber+"id"} checked={menuOption.checked} name={menuOption.serialnumber} value={menuOption.serialnumber} onChange={handleMultipleChoiceChange.bind(this,menuOption)}></input>
                    <label htmlFor={menuOption.serialnumber}><span>&nbsp;&nbsp;</span>{menuOption.serialnumber}</label>
                </div>
                )
            }))
        return(
            <div className="ecom_flex">
                <div className="ecom_search_title">
                    Manifest DL Download
                </div>
                   
                <div className="modalbuttonquerylist">
                                <form onSubmit={this.processItem}>
                                    <div className="formBodyEx">
                                        <div>
                                            <label htmlFor="input_list">Serial Numbers  : </label>
                                        </div>
                                        <div className='formBodyScroll'>
                                            {renderMultipleChoiceOptionMenu(this.handleMultipleChoiceChange,this.state.serial_numbers_selected_range)}
                                        </div>
                                        <div className="welcome_button">
                                        <button className="ecom_search_button_confirm" onClick={(e)=>this.download(e)}>Download </button>                                        </div>
                                    </div>
                                </form>
                            </div>
                <div style={{color:"red"}}>
                    {this.state.communicate||'\u00a0'}
                </div>
            </div>
            )
    }
}
const mapStateToProps=(state)=>({
    LOGGED:state.LOGGED,
    MenuOption:state.MenuOption,
    acc_type:state.acc_type,
    serialnumbers_range:state.serialnumbers_range
    })
export default connect(mapStateToProps)(ManifestDLDownload);