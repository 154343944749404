import React,{Component} from 'react';
import {connect} from 'react-redux';
import {Formik} from 'formik';
import * as Yup from 'yup';

import {RemoveItemWhlsSkuVault} from '../../apicalls/skuVault'
import { GetWhlsLogsProductName } from '../../apicalls/GetWhlsSerialNumber';

class WhlsRemove extends Component {
    constructor(){
        super()
        this.state={
            communicate:''
            };
        //creating ref for formik
        this.inputRef = React.createRef();
        this.inputText=React.createRef()
        this.inputText2=React.createRef()
        this.clicked_back=this.clicked_back.bind(this)
        this.handleKeyUp=this.handleKeyUp.bind(this)
    }
    handleKeyUp(e) {
        //get the function inside the function(?)
        //asking for a key
        console.log('our props',e)
        const keys = {
        //if 27 (esc)
          27: () => {
              console.log('clicked esc')
            //prevents submit
            e.preventDefault();
            },
          13: ()=>{
            //prevents submit
            e.preventDefault();
            console.log('clicked enter',document.activeElement.name)
            }
        }
        //if the key has ben pressed, run function to determine if it was esc
        if (keys[e.keyCode]) {
            keys[e.keyCode]();
          }
    }
    clicked_back(){
        this.props.dispatch({type:'MenuOption',payload:'associate_main'})
        console.log('clicked back')
        }
    show_back(clicked_back){
        if(this.props.acc_type==='associate'){
            return(
                <button className="ecom_search_button_back" onClick={clicked_back}>BACK</button>
                )
            }
        else{
            return null
            }
        }
    show_title(){
        if(this.props.acc_type==='associate'){
            return(
                <div className="ecom_description">
                    WHLS REMOVE
                </div>
                )
            }
        else{
            return null
            }
        }
   //while creatiung component
   componentDidMount() {
    //console.log("has mounted");
    //adding keyboard listener (keyup-press a key, handlekeyup-function which is triggered, false-?)
    window.addEventListener('keyup', this.handleKeyUp, false);
    this.inputText.current.focus();
    }
    //while unmounting component
    componentWillUnmount(){
        //we remove event listener (for keyboard)
        window.removeEventListener("keyup", this.handleKeyUp, false);
        }
    //when re-enabling component
    componentDidUpdate(prevState){
        if(prevState.communicate!==this.state.communicate){
            console.log('communicate changed')
            }
        }
    render(){
        return(
            <div className="ecom_flex">
                {this.show_title()||null}
                <Formik
                    initialValues={{ upcnr: ""}} 
                    validationSchema={Yup.object().shape({
                        upcnr:Yup.string()
                        .min(9,'9 digits minimum')
                        .max(9,'9 digits maximum')
                        .required('required value'),
                        }) 
                    }
                    displayData={(response)=>{
                    }}
                    onSubmit={async (values, { setSubmitting,resetForm }) => {
                        this.setState({communicate:''})
                        console.log('clicked',values)
                        let scanned_barcode=values.upcnr.replace(/\s/g,'')
                        let scanned_location='WHOLESALE'
                        let bagaz={
                            serialnumber:scanned_barcode,
                            LocationCode:scanned_location,
                            Warehouse:4,
                            Quantity:'1',
                            Reason:'Remove',
                            }
                        let answer_whls_logs=await GetWhlsLogsProductName(bagaz)
                        console.log('our product_name',answer_whls_logs)
                        if(answer_whls_logs.answer==='ok'){
                            if(answer_whls_logs.data.length>0){
                                let product_name=answer_whls_logs.data[0].product_name
                                bagaz.Sku=product_name
                                console.log('product name',bagaz)
                                let answer_remove_whls=await RemoveItemWhlsSkuVault(bagaz)
                                console.log('answer from sku',answer_remove_whls)
                                if(answer_remove_whls.answer==='ok'){
                                    if(answer_remove_whls.data.RemoveItemStatus){
                                        if(answer_remove_whls.data.RemoveItemStatus==='Success'){
                                            this.setState({communicate:'Item removed'})
                                            resetForm({})
                                            }
                                        else{
                                            this.setState({communicate:'no such product in provided location'})
                                            resetForm({})
                                            }
                                        }
                                    }
                                resetForm({})
                                return
                                }
                            else{
                                this.setState({communicate:'no product name associated with this number'})
                                resetForm({})
                                }
                            resetForm({})
                            }
                        else{
                            this.setState({communicate:'error occured. please try again'})
                            resetForm({})
                            }
                        return
                        }}
                    >
                {props => {
                    const {
                    values,
                    touched,
                    errors,
                    isSubmitting,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    resetForm
                    } = props;
                    
                    return(
                        <form onSubmit={handleSubmit} ref={this.inputRef}>
                            <div className="ecom_search_bar">
                                <div className="ecom_search_title">
                                    SERIALNUMBER
                                </div>
                                <input className="ecom_search_input"
                                ref={this.inputText}
                                name="upcnr"
                                type="text"
                                placeholder="numbers only"
                                value={values.upcnr}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                    />
                                <div className="ecom_FormikErrors">{errors.upcnr||'\u00a0'}</div>
                            </div>
                            <button className="ecom_search_button_confirm" type="submit"  disabled={isSubmitting} >CONFIRM</button>
                            <button className="ecom_search_button_clear" type="button" onClick={(e) =>resetForm({})}>CLEAR</button>
                            {   this.show_back(this.clicked_back)||null}
                            <div className="ecom_FormikErrors">
                                {this.state.communicate||'\u00a0'}
                            </div>
                        </form>
                    )
                    }}
                </Formik>
            </div>
            )
    }
}
const mapStateToProps=(state)=>({
    LOGGED:state.LOGGED,
    MenuOption:state.MenuOption,
    acc_type:state.acc_type,
    acc_ID:state.acc_ID
    })
export default connect(mapStateToProps)(WhlsRemove);