//dependencies
const Papa =require('papaparse');


//function which deals with a single request to upc azure function
function processSingleItemUPC(input){
    return new Promise(async function(resolve,reject){
        var bagaz={
            upcnr:input,
            l:1
            }
        //var bagaz={upcnr:values.upcnr, l:0}
        //console.log('cest parti!')
        //we create a request
        var request=new Request('https://upcscanningapp.azurewebsites.net/api/upcquery', {            
            method:'POST',
            headers: new Headers({'Accept': 'application/json',
            'Content-Type':'application/json'}),
            body:JSON.stringify(bagaz)
            })
        //we make a request
        fetch(request)
            .then(response=>{
                //error handling
                if (!response.ok) {
                    response.answer='fetch error'
                    resolve(response)
                }
                return response;
            })
        //we get response, in form of json
            .then(response=>response.json())
            .then(response=>{
                console.log('answer from upc',response.answer)
                resolve(response)
                })
            .catch(e=>{
                    e.answer='fetch error'
                    resolve(e)
                })
        })
    }

function processfinalArrays(arrayA,processB,callback){
    let errors=null;
    var arrayAL=arrayA.length
    //console.log('length',arrayAL)
    //console.log('arrayA itself',arrayA)
    //console.log('arrayb itself',processB)
    var finalArray=[]
    for(let a=0;a<arrayAL;++a){
        let finalItem={upcnr:arrayA[a],upcdbanswer:processB[a].answer}
        //let finalItem={upcnr:arrayA[a],upcdbanswer:arrayA[a]}
        finalArray.push(finalItem)
        }
    //console.log('final array',finalArray)
    return callback(errors,finalArray)
    }

/**
* Description: transform csv file (with header) to object
* @param {File} input -csv file
* @returns {Object} js object (ie unc11numbers:bigint)
*/
export function CsvToObject(input){
        return new Promise(async function(resolve,reject){
            //get file and transform it into text
            input.text()
            .then(text=>{
                //create a config for papa parse
                //parse it to json object using promise (to make sure everything is parsed)
                async function parsecsv(text){
                    let config2={
                        quotes: false, //or array of booleans
                        quoteChar: '"',
                        escapeChar: '"',
                        delimiter: ",",
                        header: true,
                        newline: "\r\n",
                        skipEmptyLines: false, //other option is 'greedy', meaning skip delimiters, quotes, and whitespace.
                        columns: null //or array of strings
                        }
                    let results = await Papa.parse(text, config2)
                    //returns only data
                    return results.data
                    }
                parsecsv(text)
                    .then(results=>{
                        //send back results
                        resolve(results)
                    })
                })
            })
        }
export function ObjectToCsv(input){
            return new Promise(async function(resolve,reject){
                async function pureCsv(input){
                    const config3={
                        delimiter: ",",
                        header: true,
                        }
                    let Csv=await Papa.unparse(input,config3)
                    return Csv
                    } 
                pureCsv(input)
                .then(results=>{
                    resolve(results)
                    })
                })
        }
export function ObjectToCsvDownload(input){
    return new Promise(async function(resolve,reject){
        let changedInput=input.map(item=>{
            console.log('item',item)
            let returneditem={
                barcode:item.barcode,
                UPC:item.data[0],
                EAN:item.data[1],
                Title:`"`+item.data[2]+`"`,
                Brand:item.data[3],
                Category:`"`+item.data[4]+`"`,
                Model:item.data[5],
                Image:item.data[13],
                Color:item.data[6],
                Size:item.data[7],
                Weight:item.data[8],
                Dimensions:item.data[9],
                TargetOwned:item.data[10],
                MSRP:item.data[11],
                SalePrice:item.data[12]
                }
            return returneditem
            })
        async function pureCsv(changedInput){
            let Csv=await Papa.unparse(changedInput)
            return Csv
            } 
        pureCsv(changedInput)
        .then(results=>{
            resolve(results)
            })
        })
    }
export function ObjectToCsvUploadMissingDigits(input){
    return new Promise(async function(resolve,reject){
        let changedInput=input.map(item=>{
            //console.log('item',item)
            let returneditem={
                unknown_barcode:item[0],
                UPC:item[1],
                EAN:item[2],
                Title:item[3],
                Brand:item[4],
                Category:item[5],
                Model:item[6],
                Image:item[14],
                Color:item[7],
                Size:item[8],
                Weight:item[9],
                Dimensions:item[10],
                TargetOwned:item[11],
                MSRP:item[12],
                SalePrice:item[13]
                }
            return returneditem
            })
        async function pureCsv(changedInput){
            let Csv=await Papa.unparse(changedInput)
            return Csv
            } 
        pureCsv(changedInput)
        .then(results=>{
            resolve(results)
            })
        })
    }
/**
* Description: processes cvs file with callback
* @param {File} input -csv file
* @returns do not know yet what it will return
*/
export function processCsvInput(input){
        return new Promise(async function(resolve,reject){
            //place where we process our await Promise from line 86
            const getArrayB=function(Aitem){
                return new Promise (async function(resolve,reject){
                    console.log('our aitem',Aitem)
                    //place where we start runnign outside function to get upc data from a single item
                    //checking our upc db for matches
                    async function getSingleItem(Aitem){
                        //via external module
                        //console.log(Aitem)
                        let UpdatedSingleItem=await processSingleItemUPC(Aitem)
                        return UpdatedSingleItem
                        }
                    getSingleItem(Aitem)
                        .then(answerSingleItem=>{
                            //console.log('B',answerSingleItem)
                            resolve(answerSingleItem)
                        })                                                                
                    })
                }
            const processA=async(results)=>{
                //we flatten our array
                let arrayA=[]
                for(let a=0;a<results.length;++a){
                    for(let b=0;b<10;++b){
                        let new_number=results[a]
                        //if we do first round
                        if(results[a]["11-Digit UPC"]){
                            new_number=((results[a]["11-Digit UPC"]).toString()).concat(b);       
                            arrayA.push(new_number)                     
                            //console.log('processign flattening arrayA')
                            }
                            //if we do retry round
                            else{
                                //console.log('ignoring processing flattening A')
                                arrayA=results
                                break
                            }
                        }
                    }
                //we go to process all our items to look for a match in upcdb
                const processB=await Promise
                    .all(arrayA.map(async Aitem=>await getArrayB(Aitem)))
                //combining to arrays into an object
                let finalObject=processfinalArrays(arrayA,processB,(err,answer)=>{
                    if(err){
                        console.log(err)
                        }
                        else{
                            //console.log('checking answer',answer)
                            return answer
                            }
                    })                
                //after all that we return our processB with answers y/n
                return finalObject
                }
            //we take all datapoints
            //processA(results[0])
            processA(input)
                .then(results=>{
                    resolve(results)
                    })
        })        
    }
    
