module.exports={
  /**
  * Description: Creates payload for reductor which deals with showing results on the webpage
  * @param {array} array -array of needed values to be passed to the results
  */
  crp:function(input){
    //console.log(input)
    var array=[]
    //if there is something in msrp
    if(input.msrp){
      //we add $
      input.msrp='$ '+input.msrp
      }
    if(input.saleprice){
      input.saleprice='$ '+input.saleprice
      }
      /*array.push(input.upc)
      array.push(input.ean)
      array.push(input.title)
      array.push(input.brand)
      array.push(input.category)
      array.push(input.model)
      array.push(input.color)
      array.push(input.size)
      array.push(input.max_list_price)
      array.push(input.min_price)
      array.push(input.image)*/ 
      if(!input.upc){input.upc=null;}
      if(!input.ean){input.ean=null}
      if(!input.name){input.name=null}
      if(!input.brandname){input.brandname=null}
      if(!input.categorypath){input.categorypath=null}
      if(!input.modelnumber){input.modelnumber=null}
      if(!input.color){input.color=null}
      if(!input.size){input.size=null}
      if(!input.msrp){input.msrp=null}
      if(!input.saleprice){input.saleprice=null}
      if(!input.mediumimage){input.mediumimage=null}
      array.push(input.upc)
      array.push(input.ean)
      array.push(input.name)
      array.push(input.brandname)
      array.push(input.categorypath)
      array.push(input.modelnumber)
      array.push(input.color)
      array.push(input.size)
      array.push(input.msrp)
      array.push(input.saleprice)
      array.push(input.mediumimage)
      //console.log('title',input.name)
/*    array.push(input.itemid) //0
    array.push(input.parentitemid)//1
    array.push(input.name)//2
    array.push(input.saleprice)//3
    array.push(input.upc)//4
    array.push(input.categorypath)//5
    array.push(input.shortdescription)//6
    array.push(input.longdesription)//7
    array.push(input.brandname)//8
    array.push(input.thumbnailimage)//9
    array.push(input.mediumimage)//10
    array.push(input.largeimage)//11
    array.push(input.producttrackingurl)//12
    array.push(input.standdardshiprate)//13
    array.push(input.color)//14
    array.push(input.marketplace)//15
    array.push(input.modelnumber)//16
    array.push(input.sellerinfo)//17
    array.push(input.producturl)//18
    array.push(input.categorynode)//19
    array.push(input.rhid)//20
    array.push(input.bundle)//21
    array.push(input.clearance)//22
    array.push(input.preorder)//23
    array.push(input.stock)//24
    array.push(input.gender)//25
    array.push(input.addtocarturl)//26
    array.push(input.affiliateaddtocarturl)//27
    array.push(input.freeshippingover35dollars)//28
    array.push(input.availableonline)//29
    array.push(input.msrp)//30 */
    return array
    },
  ean:function(input){
    //if there is something in msrp
    if(input.max_list_price){
      //we add $
      input.max_list_price='$ '+input.max_list_price
      }
      //console.log('online',input.online_price)
      //console.log('min',input.min_price)
      //console.log('sale',input.saleprice)
    if(input.online_price){
      input.online_price='$ '+input.online_price
      }
    if(input.min_price){
      input.online_price='$ '+input.min_price
      }
    
    
      //console.log('ean',input.ean.length)
      if(input.ean!==null){
        if(input.ean.length<13){
          input.ean='0'+input.ean;
        }
      }
      //initailise array
      var array=[]
      array.push(input.upc)
      array.push(input.ean)
      array.push(input.title)
      array.push(input.brand)
      array.push(input.category)
      array.push(input.model)
      array.push(input.color)
      array.push(input.size)
      array.push(input.max_list_price)
      array.push(input.online_price)
      array.push(input.image)
      return array
    },
  barcode2:function(input){
      //upc,target,wmt
      console.log('inside crp, input',input)
      let processing=null
      let source=null
      if(input[1].answer){
        processing=input[1]
        source='target'
        }
      else{
        if(input[2].answer){
          processing=input[2]
          source='wmt'
          }
        else{
          processing=input[0]
          source='upc'
          }
        }
      let upc=[]
      let ean=[];
      let title=[];
      let brand=[]
      let category=[]
      let model=[]
      let color=[]
      let size=[]
      let weight=[]
      let dimensions=[]
      let targetowned=[]
      let msrp=[]
      let saleprice=[]
      let image=[]
      let description=[]
  
      //[0]=upc,[1]-target [2]-wmt
      //console.log('input length',iterL)
      let db=processing

        if(db.fullans.ean==='-1'){
          db.fullans.ean=null;
          }
        if(db.fullans.upc==='-1'){
          db.fullans.upc=null;
          }
        //if ean is 12 digit
        if (db.fullans.ean){
          //console.log('ean',db.fullans.ean.length)
          if(db.fullans.ean.length<13){
            db.fullans.ean='0'+db.fullans.ean
            }
          }
        //adding $ sign to msrp and salesprice
        if(db.fullans.online_price){
          db.fullans.online_price='$ '+db.fullans.online_price
          }
        if(db.fullans.saleprice){
          db.fullans.saleprice='$ '+db.fullans.saleprice
          }
        if(db.fullans.max_list_price){
          //we add $
          db.fullans.max_list_price='$ '+db.fullans.max_list_price
          }
        if (db.fullans.msrp){
          db.fullans.msrp='$ '+db.fullans.msrp
          //if it is target
          }
        if(db.fullans.highest_recorder_price){
          //console.log('we have highest recorded price',db.fullans.highest_recorder_price)
          db.fullans.highest_recorder_price='$ '+db.fullans.highest_recorder_price}
        if(db.fullans.min_price){
          db.fullans.min_price='$'+db.fullans.min_price
          }
        if(db.fullans.minprice){
          db.fullans.minprice='$'+db.fullans.minprice
          }
        //console.log('are we here???')
        //assign values to array
        upc=db.fullans.upc
        ean=db.fullans.ean
        title=db.fullans.title||'unknown'
        brand=db.fullans.brand||'unknown'
        category=db.fullans.category||'unknown'
        model=db.fullans.model||'unknown'
        color=db.fullans.color||'unknown'
        //console.log('colors',color,'iter',iterdbs)
        size=db.fullans.size||'unknown'
        weight=db.fullans.weight||'unknown'
        //console.log('weight',weight[iterdbs])
        dimensions=db.fullans.dimensions||db.fullans.dimension||'unknown'
        targetowned=db.fullans.targetowned
        //changed for upc, we take min_price
        msrp=db.fullans.max_list_price||db.fullans.msrp||db.fullans.highest_recorder_price||null
        //console.log('max list price',db.fullans.max_list_price,'msrp',db.fullans.msrp,'highest recorded price',db.fullans.highest_recorder_price)
        //console.log('msrp',msrp[iterdbs])
        if (source==='upc'){
          if(db.fullans.min_price){
            saleprice=db.fullans.min_price||null
            }
        }
        else{
          saleprice=db.fullans.online_price||db.fullans.saleprice||db.fullans.min_price||db.fullans.minprice||null
          }
        image=db.fullans.image||'unknown'
        description=db.fullans.description||db.fullans.desc||db.fullans.shortdescription||'unknown'
        //if not, leave it blank
        //initailise final array
        var array=[]
        //upc   -upc, target,walmart
        let pusher=upc
        array.push(pusher)
        //console.log('upc',pusher)
        //ean -upc, target,walmar
        pusher=ean
        array.push(pusher)
        //console.log('ean',pusher)
        //title -upc, target,walmar
        pusher=title
        array.push(pusher)
        //console.log('title',pusher)
        //brand -upc, target,walmar
        pusher=brand
        array.push(pusher)
        //console.log('brand',pusher)
        //category -upc, target,walmar
        pusher=category
        array.push(pusher)
        //console.log('category',pusher)
        //model -upc, target,walmar
        pusher=model
        array.push(pusher)
        //console.log('model',pusher)
        //color -target,wmt,upc
        pusher=color
        array.push(pusher)
        //console.log('color',pusher)
        //size -target,wmt,upc
        pusher=size
        array.push(pusher)
        //console.log('size',pusher)
        //weight -target,wmt,upc
        pusher=weight
        array.push(pusher)
        //console.log('weight',pusher)
        //dimensions -target,wmt,upc
        pusher=dimensions
        array.push(pusher)
        //console.log('dimensions',pusher)
        //targetowned -target
        if (targetowned===true){
          targetowned='True'
          }
        if(targetowned===false){
          targetowned='False'
          }
        if(!targetowned){
          targetowned='False'
          }
        array.push(targetowned)
        //console.log('target owned',targetowned[1])
        //msrp -target,upc,walmart
        pusher=msrp
        array.push(pusher)
        //console.log('msrp',pusher)
        //salesprice -target,upc,walmart
        array.push(saleprice)
        //image -target,wmt,upc
        array.push(image)
        //description
        array.push(description)
        //console.log('crp affay',array)
        if(source==='target'){
          array.push('msrp_target_source')
          }
        else{
          array.push('msrp_source_other')
          }
        return array
    },
  barcode:function(input){
    //upc,target,wmt
    console.log('inside crp, input',input)
    let upc=[]
    let ean=[];
    let title=[];
    let brand=[]
    let category=[]
    let model=[]
    let color=[]
    let size=[]
    let weight=[]
    let dimensions=[]
    let targetowned=[]
    let msrp=[]
    let saleprice=[]
    let image=[]
    let description=[]

    //[0]=upc,[1]-target [2]-wmt
    var iterL=input.length
    //console.log('input length',iterL)
    for(let iterdbs=0;iterdbs<iterL;++iterdbs){
      let db=input[iterdbs]
      if (db.answer>0){
        //console.log('db nr',iterdbs,'has answers')
        //console.log('full answers',db.fullans)
        //ean and upc change -1 to null
        if(db.fullans.ean==='-1'){
          db.fullans.ean=null;
          }
        if(db.fullans.upc==='-1'){
          db.fullans.upc=null;
          }
        //if ean is 12 digit
        if (db.fullans.ean){
          //console.log('ean',db.fullans.ean.length)
          if(db.fullans.ean.length<13){
            db.fullans.ean='0'+db.fullans.ean
            }
          }
        //adding $ sign to msrp and salesprice
        if(db.fullans.online_price){
          db.fullans.online_price='$ '+db.fullans.online_price
          }
        if(db.fullans.saleprice){
          db.fullans.saleprice='$ '+db.fullans.saleprice
          }
        if(db.fullans.max_list_price){
          //we add $
          db.fullans.max_list_price='$ '+db.fullans.max_list_price
          }
        if (db.fullans.msrp){
          db.fullans.msrp='$ '+db.fullans.msrp
          //if it is target
          }
        if(db.fullans.highest_recorder_price){
          //console.log('we have highest recorded price',db.fullans.highest_recorder_price)
          db.fullans.highest_recorder_price='$ '+db.fullans.highest_recorder_price}
        if(db.fullans.min_price){
          db.fullans.min_price='$'+db.fullans.min_price
          }
        if(db.fullans.minprice){
          db.fullans.minprice='$'+db.fullans.minprice
          }
        //console.log('are we here???')
        //assign values to array
        upc[iterdbs]=db.fullans.upc
        ean[iterdbs]=db.fullans.ean
        title[iterdbs]=db.fullans.title
        brand[iterdbs]=db.fullans.brand
        category[iterdbs]=db.fullans.category
        model[iterdbs]=db.fullans.model
        color[iterdbs]=db.fullans.color
        //console.log('colors',color,'iter',iterdbs)
        size[iterdbs]=db.fullans.size
        weight[iterdbs]=db.fullans.weight
        //console.log('weight',weight[iterdbs])
        dimensions[iterdbs]=db.fullans.dimensions||db.fullans.dimension
        targetowned[iterdbs]=db.fullans.targetowned
        msrp[iterdbs]=db.fullans.max_list_price||db.fullans.msrp||db.fullans.highest_recorder_price||null
        //console.log('max list price',db.fullans.max_list_price,'msrp',db.fullans.msrp,'highest recorded price',db.fullans.highest_recorder_price)
        //console.log('msrp',msrp[iterdbs])
        saleprice[iterdbs]=db.fullans.online_price||db.fullans.saleprice||db.fullans.min_price||db.fullans.minprice||null
        image[iterdbs]=db.fullans.image
        description[iterdbs]=db.fullans.description||db.fullans.desc||db.fullans.shortdescription
        }
      //if not, leave it blank
      else{

        }
      }
      //initailise final array
      var array=[]
      //upc   -upc, target,walmart
      let pusher=upc[0]||upc[1]||upc[2]
      array.push(pusher)
      //console.log('upc',pusher)
      //ean -upc, target,walmar
      pusher=ean[0]||ean[1]||ean[2]
      array.push(pusher)
      //console.log('ean',pusher)
      //title -upc, target,walmar
      pusher=title[0]||title[1]||title[2]
      array.push(pusher)
      //console.log('title',pusher)
      //brand -upc, target,walmar
      pusher=brand[0]||brand[1]||brand[2]
      array.push(pusher)
      //console.log('brand',pusher)
      //category -upc, target,walmar
      pusher=category[0]||category[1]||category[2]
      array.push(pusher)
      //console.log('category',pusher)
      //model -upc, target,walmar
      pusher=model[0]||model[1]||model[2]
      array.push(pusher)
      //console.log('model',pusher)
      //color -target,wmt,upc
      pusher=color[1]||color[2]||color[0]
      array.push(pusher)
      //console.log('color',pusher)
      //size -target,wmt,upc
      pusher=size[1]||size[2]||size[0]
      array.push(pusher)
      //console.log('size',pusher)
      //weight -target,wmt,upc
      pusher=weight[1]||weight[2]||weight[0]
      array.push(pusher)
      //console.log('weight',pusher)
      //dimensions -target,wmt,upc
      pusher=dimensions[1]||dimensions[2]||dimensions[0]
      array.push(pusher)
      //console.log('dimensions',pusher)
      //targetowned -target
      if (targetowned[1]===true){
        targetowned[1]='True'
        }
      if(targetowned[1]===false){
        targetowned[1]='False'
        }
      if(!targetowned[1]){
        targetowned[1]='False'
        }
      array.push(targetowned[1])
      //console.log('target owned',targetowned[1])
      //msrp -target,upc,walmart
      pusher=msrp[1]||msrp[0]||msrp[2]
      array.push(pusher)
      //console.log('msrp',pusher)
      //salesprice -target,upc,walmart
      array.push(saleprice[1]||saleprice[0]||saleprice[2])
      //image -target,wmt,upc
      array.push(image[1]||image[2]||image[0])
      //description
      array.push(description[0]||description[2]||description[1])
      //console.log('crp affay',array)
      if(msrp[1]){
        array.push('msrp_target_source')
        }
      else{
        array.push('msrp_source_other')
        }
      return array
  },
  correlating:function(input){
    //console.log('crp',input)
    if(input.answer.answer==='bingo'){
      let dater=[]
      if(input.answer.upc_12_nr){
        dater.push(input.answer.upc_12_nr)
        dater.push(null)
        return dater
        }
      if(input.answer.upc_13_nr){
        dater.push(null)
        dater.push(input.answer.upc_13_nr)
        input.data=dater
        return dater
        }
      return dater
      }
    else{
      return input
      }
    },
  unknowns:function(input){
    //console.log('start unknowns',input)
    let inputL=input.length
    let output=[]
    for(let inputiter=0;inputiter<inputL;++inputiter){
      //console.log('iterating',input[inputiter])
      let sorteddata=[]
      //if we've had response with data to upcnr
      if(input[inputiter].data){
        sorteddata=this.barcode(input[inputiter].data)
        sorteddata.unshift(input[inputiter].upcnr)
        }
      //if we did not have
      else{
        //console.log('no barcode',input[inputiter].upcnr)
        let itemtopush=input[inputiter].upcnr
        sorteddata.push(itemtopush)
        sorteddata.push('unknown')
        sorteddata.push('unknown')
        }
      output.push(sorteddata)
      //console.log('sorted data',sorteddata)
      }
    return output
    }
}