//works up to line 85, we can sent request to function, now we need to connect function to db
//dependencies
import React,{Component} from 'react';
import {connect} from 'react-redux';
import {Formik} from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components'

const ButtonStyled=styled.button`
  text-align: center;
  color:black;
  background:  #c6a9c; 
  padding-left:10px;
  padding-right:10px;
  padding-top:5px;
  padding-bottom:5px;
  border: 2px solid #58402e;
  border-radius: 5px;
  font-size:13px;
`
const ButtonStyledSmallUnder=styled.button`
  text-align: center;
  font-size:13px;
  color:rgb(212, 58, 58);
`
const InputFeedbackStyled=styled.div`
  font-size:12px;
  color:#343339;
`
const CenterFStyled=styled.div`
  text-align: center;
`
class LoginEmployee extends Component {
  constructor(props) {
    super(props)
    this.state={
        acc_type:'',
        acc_ID:null,
        showlogin:1,
        rezultat:'',
        communicate:'',
        poH:'',
        stationId:''
      };
    };
  //updating state
  componentDidUpdate(prevState) {
    if (this.state.communicate !== prevState.communicate) {
      //if we have had welcome back communicate
      if (this.state.communicate==="Welcome!") {
        //we reload login page (to display communicate)
        this.props.dispatch({type:"SET_SHOW_LOGIN_STATUS",payload:1});
        //and then after 2 seconds
        setTimeout(() => {
          //we close modal
          this.props.dispatch({type:"SET_SHOW_LOGIN_STATUS",payload:0});
        }, 2000);  
      }
      }
    }
  render() {
    return(
      <Formik
        initialValues={{ stationId: this.state.stationId||"", poH: this.state.poH||"",isForgotButton:false }}
        onSubmit={(values, { setSubmitting,resetForm }) => {
            //we reset our errors before submission
            this.setState({errors:{},communicate:'',show_forgot_password:''})
            console.log('cest parti!')
            //console.log(values.stationId)
            //console.log(values.password)
            //we assign poh and stationID to new employee 
            this.props.dispatch({type:"stationId",payload:values.stationId})
            this.props.dispatch({type:"poH",payload:values.poH})
            this.setState({communicate:"Thank you !"});
            //we are logged in
            this.props.dispatch({type:"LOGGED",payload:1});
            //we change screen
            this.props.dispatch({type:"SET_SHOW_LOGIN_STATUS",payload:0});
            }}
        // ********Using Yup for validation********/
        validationSchema={Yup.object().shape({
          stationId: Yup.number()
            .positive("number must be positive")
            .required("Station ID nr required"),
          poH: Yup.number()
            .required("PO # is required")
            .positive("number must be positive")
          })}

      >
      {props => {
        const {
          values,
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          } = props;
      return (
        <form onSubmit={handleSubmit}>
          <div>ID: {this.props.acc_ID} </div>
          <br></br>
          <label htmlFor="stationId">Station ID:</label>
          <br></br>
          <input
            name="stationId"
            type="number"
            placeholder="Enter your station ID nr"
            value={values.stationId}
            onChange={handleChange}
            onBlur={handleBlur}
            className={errors.stationId && touched.stationId && "error"}
            />
            <br></br>
            <div>
          {errors.stationId && touched.stationId && (
            <InputFeedbackStyled>{errors.stationId}</InputFeedbackStyled>
            )}
            </div>
          <br></br>
          <label htmlFor="poH">PO#:</label>
          <br></br>
          <input
            name="poH"
            type="number"
            placeholder="Enter your PO # number"
            value={values.poH}
            onChange={handleChange}
            onBlur={handleBlur}
            className={errors.poH && touched.poH && "error"}
            />
          <div>
            {errors.poH && touched.poH && (
              <InputFeedbackStyled>{errors.poH}</InputFeedbackStyled>
              )}
          </div>
          {/*here we set communicate iv to display feedback messages */}
          <div>{this.state.communicate}</div>
          {/*here we activate new div ig show_forgot_password gets a value */}
          {this.state.show_forgot_password && (
            <CenterFStyled>
              <ButtonStyledSmallUnder type="button" onClick={() => {
                /* in onClick we set new function (cannot pass it to the formik, don't know why) */
                this.props.dispatch({type:"SET_SHOW_LOGIN_STATUS",payload:4}); 
                
                 }}>{this.state.show_forgot_password}</ButtonStyledSmallUnder>            
            </CenterFStyled>
          )}
          <br></br>
          <CenterFStyled>
            <ButtonStyled type="submit" disabled={isSubmitting}>Login</ButtonStyled>
          </CenterFStyled>
        </form>
        );
      }}
      </Formik>
    )
    }
  }

const mapStateToProps = state => ({
  showlogin: state.showlogin,
  acc_ID:state.acc_ID
  //memberlvl:state.memberlvl
  });
export default connect(mapStateToProps)(LoginEmployee);