import React,{Component} from 'react';
import {connect} from 'react-redux';
import {Formik} from 'formik';
import * as Yup from 'yup';
import {GetWhlsLogsProductName} from '../../apicalls/GetWhlsSerialNumber'

class Manifest extends Component {
    constructor(){
        super()
        //creating ref for formik
        this.inputRef = React.createRef();
        this.inputText=React.createRef()
        this.inputText2=React.createRef()
        this.chooseOption=this.chooseOption.bind(this)
        this.clicked_back=this.clicked_back.bind(this)
        this.handleKeyUp=this.handleKeyUp.bind(this)
    }
    handleKeyUp(e) {
        //get the function inside the function(?)
        //asking for a key
        console.log('our props',e)
        const keys = {
        //if 27 (esc)
          27: () => {
              console.log('clciked esc')
            //prevents submit
            e.preventDefault();
            },
          13: ()=>{
            //prevents submit
            e.preventDefault();
            console.log('clicked enter',document.activeElement.name)
            if(document.activeElement.name==="upcnr"){
                this.inputText2.current.focus();
                }
            }
        }
        //if the key has ben pressed, run function to determine if it was esc
        if (keys[e.keyCode]) {
            keys[e.keyCode]();
          }
    }
    chooseOption(e){
        //here we will need to process the m_sku calls
        console.log('option clicked',e.target.value)
        }
    clicked_back(){
        this.props.dispatch({type:'MenuOption',payload:'associate_main'})
        console.log('clicked back')
        }
    show_back(clicked_back){
        if(this.props.acc_type==='associate'){
            return(
                <button className="ecom_search_button_back" onClick={clicked_back}>BACK</button>
                )
            }
        else{
            return null
            }
        }
    show_title(){
        if(this.props.acc_type==='associate'){
            return(
                <div className="ecom_description">
                    MANIFEST
                </div>
                )
            }
        else{
            return null
            }
        }
   //while creatiung component
   componentDidMount() {
    //console.log("has mounted");
    //adding keyboard listener (keyup-press a key, handlekeyup-function which is triggered, false-?)
    window.addEventListener('keyup', this.handleKeyUp, false);
    this.inputText.current.focus();
    }
    //while unmounting component
    componentWillUnmount(){
        //we remove event listener (for keyboard)
        window.removeEventListener("keyup", this.handleKeyUp, false);

        }
    //when re-enabling component
    componentDidUpdate(prevState){
        if(prevState.communicate!==this.state.communicate){
            console.log('communicate changed')
            }
        }
    render(){
        return(
            <div className="ecom_flex">
                {this.show_title()||null}
                <Formik
                    initialValues={{ palletenr: ""}} 
                    validationSchema={Yup.object().shape({
                        palletenr:Yup.string()
                        .min(9,'9 digits code is accepted')
                        .max(9,'9 digits code is accepted')
                        .required('required value')
                        }) 
                    }
                    displayData={(response)=>{
                    }}
                    onSubmit={async(values, { setSubmitting,resetForm }) => {
                        let bagaz={serialnumber:values.palletenr}
                        let product_name=await GetWhlsLogsProductName(bagaz)
                        console.log('product_name',product_name)
                        if(product_name.answer==='ok'){
                            let product_name_2send=product_name.data[0].product_name
                            console.log('product_name 2 send',product_name_2send)
                            this.props.dispatch({type:'product_name',payload:product_name_2send})
                            this.props.dispatch({type:'serialnumber',payload:values.palletenr})
                            this.props.dispatch({type:'MenuOption',payload:'manifestp2'})
                            }
                        else{
                            this.setState({communicate:'an error occured, please try again'})
                            resetForm({})
                            return
                            }
                        }}
                    >
                {props => {
                    const {
                    values,
                    touched,
                    errors,
                    isSubmitting,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    resetForm
                    } = props;
                    
                    return(
                        <form onSubmit={handleSubmit} ref={this.inputRef}>
                            <div className="ecom_search_bar">
                                <div className="ecom_search_title">
                                    PALLET
                                </div>
                                <input className="ecom_search_input"
                                ref={this.inputText}
                                name="palletenr"
                                type="text"
                                placeholder="numbers only"
                                value={values.upcnr}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                    />
                                <div className="ecom_FormikErrors">{errors.palletenr||'\u00a0'}</div>
                            </div>
                            <button className="ecom_search_button_clear" type="button" onClick={(e) =>resetForm({})}>CLEAR</button>
                            <button className="ecom_search_button_confirm" type="submit"  disabled={isSubmitting} >START</button>
                            {   this.show_back(this.clicked_back)||null}
                            <div className="ecom_FormikErrors">
                            </div>
                        </form>
                    )
                    }}
                </Formik>
            </div>
            )
    }
}
const mapStateToProps=(state)=>({
    LOGGED:state.LOGGED,
    MenuOption:state.MenuOption,
    acc_type:state.acc_type
    })
export default connect(mapStateToProps)(Manifest);