import React,{Component} from 'react';
import {connect} from 'react-redux'
import styled from 'styled-components'
import '../css/small.css';
import {getSave_m_sku,updateM_sku} from '../apicalls/skuCalls'
import {writeScanLogs} from '../apicalls/askScanLogs'
import {getSendItemsToShops,get_check_shops,get_shops_variant_quantity} from '../apicalls/getSendItemsToShops'
//import {getSendItemsToShops_test, get_check_shops_test} from '../apicalls/getSendItemsToShops_test'
import {get_save_shopify_pr_id} from '../apicalls/shops_api_uploads'
//import {get_save_shopify_pr_id_test} from '../apicalls/shops_api_uploads_test'


const condition_translator={"NEW":"N","LIKE_NEW":"LN","USED":"U","REFURB":"R","EXPIRED":"E","NOT_TESTED":"T"}

const ResultsStyled=styled.div`
    display:grid;
    grid-template-columns: 13% 50% 37%;
    grid-template-rows: 12;
    font-size:16px;
`
const LeftColumn=styled.div`
    display:flex;
    flex-direction:column;
    justify-content: flex-start;
    align-items:start;
`
class ResultsUPC extends Component {
    constructor(){
        super()
        this.state={
            loaded:false
            }
        //this.clean_props=this.clean_props.bind(this)
        }
    //clears props for next round
    clean_props=()=>{
        this.props.dispatch({type:"results_to_show",payload:[]})
        this.props.dispatch({type:"item_condition",payload:null})
        this.props.dispatch({type:"m_sku_found",payload:null})
        this.props.dispatch({type:"product_name",payload:null})
        this.props.dispatch({type:"override",payload:null})
        this.props.dispatch({type:"override_product_name",payload:null})
        this.props.dispatch({type:"previous_product_name",payload:null})
        this.props.dispatch({type:"scan_value",payload:null})   
        this.props.dispatch({type:"communicate",payload:"item has been saved"})
        }
    confirm=()=>{
        if(this.props.product_name&&this.props.m_sku_found){
            this.props.dispatch({type:"autoFocus",payload:true})
            //console.log('starting confirm',this.props.m_sku_found)
            //variables fro shopify etc
            let handle=this.props.results_to_show[2]
            let price_reformatted=this.props.results_to_show[12]
            let msrp_price_reformatted=this.props.results_to_show[11]
            console.log('reg prices',price_reformatted,'msrp',msrp_price_reformatted)
            let title=this.props.results_to_show[2]
            let descriptionHtml=this.props.results_to_show[14]
            let bodyHtmlL=this.props.results_to_show[14]
            let Vendor=this.props.results_to_show[3]
            let Barcode=this.props.scan_value
            let Image_Src=this.props.results_to_show[13]
            //setting shared constants
            let cond_full=this.props.item_condition
            let cond=condition_translator[cond_full]
            const m_sku=this.props.scan_value+'-'+cond
            const m_sku_timestamp=Date.now()
            //create a load for scan logs
            var scan_log_bagaz={
                user_id:this.props.acc_ID,
                station_id:this.props.stationId,
                po_h:this.props.poH,
                barcode:this.props.scan_value,
                override:this.props.override||false,
                timestamps:m_sku_timestamp,
                l:1,
                m_sku:m_sku,
                override_product_name:this.props.override_product_name||null,
                previous_product_name:this.props.previous_product_name||null
                }

            if(this.props.item_condition==='NEW'&&this.props.product_name==='ECOM'){
                //checking if we have m_sku in table with other shops
                async function check_shops(m_sku){
                    console.log('going to check shops')

/******************************test/production!!!**********************************/
                    //let answer_check_shops=await get_check_shops_test(m_sku)
                    let answer_check_shops=await get_check_shops(m_sku)
                    return answer_check_shops
                    }
                check_shops(m_sku)
                .then(answer_check_shops=>{
                    console.log('do we have it in shops?',answer_check_shops)
                    if(answer_check_shops.answer==='ok'){
                        //we create bagaz fro shops
                        handle=handle.replace(/ /g, "-")
                        //console.log('handle:',handle)
                        if(price_reformatted){
                            price_reformatted=price_reformatted.replace('$','')
                            price_reformatted=price_reformatted.replace(' ','')
                            }
                        if(msrp_price_reformatted){
                            msrp_price_reformatted=msrp_price_reformatted.replace('$','')
                            msrp_price_reformatted=msrp_price_reformatted.replace(' ','')
                            }
                        let price_to_pass=0
                        console.log('regular',price_reformatted,'msrp reformatted',msrp_price_reformatted)
                        if(Number(msrp_price_reformatted)>Number(price_reformatted)){
                            price_to_pass=msrp_price_reformatted
                            }
                        else{
                            price_to_pass=price_reformatted
                            }
                        console.log('our price to pass',price_to_pass)
                        let bagazShops={
                            handle:handle,
                            title:title,
                            descriptionHtml:descriptionHtml,
                            bodyHtmlL:bodyHtmlL,
                            Vendor:Vendor,
                            Published:true,
                            SKU:m_sku,
                            Inventory_Tracker:'shopify',
                            Inventory_Policy:'deny',
                            Fulfillment_Service:'manual',
                            Price: price_to_pass,
                            Requires_Shipping:true,
                            Taxable:true,
                            Barcode:Barcode,
                            Image_Src:Image_Src,
                            Gift_Card:false,
                            Tax_Code:2038710
                            }
                        //if we have data
                        if(answer_check_shops.data[0]){
                            let spotify_upload=answer_check_shops.data[0].spotify_upload
                            //if we have it uploaded
                            if(spotify_upload===true){
                                //we do nothing
                                this.props.dispatch({type:"communicate",payload:"item already in shopify"})
                                return
                                }
                            else{
                                console.log('we proceed to shopify',bagazShops)
                                async function send_items_to_shops(bagazShops){
/******************************test/production!!!**********************************/   
                                    //let send_resp=await getSendItemsToShops_test(bagazShops)
                                    let send_resp=await getSendItemsToShops(bagazShops)
                                    return send_resp
                                    }
                                send_items_to_shops(bagazShops)
                                .then(send_resp=>{
                                    console.log('response from shopify',send_resp)
                                    //we save answer with product_id and shopify=true
                                    console.log(send_resp.data.product.id)
                                    let product_id=send_resp.data.product.id
                                    let variant_id=send_resp.data.product.variants[0].id
                                    let inventory_item_id=send_resp.data.product.variants[0].inventory_item_id
                                    let bagazsave={m_sku:m_sku,
                                                    product_id:product_id,
                                                    variant_id:variant_id,
                                                    inventory_item_id:inventory_item_id}
                                    async function save_shopify_product_id(bagazsave){
/******************************test/production!!!**********************************/
                                        //let answer_save_shopify_pr_id=await get_save_shopify_pr_id_test(bagazsave)
                                        let answer_save_shopify_pr_id=await get_save_shopify_pr_id(bagazsave)
                                        return answer_save_shopify_pr_id
                                        }
                                    save_shopify_product_id(bagazsave)
                                    .then(answer_save_shopify_pr_id=>{
                                        console.log('saved product_id',answer_save_shopify_pr_id)
                                        this.props.dispatch({type:"communicate",payload:"item saved to shopify"})
                                        return
                                        })
                                    })
                                }
                            }
                        //if we do not
                        else{
                            //console.log('we proceed to shopify',bagazShops)
                            async function send_items_to_shops(bagazShops){
/******************************test/production!!!**********************************/  
                                //let send_resp=await getSendItemsToShops_test(bagazShops)
                                let send_resp=await getSendItemsToShops(bagazShops)
                                return send_resp
                                }
                            send_items_to_shops(bagazShops)
                            .then(send_resp=>{
                                console.log('response from shopify',send_resp)
                                //we save answer with product_id and shopify=true
                                //console.log(send_resp.data.product.id)
                                let product_id=send_resp.data.product.id
                                let variant_id=send_resp.data.product.variants[0].id
                                let inventory_item_id=send_resp.data.product.variants[0].inventory_item_id
                                let mapped_m_sku=send_resp.data.product.variants[0].sku
                                let bagazsave={m_sku:m_sku,
                                                product_id:product_id,
                                                variant_id:variant_id,
                                                inventory_item_id:inventory_item_id,
                                                mapped_m_sku:mapped_m_sku}
/******************************test/production!!!*********************************/                                                
                                async function save_shopify_product_id(bagazsave){
                                    //let answer_save_shopify_pr_id=await get_save_shopify_pr_id_test(bagazsave)
                                    let answer_save_shopify_pr_id=await get_save_shopify_pr_id(bagazsave)
                                    return answer_save_shopify_pr_id
                                    }
                                save_shopify_product_id(bagazsave)
                                .then(answer_save_shopify_pr_id=>{
                                    console.log('saved product_id',answer_save_shopify_pr_id)
                                    this.props.dispatch({type:"communicate",payload:"item saved to shopify"})
                                    return
                                    })
                                })
                            
                            }

                        }
                    })
                
                }
            if(this.props.m_sku_found==='n'){
                let bagaz={results_to_show:this.props.results_to_show,
                    m_sku:m_sku,
                    m_sku_timestamp:m_sku_timestamp,
                    condition_name:this.props.item_condition,
                    product_name:this.props.product_name,
                    override_status:this.props.override||false,
                    last_override_user:this.props.acc_ID||null,
                    last_override_timestamp:m_sku_timestamp||null
                    }  
                console.log('bagaz',bagaz)
                async function save_m_sku(bagaz){
                    let save_resp=await getSave_m_sku(bagaz)
                    return save_resp
                    }
                save_m_sku(bagaz)
                .then(save_resp=>{
                    console.log('saving m_sku',save_resp)
                    //moving saving scal logs from scanner to confirm
                    var scan_log_bagaz={
                        user_id:this.props.acc_ID,
                        station_id:this.props.stationId,
                        po_h:this.props.poH,
                        barcode:this.props.scan_value,
                        override:this.props.override||false,
                        timestamps:m_sku_timestamp,
                        l:1,
                        m_sku:m_sku,
                        override_product_name:this.props.override_product_name||null,
                        previous_product_name:this.props.previous_product_name||null
                        }

                    console.log('scanner log details',scan_log_bagaz)
                    //we send details to scan_logs (no wait for answer) 
                    async function sendScanLogs(scan_log_bagaz){
                        //change askTarget to writeScanLogs 
                        let answerScanLogs=await writeScanLogs(scan_log_bagaz)
                        console.log('response from write scan',answerScanLogs)
                        return answerScanLogs
                        }
                    sendScanLogs(scan_log_bagaz)
                    .then(answerScanLogs=>{
                        console.log('saving user_logs',answerScanLogs)   
                        this.clean_props()                   
                        return
                        })
                    })
                }
            else{
                //checking if we've made override
                if(this.props.override===true){
                    async function setupdateM_sku(scan_log_bagaz){
                        //change askTarget to writeScanLogs 
                        console.log('override is true',scan_log_bagaz)
                        let answerupdateLogs=await updateM_sku(scan_log_bagaz)
                        console.log('response from update scan',answerupdateLogs)
                        return answerupdateLogs
                        }
                    setupdateM_sku(scan_log_bagaz)
                    .then(answerupdateLogs=>{
                        async function sendScanLogs(scan_log_bagaz){
                            //change askTarget to writeScanLogs 
                            let answerScanLogs=await writeScanLogs(scan_log_bagaz)
                            return answerScanLogs
                            }
                        sendScanLogs(scan_log_bagaz)
                        .then(answerScanLogs=>{
                            console.log('saving user_logs',answerScanLogs)
                            //if has been already found, we do nothing
                            this.clean_props()  
                            return
                            })
                        })
                    }
                //no override yes m_sku
                else{                   
                    console.log('no override, yes m_sku')
                    //save results to user_scan
                    async function sendScanLogs(scan_log_bagaz){
                        //change askTarget to writeScanLogs 
                        let answerScanLogs=await writeScanLogs(scan_log_bagaz)
                        console.log('response from write scan',answerScanLogs)
                        return answerScanLogs
                        }
                    sendScanLogs(scan_log_bagaz)
                    .then(answerScanLogs=>{
                        this.clean_props()  
                        })
                    }
                }
            }
        else{
            console.log('confirm_clicked')
            }
        }
    open_category=()=>{
        if(this.props.product_name&&this.props.m_sku_found){
            console.log('opening category')
            this.props.dispatch({type:'SET_SHOW_LOGIN_STATUS',payload:'condition'})
            }
        else{
            console.log('category clicked')
            }
        }
    open_condition=()=>{
        if(this.props.product_name&&this.props.m_sku_found){
            console.log('opening condition')
            this.props.dispatch({type:'SET_SHOW_LOGIN_STATUS',payload:'condition'})
            }
        else{
            console.log('condition clicked')
            }
        }
    open_override=()=>{
        //need override_status, override_user, override_timestamp previous_product_name override_product_name
        if(this.props.product_name&&this.props.m_sku_found){
            //console.log('opening override')
            this.props.dispatch({type:'SET_SHOW_LOGIN_STATUS',payload:"override"})
            }
        else{
            console.log('override clicked')
            }
        }      
    render(){
        const renderTargetOwned=()=>{
            //console.log('target owned',this.props.targetOwned)
            if(this.props.targetOwned===true||this.props.targetOwned==='True'){
                return <div className="biggerboldYellow">{this.props.results_to_show[10]||"\u00a0"}</div>                        
                }                
            else{
                return <div className="biggerbold">{this.props.results_to_show[10]||"\u00a0"}</div>
                }
            }
        
        return(
            <ResultsStyled>
                    <LeftColumn>
                        <div className="bold">UPC: </div>
                        <div className="bold">EAN:</div>
                        <div className="bold">Title:</div>
                        <div className="bold">Brand:</div>
                        <div className="bold">Category: </div>
                        <div className="bold">Model:</div>
                        <div className="bold">Color:</div>
                        <div className="bold">Size:</div>
                        <div className="bold">Weight:</div>
                        <div className="bold">Dimensions:</div>
                        <div>{"\u00a0"}</div>
                        <div className="biggerbold">Target Owned:</div>
                        <div className="biggerbold">MSRP:</div>
                        <div className="bold">Sale Price:</div>
                        {/* place for buttons to modal  */}
                        <div className='scanner_modal_menu'>
                            <button className='scanner_modal_menu_button_condition' onClick={this.open_condition}>{this.props.item_condition||'COND'}</button>
                            <button className='scanner_modal_menu_button_category' onClick={this.open_category}>{this.props.product_name||'PROD.NAME'}</button>
                            <button className='scanner_modal_menu_button_oveerride' onClick={this.open_override}>OVERRIDE</button>
                            <button className='scanner_modal_menu_button_confirm' onClick={this.confirm}>CONFIRM</button>
                        </div>
                    </LeftColumn>
                <div className='middle_column'>
                    <div className="results">{this.props.results_to_show[0]||"\u00a0"} </div>
                    <div className="results">{this.props.results_to_show[1]||"\u00a0"} </div>
                    <div className="results">{this.props.results_to_show[2]||"\u00a0"} </div>
                    <div className="results">{this.props.results_to_show[3]|| "\u00a0"} </div>
                    <div className="results">{this.props.results_to_show[4]||"\u00a0"} </div>
                    <div className="results">{this.props.results_to_show[5]||"\u00a0"}</div>
                    <div className="results">{this.props.results_to_show[6]||"\u00a0"} </div>
                    <div className="results">{this.props.results_to_show[7]||"\u00a0"} </div>
                    <div className="results">{this.props.results_to_show[8]||"\u00a0"} </div>
                    <div className="results">{this.props.results_to_show[9]||"\u00a0"} </div>
                    <div>{"\u00a0"}</div>
                    {renderTargetOwned()}
                    {/*<div className="biggerboldYellow">{this.props.results_to_show[10]||"\u00a0"}</div>*/}
                    <div className="biggerbold">{this.props.results_to_show[11]||"\u00a0"}</div>
                    <div className="results">{this.props.results_to_show[12]||"\u00a0"}</div>
                </div>

                <div style={{maxWidth:"500px", height:"auto"}}>
                    <img style={{maxWidth:"100%", height:"auto"}} src={this.props.results_to_show[13]} alt=""/> 
                </div>
            </ResultsStyled>
        )
    }
    }

const mapStateToProps=(state)=>({
    acc_ID:state.acc_ID,
    communicate:state.communicate,
    item_condition:state.item_condition,
    m_sku_found:state.m_sku_found,
    override:state.override,
    override_product_name:state.override_product_name,
    poH:state.poH,
    previous_product_name:state.previous_product_name,
    product_name:state.product_name,
    results_to_show:state.results_to_show,
    scan_value:state.scan_value,
    stationId:state.stationId,
    showlogin:state.showlogin,
    targetOwned:state.targetOwned
    })
export default connect(mapStateToProps)(ResultsUPC);