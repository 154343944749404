import React, {Component} from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components'
const LoginDiv=styled.div`
    cursor: pointer
`
//class dealing with login
class Login extends Component {
    constructor(){
        super()
       this.showLogin = this.showLogin.bind(this);
    };
    showLogin=()=> {
        console.log('clicked')
        this.props.dispatch({type:"SET_SHOW_LOGIN_STATUS", payload:1})
        }
    render() {       
        return(           
            <LoginDiv onClick={this.showLogin}>Log in </LoginDiv>
        )
    }
}
const mapStateToProps=(state)=>({
    showlogin:state.showlogin,
})
export default connect(mapStateToProps)(Login);