import React from 'react';
import ReactDOM from 'react-dom';
import LayoutRouter from './components/layoutRouter';
import {Provider} from "react-redux";
import store from "./store";



//div twhere to render
const app=document.getElementById('app');
//version with redux only
//render the website, to wrap redux around react, use <provider> tag
//ReactDOM.render(<Provider store={store}><Layout/></Provider>,app);

//version with react-router-dom
ReactDOM.render(<Provider store={store}><LayoutRouter/></Provider>,app);