import React,{Component} from 'react';
import {connect} from 'react-redux';
import {Formik} from 'formik';
import * as Yup from 'yup';
//local dependencies
import {askupcdb,askwmtapi,askUPCBCapi,askUPCBCdb} from '../../apicalls/askupc'
import {askTarget,askTargetDB} from '../../apicalls/askTarget'
import{saveManifestItem} from '../../apicalls/manifests.js'
import crp from'../../components/crp'

async function GetApiUPCs(bagaz){
    return new Promise(async function(resolve,reject){
        let answerUPCbarcodeQuery=await askUPCBCapi(bagaz)
        //let answerUPCbarcodeQuery={answer:0}
        let answertargetQuery=await askTarget(bagaz)
        let answerwmtquery=await askwmtapi(bagaz)
        //let answerwmtquery={answer:0}
        Promise.all([answerUPCbarcodeQuery,answertargetQuery,answerwmtquery])
        .then((answerApis)=>{
            //we return our values
            console.log('answer from apis',answerApis)
            resolve(answerApis)
            })
        })
    }


class Manifestp2 extends Component {
    constructor(){
        super()
        this.state={
            communicate:''
            };
        //creating ref for formik
        this.inputText=React.createRef()
        this.stopping=this.stopping.bind(this)
        this.handleKeyUp=this.handleKeyUp.bind(this)
    }
    handleKeyUp(e) {
        //get the function inside the function(?)
        //asking for a key
        //console.log('our props',e)
        const keys = {
        //if 27 (esc)
          27: () => {
              //console.log('clciked esc')
            //prevents submit
            e.preventDefault();
            },
          13: ()=>{
            //prevents submit
            e.preventDefault();
            //console.log('clicked enter',document.activeElement.name)
            }
        }
        //if the key has ben pressed, run function to determine if it was esc
        if (keys[e.keyCode]) {
            keys[e.keyCode]();
          }
    }
    show_title(){
        if(this.props.acc_type==='associate'){
            return(
                <div className="ecom_description_yellow">
                    SCANNING
                </div>
                )
            }
        else{
            return null
            }
        }
    stopping(){
        console.log('stopping')
        this.props.dispatch({type:'MenuOption',payload:'manifest'})
        }
   //while creatiung component
   componentDidMount() {
    //console.log("has mounted");
    //adding keyboard listener (keyup-press a key, handlekeyup-function which is triggered, false-?)
    window.addEventListener('keyup', this.handleKeyUp, false);
    this.inputText.current.focus();
    }
    //while unmounting component
    componentWillUnmount(){
        //we remove event listener (for keyboard)
        window.removeEventListener("keyup", this.handleKeyUp, false);
        }
    //when re-enabling component
    componentDidUpdate(prevState){
        if(prevState.communicate!==this.state.communicate){
            console.log('communicate changed')
            }
        }
    render(){
        return(
            <div className="ecom_flex_yellow">
                {this.show_title()||null}
                <Formik
                    initialValues={{ palletenr: ""}} 
                    validationSchema={Yup.object().shape({
                        palletenr:Yup.string()
                        .required('required value')
                        }) 
                    }
                    displayData={(response)=>{
                    }}
                    onSubmit={async (values, { setSubmitting,resetForm }) => {
                        this.setState({"communicate":"processing.."})
                        console.log('palette clicked',values)
                        let nrchecker=values.palletenr
                        var bagaz={
                            upcnr:values.palletenr,
                            barcode:values.palletenr,
                            l:1
                            }
                        //if barcode is nonstandard
                        if(String(nrchecker).length>13||String(nrchecker).length<12||isNaN(nrchecker)===true){
                            console.log('we have a non-standard barcode')
                            nrchecker='nonstandard'
                            let bagaz_manifest={
                                serialnumber:this.props.serialnumber,
                                productname:this.props.product_name,
                                barcode:values.palletenr,
                                title:'unknown',
                                brand:null,
                                category:'unknown',
                                model:null,
                                image:null,
                                color:null,
                                size:null,
                                msrp:9.99,
                                condition:'Non Tested',
                                userid:this.props.acc_ID,
                                poh:this.props.poH,
                                stationid:this.props.stationId,
                                targetowned:true,
                                weight:null,
                                dimensions:null,
                                msrp_source:'other'
                                }

                            console.log('bagaz manifest',bagaz_manifest)
                            let saved=await saveManifestItem(bagaz_manifest)
                            this.setState({communicate:"nonstandard Barcode saved. Please proceed"});
                            console.log(saved)
                            resetForm({})
                            this.inputText.current.focus();
                            return
                            }
                        async function asklocalUPCbarcodeQuery(bagaz){
                            //returning promise
                            return new Promise(async function(resolve,reject){
                                let answerlocalUPCbarcodeQuery=await askUPCBCdb(bagaz)
                                let answertargetdb=await askTargetDB(bagaz)
                                let answerwmtdb=await askupcdb(bagaz)
                                //let answerwmtdb={answer:0}
                                //we gather all promises
                                Promise.all([answerlocalUPCbarcodeQuery,answertargetdb,answerwmtdb])
                                .then((answerlocalDbs)=>{
                                    //we return our values
                                    resolve(answerlocalDbs)
                                    })
                                })                                               
                            }
                        asklocalUPCbarcodeQuery(bagaz)
                            .then(async(answerlocalDbs)=>{
                                //console.log('local dbs,',answerlocalDbs)
                                //if we no have an answer , go to to api
                                if (answerlocalDbs[0].answer===0&&answerlocalDbs[1].answer===0&&answerlocalDbs[2].answer===0){
                                    this.setState({"communicate":"fetching from api"})
                                    let answersfromapi=await GetApiUPCs(bagaz)
                                    //assign resutls to localdbanswer(ebcause it was 0)
                                    answerlocalDbs=answersfromapi
                                    }
                                //checking again if we have some answers
                                if (answerlocalDbs[0].answer>0||answerlocalDbs[1].answer>0||answerlocalDbs[2].answer>0){
                                    //if we have a code
                                    this.setState({communicate:"Barcode fetched from local database. saving to the manifest"});
                                    //building payload
                                    var result_payload=crp.barcode2(answerlocalDbs)
                                    console.log('answer local dbs',result_payload)
                                    //console.log('results array',result_payload)
                                    let msrp=Number(result_payload[11].substring(2))
                                    let msrp2=Number(result_payload[11].substring(2))
                                    let target_msrp=result_payload[15]
                                    //console.log('target msrp',target_msrp)
                                    //if msrp source is not target, then we take bigger form msrp and online_prioce.
                                    //if msrp comes from target, we take target price
                                    if(target_msrp!=='msrp_target_source'){
                                        if(msrp<=msrp2){
                                            msrp=msrp2
                                            }
                                        }
                                    if( result_payload[0]=='810655114584'){msrp=1}
                                    console.log('results from payload',result_payload)
                                    let bagaz_manifest={
                                        serialnumber:this.props.serialnumber,
                                        productname:this.props.product_name,
                                        barcode:values.palletenr,
                                        title:result_payload[2],
                                        brand:result_payload[3],
                                        category:result_payload[4],
                                        model:result_payload[5],
                                        image:result_payload[13],
                                        color:result_payload[6],
                                        size:result_payload[7],
                                        msrp:msrp,
                                        condition:'Non Tested',
                                        userid:this.props.acc_ID,
                                        poh:this.props.poH,
                                        stationid:this.props.stationId,
                                        targetowned:result_payload[10],
                                        weight:result_payload[8],
                                        dimensions:result_payload[9]
                                        }

                                    console.log('bagaz manifest',bagaz_manifest)
                                    let saved=await saveManifestItem(bagaz_manifest)
                                    this.setState({communicate:"Barcode saved. Please proceed"});
                                    console.log(saved)
                                    resetForm({})
                                    this.inputText.current.focus();
                                    return
                                    }
                                else{
                                    this.setState({communicate:"no such barcode in local database nor in api."})
                                    let bagaz_manifest={
                                        serialnumber:this.props.serialnumber,
                                        productname:this.props.product_name,
                                        barcode:values.palletenr,
                                        title:'unknown',
                                        brand:null,
                                        category:'unknown',
                                        model:null,
                                        image:null,
                                        color:null,
                                        size:null,
                                        msrp:9.99,
                                        condition:'Non Tested',
                                        userid:this.props.acc_ID,
                                        poh:this.props.poH,
                                        stationid:this.props.stationId,
                                        targetowned:true,
                                        weight:null,
                                        dimensions:null
                                        }
                                    console.log('bagaz manifest',bagaz_manifest)
                                    let saved=await saveManifestItem(bagaz_manifest)
                                    this.setState({communicate:"unknown Barcode saved. Please proceed"});
                                    console.log(saved)
                                    resetForm({})
                                    this.inputText.current.focus();
                                    return
                                    }
                                })                                              
                        }}
                    >
                {props => {
                    const {
                    values,
                    touched,
                    errors,
                    isSubmitting,
                    handleChange,
                    handleBlur,
                    handleSubmit
                    } = props;
                    
                    return(
                        <form onSubmit={handleSubmit}>
                            <div className="ecom_search_bar">
                                <div className="ecom_search_title_yellow" >
                                    BARCODE
                                </div>
                                <input className="ecom_search_input"
                                    style={{"backgroundColor":"rgb(255, 255, 51)"}}
                                    ref={this.inputText}
                                    name="palletenr"
                                    type="text"
                                    disabled={isSubmitting}
                                    placeholder="numbers only"
                                    value={values.palletenr}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    />
                                <div className="ecom_FormikErrors">{errors.palletenr||'\u00a0'}</div>
                            </div>
                            {/*<button className="ecom_search_button_clear" type="submit"  disabled={isSubmitting} >STOP</button>*/}
                            <button className="ecom_search_button_clear" type="button" onClick={(e) =>{this.stopping()}}>STOP</button>
                            <div className="ecom_FormikErrors">
                                {this.state.communicate||'\u00a0'}
                            </div>
                        </form>
                    )
                    }}
                </Formik>
            </div>
            )
    }
}
const mapStateToProps=(state)=>({
    LOGGED:state.LOGGED,
    MenuOption:state.MenuOption,
    product_name:state.product_name,
    serialnumber:state.serialnumber,
    acc_type:state.acc_type,
    acc_ID:state.acc_ID,
    poH:state.poH,
    stationId:state.stationId
    })
export default connect(mapStateToProps)(Manifestp2);