//our store
import {createStore,
    applyMiddleware} from "redux";
//for making asynchonous http requests
import thunk from "redux-thunk";
import ourreducer from './reductors/ourReducer';

const middleware=applyMiddleware(thunk);
const store=createStore(ourreducer,middleware);

export default store
//export default createStore(reducers);
//const store= createStore(red,middleware); 
//export default store;