import React,{Component} from 'react';
import {connect} from 'react-redux';

    class WhlsAdd extends Component {
        constructor(){
            super()
            this.state={
                sku:'',
                sku_full:'TGT-APPAREL',
                printing:false
                };
            //creating ref for formik
            this.setCondition=this.setCondition.bind(this)
            this.showLogin=this.showLogin.bind(this)
            this.reprint=this.reprint.bind(this)
        }

        reprint=()=>{
            console.log('clicked')
            if(this.props.sku_to_print){
            this.props.dispatch({type:"reprint",payload:"yes"})
            this.props.dispatch({type:"SET_SHOW_LOGIN_STATUS",payload:"sku_print_modal"})
            }
            else{
                console.log('no previous print has been recorded')
                }
            }
        TriggerPrinting=() => {
            this.ReactprintRef.current.handleClick();
            this.PrintbuttonRef.current.removeAttribute("disabled")
            //this.setState({sku:'',sku_full:'',communicate:'done'})
            };
        setCondition=(e)=>{
            console.log('clicked',e.target.value)
            this.props.dispatch({type:"reprint",payload:"no"})
            this.props.dispatch({type:"sku_to_print",payload:e.target.value})
            this.props.dispatch({type:"SET_SHOW_LOGIN_STATUS",payload:"sku_print_modal"})
            }
        showLogin=()=>{
            console.log('clicked back')
            this.props.dispatch({type:"MenuOption",payload:1})
            }
        render(){
            return(
                <div className='whls_add_main_container'>
                <br></br>
                <br></br>
                <div className='modal_override_title'>WHLS ADD</div>
                <div className='whls_add_options_box'>
                    <button className='modal_override_options_button' style={{"backgroundColor":" lightblue"}} onClick={this.setCondition} value='ECOM'>ECOM</button>
                    <button className='modal_override_options_button' style={{"backgroundColor":" #ffe70d"}} onClick={this.setCondition} value='REFURB'>REFURB</button>
                    <button className='modal_override_options_button' style={{"backgroundColor":" rgb(219, 26, 26)"}} onClick={this.setCondition} value='SLVG-PLT'>SLVG-PLT</button>
                    <button className='modal_override_options_button' style={{"backgroundColor":" rgb(106, 212, 0)"}} onClick={this.setCondition} value='TGT-AP'>TGT-AP</button>
                    <button className='modal_override_options_button' style={{"backgroundColor":" rgb(106, 212, 0)"}} onClick={this.setCondition} value='TGT-SH'>TGT-SH</button>
                    <button className='modal_override_options_button' style={{"backgroundColor":" rgb(106, 212, 0)"}} onClick={this.setCondition} value='TGT-UG'>TGT-UG</button>
                    <button className='modal_override_options_button' style={{"backgroundColor":" #ff890d"}} onClick={this.setCondition} value='TGT-HC'>TGT-HC</button>
                    <button className='modal_override_options_button' style={{"backgroundColor":" #ff890d"}} onClick={this.setCondition} value='TGT-GM'>TGT-GM</button>
                    <div className='modal_override_options_button'  > </div>
                    <button className='modal_override_options_button' style={{"backgroundColor":" rgb(226, 181, 181)"}} onClick={this.setCondition} value='TGT-SW'>TGT-SW</button>
                    <button className='modal_override_options_button' style={{"backgroundColor":" rgb(226, 181, 181)"}} onClick={this.setCondition} value='TGT-X'>TGT-X</button>
                    <button className='modal_override_options_button' style={{"backgroundColor":"white"}} onClick={this.showLogin}>BACK</button>
                </div>
                <button className='modal_whls_add_reprint_button' onClick={this.reprint}>REPRINT PREVIOUS</button>
            </div>
                )
        }
    }

const mapStateToProps=(state)=>({
    communicate:state.communicate,
    LOGGED:state.LOGGED,
    showlogin:state.showlogin,
    MenuOption:state.MenuOption,
    acc_ID:state.acc_ID,
    stationId:state.stationId,
    sku_to_print:state.sku_to_print,
    poH:state.poH
})
export default connect(mapStateToProps)(WhlsAdd);